import { computed } from 'vue'

export function resumeSectionProperties(resumeRef) {
  // Expects a ref to the resume object as parameter

  // These are generic sections with dates
  const dateSections = [
    'awards_and_recognitions',
    'publications_and_presentations',
    'trainings_and_certifications'
  ];
  // These are generic sections without dates
  const nonDateSections = [
    'technical_tools',
    'professional_associations',
    'volunteer_experience',
    'languages_spoken',
    'hobbies_and_activities',
  ];
  const specialSections = [
    'experiences',
    'educational_qualifications',
    'projects_and_assignments',
  ];

  // Whether the experience section can be shown
  const hasVisibleExperiences = computed(() => {
    // console.log('checking if resume hasVisibleExperiences:\n'+JSON.stringify(resumeRef.value.experiences));
    if (!resumeRef.value?.experiences || resumeRef.value.experiences.length === 0) {
      return false;
    }
    return (experienceCounts.value.total > 0 && !resumeRef.value.hide_experiences)
      || (experienceCounts.value.visible > 0 && resumeRef.value.hide_experiences);
  });

  //counts of positions in the experience section (across all experiences)
  const experienceCounts = computed(() => {
    // Get the experiences array
    const experiences = resumeRef.value?.experiences;

    // Default state
    const counts = {
      visible: 0,
      hidden: 0,
      total: 0
    };

    // If no experiences, return default counts
    if (!experiences) {
      return counts;
    }

    // Calculate all counts in a single pass
    return experiences.reduce((acc, experience) => {
      // Skip if experience has no positions
      if (!experience.positions) {
        return acc;
      }

      experience.positions.forEach(position => {
        acc.total++;
        if (position?.hide) {
          acc.hidden++;
        } else {
          acc.visible++;
        }
      });

      return acc;
    }, counts);
  });

  //counts of positions for a specific experience (based on index)
  const experiencePositionCounts = computed(() => (index) => {
    // Get the experiences array
    const experiences = resumeRef.value?.experiences;

    // Default state
    const counts = {
      visible: 0,
      hidden: 0,
      total: 0
    };

    // Check if experiences array exists and index is valid
    if (!experiences || index < 0 || index >= experiences.length || !experiences[index].positions) {
      return counts;
    }

    const experience = experiences[index];

    // Calculate all counts in a single pass
    return experience.positions.reduce((acc, position) => {
      acc.total++;
      if (position?.hide) {
        acc.hidden++;
      } else {
        acc.visible++;
      }
      return acc;
    }, counts);
  });

  // Whether a specific organization can be shown
  const hasVisibleOrganization = computed(() => (index) => {
    if (!resumeRef.value?.experiences || resumeRef.value.experiences.length === 0 || index < 0 || index >= resumeRef.value.experiences.length) {
      return false;
    }
    const counts = experiencePositionCounts.value(index);
    return (counts.total > 0 && !resumeRef.value.hide_experiences) ||
      (counts.visible > 0 && resumeRef.value.hide_experiences);
  });

  // Get an array of position IDs where hide_recommended is true
  const experienceRecommendations = computed(() => {
    // Get the experiences array
    const experiences = resumeRef.value?.experiences;

    // If no experiences, return empty array
    if (!experiences) {
      return [];
    }

    // Collect all position IDs where hide_recommended is true
    // recommendations = final array of position IDs
    return experiences.reduce((recommendations, experience) => {
      // Skip if experience has no positions
      if (!experience.positions) {
        return recommendations;
      }

      // Check each position and collect IDs where hide_recommended is true
      // recommendedPositions = array of position IDs for a given experience
      const recommendedPositions = experience.positions
        .filter(position => position?.hide_recommended === true)
        .map(position => ({
          ...position,                         // spread existing position properties
          company_name: experience.company_name // add company_name from experience
        }));

      return [...recommendations, ...recommendedPositions];
    }, []);
  });

  // Get experience and position indexes for a given position.id
  const getExperiencePositionIndex = computed(() => (id) => {
    // Default return value when no match is found
    const notFound = {
      experienceIndex: -1,
      positionIndex: -1
    };

    // Check if experiences array exists
    const experiences = resumeRef.value?.experiences;
    if (!resumeRef.value?.experiences || resumeRef.value.experiences.length === 0) {
      return notFound;
    }

    // Loop through experiences
    for (let expIdx = 0; expIdx < experiences.length; expIdx++) {
      const experience = experiences[expIdx];

      // Check if positions array exists for this experience
      if (!Array.isArray(experience?.positions)) {
        continue;
      }
      // Find position index
      const posIdx = experience.positions.findIndex(position => position?.id === id);

      // If position found, return both indexes
      if (posIdx !== -1) {
        return {
          experienceIndex: expIdx,
          positionIndex: posIdx
        };
      }
    }
    // No match found
    return notFound;
  });

  // counts of items in a given section
  const itemCounts = computed(() => (section) => {
    // Default state
    const counts = {
      visible: 0,
      hidden: 0,
      total: 0
    };

    const { itemsArray, requiredField } = getSectionDetails.value(section);

    // If no items array exists, return default counts
    if (!itemsArray) {
      return counts;
    }

    // Calculate all counts in a single pass
    return itemsArray.reduce((acc, item) => {
      acc.total++;

      const isHidden = item?.hide;
      const hasRequiredField = item?.[requiredField]?.trim().length > 0;

      if (isHidden || !hasRequiredField) {
        acc.hidden++;
      } else {
        acc.visible++;
      }

      return acc;
    }, counts);
  });

  // Returns the index of an item within a section with a matching id
  const getItemIndex = computed(() => (section, arrayKey, id) => {
    // Check if resume exists and has the section
    if (!resumeRef.value?.[section]) {
      return -1;
    }

    // Get the array from the section using arrayKey
    const itemsArray = resumeRef.value[section][arrayKey];

    // Check if the array exists and is actually an array
    if (!Array.isArray(itemsArray)) {
      return -1;
    }

    // Find the index of the item with matching id
    return itemsArray.findIndex(item => item?.id === id);
  });
  // Whether a specific section can be shown
  const isSectionVisible = computed(() => (section) => {
    // Early return if resume or section doesn't exist
    if (!resumeRef.value || !resumeRef.value[section]) {
      return false;
    }
    const sections_to_skip = [...specialSections, ...dateSections];
    if (section === 'experiences') {
      return hasVisibleExperiences.value;
    } else if (section === 'professional_skills') {
      return hasVisibleSkills.value;
    } else if (!sections_to_skip.includes(section)) {
      // These are non date generic sections
      const sectionData = resumeRef.value[section];
      // Check if the items_array has at least one non-empty item (i.e. item_name !="")
      const hasVisibleNonEmptyItems = sectionData.items_array?.some(item =>
        item.item_name && item.item_name.trim() !== ''
      );
      // Section is visible if it has at least one non-empty item and is not hidden
      // console.log(section + ' section hasVisibleNonEmptyItems: '+hasVisibleNonEmptyItems + ' , section hide?: '+resumeRef.value[section].hide);
      const isVisible = !sectionData.hide && hasVisibleNonEmptyItems;
      console.log(section + ' section isVisible: ' + isVisible);
      return isVisible;
    } else {
      // These are sections which have item level visibility
      const counts = itemCounts.value(section);
      // console.log(section + ' section counts total: '+counts.total+' , visible: '+counts.visible + ' , hide?: '+resumeRef.value[section].hide);
      return (counts.total > 0 && !resumeRef.value[section].hide) ||
        (counts.visible > 0 && resumeRef.value[section].hide);
    }

  });

  // Whether the next section can be shown
  const isNextSectionVisible = computed(() => (section, sectionType = 'all') => {
    // Early return if resume or section order doesn't exist
    if (!resumeRef.value || !resumeRef.value.section_order?.items_array) {
      return false;
    }

    // Get the current section's index in the section order array
    const sectionOrder = resumeRef.value.section_order.items_array;
    const currentIndex = sectionOrder.indexOf(section);

    // If section not found or it's the last section, return false
    if (currentIndex === -1 || currentIndex === sectionOrder.length - 1) {
      return false;
    }

    // Define the sections to check based on sectionType
    let sectionsToCheck;
    if (sectionType === 'only_non_date') {
      sectionsToCheck = [...nonDateSections, 'professional_skills'];
    } else if (sectionType === 'only_date') {
      sectionsToCheck = [...dateSections, ...specialSections, 'professional_summary'];
    }

    // Check subsequent sections
    for (let i = currentIndex + 1; i < sectionOrder.length; i++) {
      const nextSection = sectionOrder[i];

      // Skip sections not in sectionsToCheck if sectionType is specified
      if (sectionType !== 'all' && !sectionsToCheck.includes(nextSection)) {
        continue;
      }

      // Check if the next section is visible
      if (isSectionVisible.value(nextSection)) {
        return true;
      }
    }

    return false;
  });

  // Whether a specific section item can be shown
  const isSectionItemVisible = computed(() => (section, index) => {
    const { itemsArray, requiredField } = getSectionDetails.value(section);

    // Check if array exists and index is valid
    if (!itemsArray || index < 0 || index >= itemsArray.length) {
      return false;
    }

    const item = itemsArray[index];

    const isHidden = item?.hide;
    const hasRequiredField = item?.[requiredField]?.trim().length > 0;

    return !isHidden && hasRequiredField;
  });
  // Whether the resume has any recommendations across all sections
  const hasVisibleRecommendations = computed(() => {
    // Get all sections from resumeRef
    const sections = Object.keys(resumeRef.value || {});

    // Check if any resume section has recommendations
    return sections.some(section => {
      const recommendations = sectionRecommendations.value(section);
      return Array.isArray(recommendations) && recommendations.length > 0;
    });
  });
  // Get an array of items with recommendations (i.e. where 'hide_recommended' key is true) for a given section
  const sectionRecommendations = computed(() => (section) => {
    // Early return if resume or section doesn't exist
    if (!resumeRef.value || !resumeRef.value[section]) {
      return [];
    }

    const sections_to_skip = [...specialSections, ...dateSections];

    if (section === 'experiences') {
      return experienceRecommendations.value;
    } else if (section === 'educational_qualifications') {
      return educationRecommendations.value;
    } else if (!sections_to_skip.includes(section)) { // Handle regular sections (non-date and non-special sections)
      // Return the section object if hide_recommended is true, otherwise empty array
      return resumeRef.value[section]?.hide_recommended === true
        ? [resumeRef.value[section]]
        : [];
    }

    // Handle date sections and special sections
    const { itemsArray } = getSectionDetails.value(section);
    // console.log('Section: ' + section + ' itemsArray: '+JSON.stringify(itemsArray));

    // If no items array exists, return empty array
    if (!itemsArray) {
      return [];
    }

    // Filter items where hide_recommended is true
    const recommendedItems = itemsArray.filter(item => item?.hide_recommended === true);
    // console.log('Section: ' + section + ' items with recommendations: '+JSON.stringify(recommendedItems));
    // Add section name to each item for context
    return recommendedItems.map(item => ({
      ...item,
      section_name: section
    }));
  });

  // Returns a hash with the contents to show in the overall section
  const overallRecommendationContents = computed(() => {

    const has_career_recommendation = resumeRef.value.career_start_year !== undefined && resumeRef.career_start_year !== null && resumeRef.career_start_year >= 0;
    if (has_career_recommendation || resumeRef.value.additional_recommendations?.length > 0 || hasVisibleRecommendations.value) {
      return {
        career_start_year: resumeRef.value.career_start_year,
        overall_recommendations: resumeRef.value.additional_recommendations,
        other_recommendations: hasVisibleRecommendations.value,
      }
    } else return null;
  })
  // Whether the resume section has a comment to show
  const sectionHasComments = computed(() => (section, index = null, positionIndex = null) => {
    // Early return if resume or section doesn't exist or section is a date section
    if (!resumeRef.value || !resumeRef.value[section] || dateSections.includes(section)) {
      return false;
    }
    let resumeSection = resumeRef.value[section];
    const sections_to_skip = [...specialSections, ...dateSections];
    if (section === 'experiences') {
      const position = resumeRef.value[section][index].positions[positionIndex];
      if (!position) {
        return false;
      } else {
        resumeSection = position;
      }
    } else if (sections_to_skip.includes(section)) {
      const sectionDetails = getSectionDetails.value(section);
      if (!sectionDetails.itemsArray) {
        return false;
      } else {
        resumeSection = sectionDetails.itemsArray[index];
      }
    }
    return resumeSection.comments?.length > 0 || resumeSection.change_reason?.length > 0;
  });
  // Get an array of educations with recommendations to show
  const educationRecommendations = computed(() => {
    // Get the educational qualifications section
    const eduSection = resumeRef.value?.educational_qualifications;

    // If section doesn't exist or has no educations array, return empty array
    if (!eduSection?.educations) {
      return [];
    }

    // Filter educations that meet any of the recommendation conditions
    return eduSection.educations.filter(education => {
      // Skip if education object is null/undefined
      if (!education) {
        return false;
      }

      // Check for hide_recommended flag
      const isHideRecommended = education.hide_recommended === true;

      // Check for hide_date_recommended flag
      const isDateHideRecommended = education.hide_date_recommended === true &&
        (education.school_start_date?.trim().length > 0 || education.school_end_date?.trim().length > 0);

      // Check for non-empty GPA changes
      const hasGpaChanges = Array.isArray(education.gpa_changes) &&
        education.gpa_changes.some(change =>
          typeof change === 'string' && change.trim().length > 0
        );

      // Return true if any of the conditions are met
      return isHideRecommended || isDateHideRecommended || hasGpaChanges;
    });
  });
  // Return the details required to get item counts and visibility for a specific section
  const getSectionDetails = computed(() => (section) => {
    // Get the resume section
    const resumeSection = resumeRef.value?.[section];

    // Default state
    const details = {
      itemsArray: null,
      requiredField: null
    };

    if (!resumeSection) {
      return details;
    }

    // Determine array and required field based on section
    switch (section) {
      case 'educational_qualifications':
        return {
          itemsArray: resumeSection.educations,
          requiredField: 'degree'
        };
      case 'projects_and_assignments':
        return {
          itemsArray: resumeSection.projects,
          requiredField: 'title'
        };
      default:
        return {
          itemsArray: resumeSection.items_array,
          requiredField: 'item_name'
        };
    }
  });

  const hasVisibleSkills = computed(() => {
    const skills = resumeRef.value?.professional_skills;

    if (!skills || skills.hide) {
      return false;
    }

    if (!skills.skill_groups || skills.skill_groups.length === 0) {
      return false;
    }

    return skills.skill_groups.some(skillGroup => {
      return skillGroup.items_array &&
        skillGroup.items_array.length > 0 &&
        skillGroup.items_array.some(skill => skill && skill.trim().length > 0);
    });
  });

  const hasVisibleSkillGroup = computed(() => (groupTitle) => {
    // Safely access nested properties
    const skillGroups = resumeRef.value?.professional_skills?.skill_groups
    if (!skillGroups) return false;

    // Find the skill group with matching title
    const skillGroup = skillGroups.find(group => group.title === groupTitle);

    // Check if group exists and has non-empty items
    return skillGroup?.items_array &&
      skillGroup.items_array.length > 0 &&
      skillGroup.items_array.some(skill =>
        skill && skill.trim().length > 0
      );
  });

  const getSkills = computed(() => (skillGroup) => {
    // Check if skill group exists and has items
    if (skillGroup?.items_array && skillGroup.items_array.length > 0) {
      // Filter out empty strings and trim whitespace before joining
      return skillGroup.items_array
        .filter(skill => skill && skill.trim().length > 0)
        .map(skill => skill.trim())
        .join(', ');
    }
    return ''; // Return empty string if no valid items found
  });

  // Check if it's a date section
  const isDateSection = computed(() => (section) => {
    return dateSections.includes(section);
  });

  // Check if it's a date section
  const isNonDateSection = computed(() => (section) => {
    return nonDateSections.includes(section);
  });

  // Check if it's a date section with at least one item having a date
  //   const hasDate = computed(() => (section) => {
  //     // Check if it's a date section
  //     if (dateSections.includes(section)) {
  //       const items = resumeRef.value?.[section]?.items_array;

  //       if (items && items.length > 0) {
  //         return items.some((item) => item.date !== '');
  //       }
  //     }

  //     return false;
  //   });



  return {
    experienceCounts,
    hasVisibleOrganization,
    experiencePositionCounts,
    getExperiencePositionIndex,
    experienceRecommendations,
    itemCounts,
    getItemIndex,
    hasVisibleSkillGroup,
    getSkills,
    isSectionVisible,
    isNextSectionVisible,
    isSectionItemVisible,
    overallRecommendationContents,
    hasVisibleRecommendations,
    sectionRecommendations,
    sectionHasComments,
    isDateSection,
    isNonDateSection
  }
}