<template>
  <div class="_labelBottom" v-if="showIcon && template == 'SanFrancisco'">
    <svg width="60" height="65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 65h60V20L30 0 0 20v45Z" fill="currentcolor"></path>
    </svg>
  </div>
</template>

<script>

export default {
  name: 'PageFooter',
  props: {
    template: { 
      type: String,
      validator: value => ['London','NewYork','SanFrancisco','Berlin'].includes(value)
    },
    showIcon: {
      type: Boolean,
      default: false
    }
  },
}
</script>