<template>
    <template v-if="section.summary && !section.hide">
      <div :data-section-id="sectionKey">
        <div class="_resume_section" :class="{
          'NoTopMargin': template === 'SanFrancisco'
          }">
          <div class="_resume_title" :class="{
            '_less_margin': template === 'SanFrancisco' || template === 'NewYork' ,
          }">
            <label>{{ section.name }}</label>
            <feedback
              v-if="showCommentIndicators"
              :comments="sectionLevelComments"
              :recommendations="sectionLevelRecommendations"
              :on-margin="false"
            />
          </div>
          <hr v-if="template !== 'Berlin'"/>
          <div data-card-id="summary" class="_card_row">
            <p class="_resume_paragraph">
              <span>{{ arrayToString(section.summary) }}</span>
            </p>
          </div>
        </div>
      </div>
      <hr v-if="template === 'Berlin' && hasNextSection"/>
    </template>
  </template>
  
  <script>
  import Feedback from './Feedback.vue'
  import { labelHelpers } from '../mixins/labelHelpers'
  import { layoutHelpers } from '../mixins/layoutHelpers';
  
  export default {
    name: 'ResumeSummary',
    components: {
      Feedback
    },
    mixins: [labelHelpers, layoutHelpers],
    props: {
      template: {
        type: String,
        required: true
      },
      section: {
        type: Object,
        required: true,
        validator: (obj) => {
          const requiredProps = ['name', 'summary', 'id']
          return requiredProps.every(prop => prop in obj)
        }
      },
      pageLayout: {
        type: String,
        default: 'Center',
        validator: value => ['Left', 'Right', 'Center'].includes(value)
      },
      showCommentIndicators: {
        type: Boolean,
        default: false
      },
      hasComments: {
        type: Boolean,
        required: true
      },
      comments: { //array of comments
        type: Array,
        required: true
      },
      hasRecommendations: {
        type: Boolean,
        required: true
      },
      recommendations: { //array of recommendations
        type: Array,
        required: true
      },
      hasNextSection: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        sectionKey: 'professional_summary',
      }
    }
  }
  </script>