<template>
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Open+Sans">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Montserrat">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Fira+Sans">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Arimo">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Lora">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=EB+Garamond">
    <div :class="templateName" id="Resume" >
        <div class="_page_container _resume_formatting" :name="templateName" ref="pageContainer" 
        v-if="userEditedResume && Object.keys(userEditedResume).length">
            <div class="_content _full" type="Full">
                <div class="_page_resume_template">
                    <!-- Page Header/ Sidebard background -->
                    <page-header
                        :template="templateName"
                        :show-icon="showIcon"
                        :page-layout="pageLayout"
                    />
                    <!-- Page Footer: None -->
                    <!-- Resume Header: Center Layout -->
                    <resume-header
                        v-if="pageLayout === 'Center'"
                        :template="templateName"
                        :name="userEditedResume.name"
                        :contact-info="userEditedResume.contact_information"
                        :show-comment-indicators="showCommentIndicators"
                        :resume-id="userEditedResume.id"
                        :section-order-id="userEditedResume.section_order.id"
                        :section-order-change-reason="userEditedResume.section_order.change_reason"
                        :comments="comments"
                        :has-recommendations="overallRecommendationContents !== null"
                        :recommendations="recommendations" />
                    <!-- Resume Data -->
                    <div class="_resume_columns">
                        <!--Main Column-->
                        <div class="_column _main_content" id="content_column" :style="{ order: columnOrder }">
                             <!-- Sortable Sections -->
                             <template v-for="section in userEditedResume.section_order?.items_array"> 
                                <!-- Professional Summary -->
                                <template v-if="section ==='professional_summary'">
                                    <resume-summary
                                        :template="templateName"
                                        :section="userEditedResume[section]"
                                        :page-layout="pageLayout"
                                        :show-comment-indicators="showCommentIndicators"
                                        :has-comments="sectionHasComments(section)"
                                        :comments="comments"
                                        :has-recommendations="sectionRecommendations(section)?.length > 0"
                                        :recommendations="recommendations"
                                    />
                                </template>
                                <!-- Employment History -->
                                <template v-else-if="section ==='experiences'">
                                    <resume-experiences
                                        :template="templateName"
                                        :section="userEditedResume[section]"
                                        :visible="isSectionVisible(section)"
                                        :has-recommendations="sectionRecommendations(section)?.length > 0"
                                        :recommendations="recommendations"
                                        :has-visible-organization="hasVisibleOrganization"
                                        :hide-experiences="userEditedResume.hide_experiences=== true"
                                        :page-layout="pageLayout"
                                        :show-comment-indicators="showCommentIndicators"
                                        :comments="comments"
                                        :section-has-comments="sectionHasComments"
                                    />
                                </template>
                                <!-- Education History -->
                                <template v-else-if="section=='educational_qualifications'">
                                    <resume-education
                                        :template="templateName"
                                        :section="userEditedResume[section]"
                                        :visible="isSectionVisible(section)"
                                        :page-layout="pageLayout"
                                        :show-comment-indicators="showCommentIndicators"
                                        :has-recommendations="sectionRecommendations(section)?.length > 0"
                                        :recommendations="recommendations"
                                        :is-section-item-visible="isSectionItemVisible"
                                    />
                                </template>
                                <!-- Project List -->
                                <template v-else-if="section=='projects_and_assignments'">
                                    <resume-projects
                                        :template="templateName"
                                        :section="userEditedResume[section]"
                                        :visible="isSectionVisible(section)"
                                        :is-section-item-visible="isSectionItemVisible"
                                        :page-layout="pageLayout"
                                        :show-comment-indicators="showCommentIndicators"
                                        :comments="comments"
                                        :section-has-comments="sectionHasComments"
                                        :has-recommendations="sectionRecommendations(section)?.length > 0"
                                        :recommendations="recommendations"
                                    />
                                </template>
                                <!-- Skills (if Center Layout)-->
                                <template v-else-if="section=='professional_skills' && pageLayout==='Center' ">
                                    <resume-skills
                                        :template="templateName"
                                        :section="userEditedResume[section]"
                                        :visible="isSectionVisible(section)"
                                        :has-visible-skill-group="hasVisibleSkillGroup"
                                        :get-skills="getSkills"
                                        :page-layout="pageLayout"
                                        :show-comment-indicators="showCommentIndicators"
                                        :comments="comments"
                                        :has-comments="sectionHasComments(section)"
                                        :has-recommendations="sectionRecommendations(section)?.length > 0"
                                        :recommendations="recommendations"
                                    />
                                </template>
                                <!-- Date Sections -->
                                <template v-else-if="isDateSection(section)">
                                    <date-section
                                        :template="templateName"
                                        :section="userEditedResume[section]"
                                        :section-key="section"
                                        :visible="isSectionVisible(section)"
                                        :is-section-item-visible="isSectionItemVisible"
                                        :page-layout="pageLayout"
                                        :show-comment-indicators="showCommentIndicators"
                                        :has-recommendations="sectionRecommendations(section)?.length > 0"
                                        :recommendations="recommendations"
                                    />
                                </template>
                                <!-- Non-Date Sections (Only if Center Layout)-->
                                <template v-else-if="isNonDateSection(section) && pageLayout==='Center'">
                                    <non-date-section
                                        :template="templateName"
                                        :section="userEditedResume[section]"
                                        :section-key="section"
                                        :visible="isSectionVisible(section)"
                                        :page-layout="pageLayout"
                                        :show-comment-indicators="showCommentIndicators"
                                        :comments="comments"
                                        :has-comments="sectionHasComments(section)"
                                        :has-recommendations="sectionRecommendations(section)?.length > 0"
                                        :recommendations="recommendations"
                                    />
                                </template>
                            </template>
                        </div>
                         <!--Side Bar:Left or Right Layout -->
                        <div class="_column _sidebar_column" id="sidebar_column" style="order:0" v-if="pageLayout!='Center'">
                            <!-- Resume Header: Left/ Right Layout -->
                            <resume-header
                                :template="templateName"
                                :page-layout="pageLayout"
                                :name="userEditedResume.name"
                                :contact-info="userEditedResume.contact_information"
                                :show-comment-indicators="showCommentIndicators"
                                :resume-id="userEditedResume.id"
                                :section-order-id="userEditedResume.section_order.id"
                                :section-order-change-reason="userEditedResume.section_order.change_reason"
                                :comments="comments"
                                :has-recommendations="overallRecommendationContents !== null"
                                :recommendations="recommendations" />
                            <template v-for="section in userEditedResume.section_order?.items_array">
                                <template v-if="section === 'professional_skills'">
                                    <resume-skills
                                        :template="templateName"
                                        :section="userEditedResume[section]"
                                        :visible="isSectionVisible(section)"
                                        :has-visible-skill-group="hasVisibleSkillGroup"
                                        :get-skills="getSkills"
                                        :page-layout="pageLayout"
                                        :show-comment-indicators="showCommentIndicators"
                                        :comments="comments"
                                        :has-comments="sectionHasComments(section)"
                                        :has-recommendations="sectionRecommendations(section)?.length > 0"
                                        :recommendations="recommendations"
                                    />
                                </template>
                                 <!-- Other Non-Date Sections Only-->
                                <template v-else-if="isNonDateSection(section)">
                                    <non-date-section
                                            :template="templateName"
                                            :section="userEditedResume[section]"
                                            :section-key="section"
                                            :visible="isSectionVisible(section)"
                                            :page-layout="pageLayout"
                                            :show-comment-indicators="showCommentIndicators"
                                            :comments="comments"
                                            :has-comments="sectionHasComments(section)"
                                            :has-recommendations="sectionRecommendations(section)?.length > 0"
                                            :recommendations="recommendations"
                                        />
                                </template>
                            </template>
                        </div>
                    </div>
                    <!-- <div class="_keywords">This has some sample text to check spacing.</div> -->
                </div>
            </div>     
        </div>
    </div>
</template>

<script>
import Icon from '../ui_controls/Icon.vue';
import ResumeHeader from './resume_sections/ResumeHeader.vue';
import ResumeSummary from './resume_sections/ResumeSummary.vue';
import ResumeEducation from './resume_sections/ResumeEducation.vue';
import ResumeProjects from './resume_sections/ResumeProjects.vue';
import ResumeExperiences from './resume_sections/ResumeExperiences.vue';
import ResumeSkills from './resume_sections/ResumeSkills.vue';
import DateSection from './resume_sections/DateSection.vue';
import NonDateSection from './resume_sections/NonDateSection.vue';
import PageHeader from './resume_sections/PageHeader.vue';
import { layoutHelpers } from './mixins/layoutHelpers';

export default {
  name: 'NewYork',
  props: {
    userEditedResume: {
        type: Object,
        required: true,
        default: () => ({
            name: '',
            contact_information: {
                email: '',
                phone_number: '',
                city: '',
            },
            professional_summary: '',
            experiences: [],
            educational_qualifications: {
                educations: [],
            },
        }),
    },
    pageLayout: {
        type: String,
        default: 'Left',
    },
    showIcon: { //not necessary for this template
        type: Boolean,
        default: false,
    },
    comments: {
        type: Array,
        required: true,
    },
    showCommentIndicators: {
        type: Boolean,
        default: true,
    },
    recommendations: {
            type: Array,
            required: true,
        },
        overallRecommendationContents: {
            type: Object,
            required: true,
        },
        hasVisibleOrganization: {
            type: Function,
            required: true,
        },
        isSectionVisible: {
            type: Function,
            required: true,
        },
        isNextSectionVisible: {
            type: Function,
            required: true,
        },
        isSectionItemVisible: {
            type: Function,
            required: true,
        },
        sectionRecommendations: {
            type: Function,
            required: true,
        },
        hasVisibleSkillGroup: {
            type: Function,
            required: true,
        },
        getSkills: {
            type: Function,
            required: true,
        },
        sectionHasComments: {
            type: Function,
            required: true,
        },
        isDateSection: {
            type: Function,
            required: true,
        },
        isNonDateSection: {
            type: Function,
            required: true,
        }
  },
  mixins: [layoutHelpers],
  components: {
    Icon,
    ResumeHeader,
    ResumeSummary,
    ResumeEducation,
    ResumeProjects,
    ResumeExperiences,
    ResumeSkills,
    DateSection,
    NonDateSection,
    PageHeader
  },
  data() {
    return {
        templateName: 'Tokyo',   
    };
  },
};
</script>