<template v-if="hasContent">
    <div class="CommentCard" :class="{ focus: onFocus}"  
        @mouseover="handleMouseOver" @mouseout="handleMouseOut"
        @click="focusComment">
        <!-- Title Row -->
        <div class="CommentHeaderRow" :class="{ lessPadding : subTitle !== null }" >
            <div class="HorizontalGroup">
                <div class="CommentHeaderText TextTruncate">{{ title }}</div>
            </div>
            <!-- Minimize Button -->
            <div class="ChildCardIconButton MarginRight">
                <themed-button :componentId=componentId
                    button-type="icon" :icon="isVisible ? 'compress' : 'enlarge'"
                    :tooltip-text="isVisible ? BUTTON_LABEL_HIDE_COMMENTS: BUTTON_LABEL_SHOW_COMMENTS"
                    @buttonClick="toggleComment" />
            </div>
        </div>
        <!-- Sub title row -->
        <div class="CommentHeaderRow noTopPadding" v-if="subTitle">
            <div class="CommentText DescriptionColor TextTruncate">{{ subTitle }}</div>
        </div>
        <!-- Diff Summary Row -->
        <template v-if="showDiff">
            <div class="CommentSummaryRow" v-if="!contentStatus || contentStatus==='generated'">
                <div class="deleteForeground"> 
                    <span>-{{numLinesRemoved}} </span>
                    <span class="DescriptionColor">{{ numLinesRemoved===1 ? MESSAGE_LINES_REMOVED_SINGULAR: MESSAGE_LINES_REMOVED_PLURAL}}</span>
                </div>
                <div class="addForeground"> 
                    <span>+{{numLinesAdded}} </span>
                    <span class="DescriptionColor"> {{ numLinesAdded===1 ? MESSAGE_LINES_ADDED_SINGULAR: MESSAGE_LINES_ADDED_PLURAL }}</span>
                </div>
                
            </div>
            <div class="CommentSummaryRow DescriptionColor noTopPadding" v-else>{{ MESSAGE_USER_EDITED }}</div>
        </template>
        <div class="RegularCardSection" :class="{ collapsed: !isVisible}">
            <!-- Diff Array Contents -->
            <template v-if=showDiff>
                <!-- List of diff strings -->
                <ul class="CommentList">
                    <li class="CommentRow" :class="change_color(comment_line)" v-for="(comment_line) in content.comments">
                        <div class="HorizontalGroup">
                            <div class="CommentBulletContainer" :class="change_color(comment_line)">{{ change_indicator(comment_line) }}</div>
                            <div class="CommentText" :class="{'StrikeThrough': contentStatus && contentStatus !== 'generated'}"> {{ comment_line.slice(4).trim() }}</div>
                        </div>
                    </li>
                </ul>
            </template>
            <!-- Change Reason Contents -->
            <template v-if="showChangeReason">
                <div class="CommentHeaderRow" v-if="showDiff">
                    <div class="CommentHeaderText" :class="onFocus ? 'focus' : 'normal'">{{ LABEL_CHANGE_REASON}}</div>
                </div>
                <ul class="CommentList">
                    <li class="CommentRow"
                        v-for="(comment_line) in content.change_reason">
                        <div class="HorizontalGroup AlignTop">
                            <div class="CommentBulletContainer">
                                <Icon iconName="circle-checklist"/>
                            </div>
                            <div class="CommentText">{{ comment_line }}</div>
                        </div>
                    </li>
                </ul>
            </template>
            <!-- Restore Buttons -->
            <template v-if="showDiff">
                <div class="CommentButtonContainer" v-if="contentStatus && contentStatus==='generated'">
                    <themed-button componentId="restore_original" width="full"
                        button-type="secondary" :label="BUTTON_LABEL_ORIGNAL"
                        @buttonClick="restoreOriginal" />
                </div>
                <div class="CommentButtonContainer" v-if="contentStatus && contentStatus==='original'">
                    <themed-button componentId="restore_generated" width="full"
                        button-type="secondary" :label="BUTTON_LABEL_GENERATED"
                        @buttonClick="restoreGenerated" />
                </div>
                <div class="CommentButtonContainer" v-if="contentStatus && contentStatus==='edited'">
                    <div class="TwoButtonRow">
                        <themed-button componentId="restore_original" width="full"
                            button-type="secondary" :label="BUTTON_LABEL_ORIGINAL_SHORT"
                            @buttonClick="restoreOriginal" />
                    </div>        
                    <div class="TwoButtonRow">
                        <themed-button componentId="restore_generated" width="full"
                            button-type="secondary" :label="BUTTON_LABEL_GENERATED_SHORT"
                            @buttonClick="restoreGenerated" />
                    </div>
                </div>
            </template>
            <!-- ATS Score Contents -->
            <template v-else-if="type ==='ats_score'">
                <!-- score summaries -->
                <ul class="CommentList">
                    <li class="CommentRow NoBottomPadding">
                        <div class="HorizontalGroup">
                            <div class="CommentBulletContainer">
                                <Icon iconName="circle-checklist"/>
                            </div>
                            <div class="CommentText">{{ MESSAGE_ORIGINAL_SCORE + content.original_score + ' (' + scoreToText(content.original_score) }})</div>
                        </div>
                    </li>
                    <li class="CommentRow noTopPadding">
                        <div class="HorizontalGroup">
                            <div class="CommentBulletContainer">
                                <Icon iconName="circle-checklist"/>
                            </div>
                            <div class="CommentText">{{ MESSAGE_NEW_SCORE + content.new_score + ' (' + scoreToText(content.new_score) }})</div>
                        </div>
                    </li>
                </ul>
                <!-- chart -->
                <div class="ChartSection">
                    <div class="CommentChartContainer">
                        <doughnut-chart :percentage="content.new_score" 
                            :color="scoreToColor(content.new_score)"></doughnut-chart>
                    </div>
                </div>
                <!-- update score button -->
                <div class="CommentButtonContainer">
                    <themed-button componentId="recalculate_score" width="full"
                        button-type="secondary" :label="BUTTON_LABEL_UPDATE_SCORE"
                        @buttonClick="recalculateScore" />
                </div>
                <!-- Keywords section -->
                <ul class="CommentList">
                    <!-- Keywords added -->
                    <li class="CommentRow"  v-if="this.content.keywords_added?.length>0">
                        <div class="HorizontalGroup">
                            <div class="CommentBulletContainer">
                                <Icon iconName="circle-checklist"/>
                            </div>
                            <div class="CommentText"> {{MESSAGE_KEYWORDS_ADDED}}
                                <div class="ImpactPill KeywordPillMargin TextTruncate" v-for="(keyword) in content.keywords_added"> {{ keyword }}</div>
                            </div>
                        </div>
                    </li>
                    <!-- Keywords increased -->
                    <li class="CommentRow" v-if="this.content.keywords_increased?.length>0">
                        <div class="HorizontalGroup">
                            <div class="CommentBulletContainer">
                                <Icon iconName="circle-checklist"/>
                            </div>
                            <div class="CommentText InlineText"> {{ MESSAGE_KEYWORDS_INCREASED }}
                                <template v-if="!keywords_increased_expanded">
                                    <div class="ImpactPill KeywordPillMargin TextTruncate" v-for="(keyword) in limitedKeywords(content.keywords_increased)"> {{ keyword }}</div>
                                    <button class="TertiaryButton FontSmall" v-if="getMoreButtonLabel(content.keywords_increased)" @click="toggleIncreasedKeywordsSection"> 
                                        {{ getMoreButtonLabel(content.keywords_increased) }} </button>
                                </template>
                                <template v-else>
                                    <div class="ImpactPill KeywordPillMargin TextTruncate" v-for="(keyword) in content.keywords_increased"> {{ keyword }}</div>
                                    <button class="TertiaryButton FontSmall" @click="toggleIncreasedKeywordsSection"> {{ BUTTON_LABEL_LESS }} </button>
                                </template>
                            </div>
                        </div>
                    </li>
                    <!-- Keywords missing-->
                    <li class="CommentRow"  v-if="this.content.keywords_missing?.length>0">
                        <div class="HorizontalGroup">
                            <div class="CommentBulletContainer">
                                <Icon iconName="circle-checklist"/>
                            </div>
                            <div class="CommentText InlineText"> {{ MESSAGE_KEYWORDS_MISSING }}
                                <template v-if="!keywords_missing_expanded">
                                    <div class="ImpactPill KeywordPillMargin TextTruncate" v-for="(keyword) in limitedKeywords(content.keywords_missing)"> {{ keyword }}</div>
                                    <button class="TertiaryButton FontSmall" v-if="getMoreButtonLabel(content.keywords_missing)" @click="toggleMissingKeywordsSection"> 
                                        {{ getMoreButtonLabel(content.keywords_missing) }} </button>
                                </template>
                                <template v-else>
                                    <div class="ImpactPill KeywordPillMargin TextTruncate" v-for="(keyword) in content.keywords_missing"> {{ keyword }}</div>
                                    <button class="TertiaryButton FontSmall" @click="toggleMissingKeywordsSection"> {{ BUTTON_LABEL_LESS }} </button>
                                </template>
                            </div>
                        </div>
                    </li>
                </ul>
            </template>
            <!-- Recommendation Contents -->
            <template v-else-if="type ==='recommendation'">
                <div class="CommentHeaderRow" v-if="recommendationKey!=='educational_qualifications' || hasHideRecommendation">
                    <div class="CommentText">{{ recommendationIntro }}:</div>
                </div>
                <!-- List of Hide Recommendations -->
                <template v-if="recommendationKey!=='resume'" v-for="(item) in content">
                    <template v-if="item.hide_recommended && item.hide_reason?.length>0">
                        <ul class="CommentList">
                            <li class="CommentRow">
                                <div class="HorizontalGroup AlignTop">
                                    <div class="CommentBulletContainer" :class="{'SuccessColor': sectionHideStatus && item.hide  }">
                                        <Icon
                                            :iconName="sectionHideStatus && item.hide ? 'circle-check-filled' : 'circle'"/>
                                    </div>
                                    <div class="VerticalCommentStack">
                                        <!-- Header -->
                                        <div class="CommentText" v-if="recommendationKey === 'experiences'">{{ item.job_title + ' @ ' + item.company_name}}:</div>
                                        <div class="CommentText" v-else-if="recommendationKey === 'educational_qualifications'">{{ item.degree + ' ( ' + item.school + ' )'}}:</div>
                                        <div class="CommentText" v-else-if="recommendationKey === 'projects_and_assignments'">{{ item.title}}:</div>
                                        <div class="CommentText" v-else-if="dateSections.includes(recommendationKey)">{{ item.item_name}}:</div>
                                        <!-- Hide Reasons -->
                                        <div class="TextDescription" v-for="(reason) in item.hide_reason" > {{ reason }} </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <!-- Accept/Reject Buttons -->
                        <div class="CommentButtonContainer" v-if="sectionHideStatus && item.hide">
                            <themed-button :componentId="'reject_hide_' + item.id" width="full"
                                button-type="secondary" :label="BUTTON_LABEL_REJECT"
                                @buttonClick="executeHide(false, item.id)" />
                        </div>
                        <div class="CommentButtonContainer" v-else>
                            <themed-button :componentId="'accept_hide_' + item.id" width="full"
                                button-type="secondary" :label="BUTTON_LABEL_ACCEPT"
                                @buttonClick="executeHide(true, item.id)" />
                        </div>
                    </template>
                </template>
                <!-- Overall Recommendations - List -->
                <template v-else>
                    <ul class="CommentList">
                        <!-- Summarize if any content needs to be hidden -->
                        <li class="CommentRow" v-if="content.other_recommendations">
                            <div class="HorizontalGroup AlignTop">
                                <div class="CommentBulletContainer">
                                    <Icon iconName="circle-checklist"/>
                                </div>
                                <div class="VerticalCommentStack">
                                    <div class="TextDescription"  > {{ MESSAGE_HIDE_SOME }} </div>
                                </div>
                            </div>
                        </li>
                        <!-- Num Page Recommendations -->
                        <li class="CommentRow" v-if="numPageRecommendation">
                            <div class="HorizontalGroup AlignTop">
                                <div class="CommentBulletContainer" :class="{'SuccessColor': numPageRecommendationMet }">
                                    <Icon :iconName="numPageRecommendationMet ? 'circle-check-filled' : 'circle'"/>
                                </div>
                                <div class="VerticalCommentStack">
                                    <div class="TextDescription"  > {{ numPageRecommendation }} </div>
                                </div>
                            </div>
                        </li>
                        <!-- List of additional recommendations -->
                        <li class="CommentRow" v-for="(reason) in content.overall_recommendations">
                            <div class="HorizontalGroup AlignTop">
                                <div class="CommentBulletContainer">
                                    <Icon iconName="circle-checklist"/>
                                </div>
                                <div class="VerticalCommentStack">
                                    <div class="TextDescription"  > {{ reason }} </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </template>
                <!--  Hide Date Recommendation -->
                <template v-if="hideDate">
                    <div class="CommentHeaderRow">
                        <div class="CommentText"> {{ MESSAGE_HIDE_DATE }}:</div>
                    </div>
                    <!-- Hide Date Reason -->
                    <ul class="CommentList">
                        <li class="CommentRow" >
                            <div class="HorizontalGroup AlignTop">
                                <div class="CommentBulletContainer" :class="{'SuccessColor': isDateHidden }">
                                    <Icon
                                        :iconName="isDateHidden ? 'circle-check-filled' : 'circle'"/>
                                </div>
                                <div class="VerticalCommentStack">
                                    <div class="TextDescription"> {{ MESSAGE_HIDE_DATE_REASON }} </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- Accept/Reject Buttons -->
                    <div class="CommentButtonContainer" v-if="isDateHidden">
                        <themed-button componentId="reject_date_hide" width="full"
                            button-type="secondary" :label="BUTTON_LABEL_REJECT"
                            @buttonClick="executeDateHide(false)" />
                    </div>
                    <div class="CommentButtonContainer" v-else>
                        <themed-button componentId="accept_date_hide" width="full"
                            button-type="secondary" :label="BUTTON_LABEL_ACCEPT"
                            @buttonClick="executeDateHide(true)" />
                    </div>
                </template>
                <!--  GPA Recommendation -->
                <template v-if="hasGPARecommendation">
                    <div class="CommentHeaderRow">
                        <div class="CommentText"> {{ gpaRecommendationText }}:</div>
                    </div>
                    <!-- List of GPA Changes -->
                    <ul class="CommentList" v-for="(item) in content">
                        <li class="CommentRow" v-if="item.gpa_changes?.length>0">
                            <div class="HorizontalGroup AlignTop">
                                <div class="CommentBulletContainer">
                                    <Icon iconName="circle-checklist"/>
                                </div>
                                <div class="VerticalCommentStack">
                                    <div class="TextDescription" v-for="(reason) in item.gpa_changes" > {{ 'For ' + item.degree + ': ' + reason }} </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </template>
            </template>
        </div>
    </div>
  </template>
  
  <script>
  import ThemedButton from './ThemedButton.vue';  //Import the themed button component
  import DoughnutChart from './DoughnutChart.vue';  //Import the doughnut chart component
  import Icon from './Icon.vue';  //Import icons library

  export default {
    name: 'comment-card',
    emits: {
        'buttonClick': (id) => typeof id === 'string', // the buttonClick event contains an 'id' string
        'recommendationAction': (details) => typeof details === 'object', // the recommendationAction event contains a 'details' object
    },
    props: {
      componentId: { //the identifer for the card
        type: String,
        required: true,
      },
      title: { //The title to display for the card
        type: String,
        required: true,
      },
      subTitle: { //The sub-title to display for the card
        type: String,
        default: null,
      },
      type: { //Options = 'change_summary', 'ats_score', 'recommendation'
        // 'change_summary' type expects an object at least one of the 2 properties (arrays of strings): comments or change_reason
        type: String,
        enum: ['change_summary', 'ats_score', 'recommendation'],
        default: 'change_summary'
      },
      content: { //Contents to display in the card
        type: Object,
        required: true,
      },
      onFocus: { //Whether the card is being focused
        type: Boolean,
        default: false,
      },
      isVisible: { //Whether the card can be displayed
        type: Boolean,
        default: true,
      },
      contentStatus: { //Options: 'original', 'generated', 'edited'
      // This is required only for comment cards of type = 'change_summary' for the professional summary and position sections
        type: String,
      },
      recommendationKey: { //Section key for the recommendation
        type: String,
      },
      sectionHideStatus: { //Whether the top level section is hidden. (Required for recommendation type comments only)
        type: Boolean,
        default: false,
      }
    },
    components: {
      ThemedButton,
      DoughnutChart,
      Icon,
    },
    data() {
        return {
            isHovered: false,
            dateSections: [ 
                'awards_and_recognitions',
                'publications_and_presentations',
                'trainings_and_certifications'
            ],
            specialSections: [ 
                'experiences',
                'educational_qualifications',
                'projects_and_assignments'
            ],
            keywords_increased_expanded: false, //whether the keywords increased section is expanded
            keywords_missing_expanded: false, //whether the keywords missing section is expanded
            // ---------- LABELS ----------
            BUTTON_LABEL_HIDE_COMMENTS: 'Hide Details',
            BUTTON_LABEL_SHOW_COMMENTS: 'Show Details',
            BUTTON_LABEL_UPDATE_SCORE:'Re-calculate',
            BUTTON_LABEL_ORIGNAL:'Restore Original Content',
            BUTTON_LABEL_GENERATED:'Restore Generated Content',
            BUTTON_LABEL_ORIGINAL_SHORT:'Original',
            BUTTON_LABEL_GENERATED_SHORT:'Generated',
            BUTTON_LABEL_ACCEPT:'Accept',
            BUTTON_LABEL_REJECT:'Reject Recommendation',
            BUTTON_LABEL_AND: '.. and',
            BUTTON_LABEL_MORE: 'more >>',
            BUTTON_LABEL_LESS: '<< show less',
            LABEL_CHANGE_REASON: 'Rationale',
            MESSAGE_NEW_SCORE:'Your new score: ',
            MESSAGE_ORIGINAL_SCORE:'Your original score: ',
            MESSAGE_USER_EDITED: 'You edited this section',
            MESSAGE_LINES_REMOVED_SINGULAR: ' Line Removed',
            MESSAGE_LINES_ADDED_SINGULAR: ' Line Added',
            MESSAGE_LINES_REMOVED_PLURAL: ' Lines Removed',
            MESSAGE_LINES_ADDED_PLURAL: ' Lines Added',
            MESSAGE_KEYWORDS_ADDED: 'New keywords added:',
            MESSAGE_KEYWORDS_INCREASED: 'Existing keywords increased:',
            MESSAGE_KEYWORDS_MISSING: 'Keywords still missing: ',
            MESSAGE_RECOMMEND_HIDE_ITEMS: 'We recommend hiding the following ',
            MESSAGE_RECOMMEND_HIDE_SECTION: 'We recommend hiding this section',
            MESSAGE_OVERALL_RECOMMENDATION: 'Your resume looks great! If you want to improve it further, here are some suggestions',
            MESSAGE_HIDE_DATE: 'We recommend hiding your graduation date(s)',
            MESSAGE_HIDE_DATE_REASON: 'You have degrees which are 15+ years old. Hiding the graduation date could help minimize any potential age bias against you during the screening process.',
            MESSAGE_RECOMMENDATION_GENERIC: 'We recommend the following',
            MESSAGE_RECOMMENDATION_GENERIC_ADDITIONAL: 'We also recommend the following',
            MESSAGE_HIDE_SOME: 'We recommend hiding a few item(s) in your resume. Expand card(s) below for details.',
            MESSAGE_NUM_PAGE_0_5: 'For early career professionals (with less than 5 years of experience), we recommend a 1-page resume.' ,
            MESSAGE_NUM_PAGE_5_10: 'For mid-level professionals (with 5-10 years of experience), we recommend a 1 - 2 page resume.' ,
            MESSAGE_NUM_PAGE_10_20: 'For experienced professionals (with 10-20 years of experience), we recommend a 2-page resume.' ,
            MESSAGE_NUM_PAGE_20_PLUS: 'For very experienced professionals (with 20+ years of experience), we recommend a 2 - 3 page resume.' ,
            sectionLabels: {  
                professional_summary: 'Summary',
                experiences: 'Positions',
                educational_qualifications: 'Educations',
                projects_and_assignments: 'Projects',
                professional_skills: 'Skills',
                awards_and_recognitions: 'Awards',
                publications_and_presentations: 'Publications',
                trainings_and_certifications: 'Certifications',
                technical_tools: 'Tools',
                professional_associations: 'Professional Networks',
                volunteer_experience: 'Community Services',
                languages_spoken: 'Languages',
                hobbies_and_activities: 'Interests',
            },
        };
    },
    computed: {
        /**
         * Determines if the string difference array should be displayed.
         * This is true when the comment card type is 'change_summary' and the content has 'comments' property.
         * 
         * @returns {boolean} True if the difference should be shown, otherwise false.
         */
        showDiff() {
            return this.type === 'change_summary' && this.content?.hasOwnProperty('comments') ;
        },
        showChangeReason() {
            return this.type === 'change_summary' && this.content?.hasOwnProperty('change_reason'); ;
        },
        numLinesAdded() {
            return this.content.comments?.filter(line => line.startsWith('[+]')).length;
        },
        numLinesRemoved() {
            return this.content.comments?.filter(line => line.startsWith('[-]')).length;
        },
        //Returns true if it has some content to display
        hasContent() {
            return ( this.type!=='ats_score' && this.content!==null || 
            this.type==='ats_score' && this.content?.hasOwnProperty('new_score'));
        },
        // Returns true if the section has an array of hashes
        isSectionWithHashArray() {
            return (section) => {
                const sections_to_skip = [...this.specialSections, ...this.dateSections];
                return sections_to_skip.includes(section);
            }
        },
        hideDate() {
            if (this.recommendationKey === 'educational_qualifications') {
                //return true if any item in the content array has the key 'hide_date_recommended' set to true  
                return this.content?.some(item => item.hasOwnProperty('hide_date_recommended') && item.hide_date_recommended
                 && (item.school_start_date?.trim().length > 0 || item.school_end_date?.trim().length > 0));
            } else {
                return false;
            }
        },
        isDateHidden() {
            if (this.recommendationKey === 'educational_qualifications') {
                //return true if all items in the content array has the key 'hide_date' set to true  
                return this.content?.every(item => item.hasOwnProperty('hide_date') && item.hide_date);
            } else {
                return false;
            }
        },
        hasGPARecommendation() {
            if (this.recommendationKey === 'educational_qualifications') {
                //return true if any item in the content array has the key 'gpa_changes' && the 'gpa_changes' array has at least one non empty string
                return this.content?.some(item => item.hasOwnProperty('gpa_changes') && item.gpa_changes?.some(gpa => gpa.trim() !== ''));
            } else {
                return false;
            }
        },
        hasHideRecommendation() {
            if (this.recommendationKey === 'educational_qualifications') {
                //return true if any item in the content array has the key 'hide_reason'and the 'hide_reason' array has at least one non empty string
                return this.content?.some(item => item.hide_recommended && item.hasOwnProperty('hide_reason') && item.hide_reason?.some(reason => reason.trim() !== ''));
            } else {
                return false;
            }
        },
        gpaRecommendationText() {
            if (this.hideDate || this.hasHideRecommendation) {
                return this.MESSAGE_RECOMMENDATION_GENERIC_ADDITIONAL;
            } else {
                return this.MESSAGE_RECOMMENDATION_GENERIC;
            }
        },
        // The recommendation intro message before listing the recommendation bullet points
        recommendationIntro() {
            if ( this.isSectionWithHashArray(this.recommendationKey) ) {
                return this.MESSAGE_RECOMMEND_HIDE_ITEMS + this.sectionLabels[this.recommendationKey].toLowerCase();
            } else if (this.recommendationKey === 'resume') {
                return this.MESSAGE_OVERALL_RECOMMENDATION;
            } else {
               return this.MESSAGE_RECOMMEND_HIDE_SECTION;
            }         
        },
        // The recommendation bullet point for max pages to use
        numPageRecommendation() {
            console.log("Getting num page recommendations..");
            // Return null if career_start_year is null or undefined
            if (this.content?.career_start_year === undefined || this.content?.career_start_year === null) {
                return null;
            }

            // Calculate years of experience
            const currentYear = new Date().getFullYear();
            const yearsOfExperience = this.content.career_start_year ===0 ? 0 : currentYear - this.content.career_start_year;

            // Return recommendations based on experience ranges
            if (yearsOfExperience <= 5) {
                return this.MESSAGE_NUM_PAGE_0_5;
            } else if (yearsOfExperience <= 10) {
                return this.MESSAGE_NUM_PAGE_5_10;
            } else if (yearsOfExperience <= 20) {
                return this.MESSAGE_NUM_PAGE_10_20;
            } else {
                return this.MESSAGE_NUM_PAGE_20_PLUS;
            }
        },
        /**
         * Return true if the recommendation for the number of pages is met.
         * Return null if either career_start_year or num_pages is null/undefined.
         * 
         * The recommendation rules are as follows:
         * - 0-5 years of experience: 1 page
         * - 5-10 years of experience: 1-2 pages
         * - 10-20 years of experience: 2 pages
         * - 20+ years of experience: 2-3 pages
         */
        numPageRecommendationMet() {
            // Return null if either career_start_year or num_pages is null/undefined
            if (this.content?.career_start_year === undefined || this.content?.career_start_year === null || this.content?.num_pages == null) {
                return null;
            }

            // Calculate years of experience
            const currentYear = new Date().getFullYear();
            const yearsOfExperience = this.content.career_start_year ===0 ? 0 : currentYear - this.content.career_start_year;
            const numPages = this.content.num_pages;

            // Check conditions based on years of experience
            if (yearsOfExperience <= 5) {
                return numPages === 1;
            } else if (yearsOfExperience <= 10) {
                return numPages >= 1 && numPages <= 2;
            } else if (yearsOfExperience <= 20) {
                return numPages === 2;
            } else {
            // 20+ years
                return numPages >= 2 && numPages <= 3;
            }
        }
    },
    methods: {
        // The class that determines the comment's background color
        change_color(input) {
            if(this.isHovered || this.onFocus) {
                if (input.startsWith('[+]')) {
                return 'addBackground addForeground';
                } else if (input.startsWith('[-]')) {
                    return 'deleteBackground deleteForeground';
                }
            }
        },
        // The indicator '+' or '-' to show at the beginning of a comment
        change_indicator(input) {
            if (input.startsWith('[+]')) {
                return '+';
            } else if (input.startsWith('[-]')) {
                return '-';
            } else {
                return ' ';
            }
        },
        toggleComment() {
            this.$emit('buttonClick', 'toggle_comment_' +this.componentId);
        },
        recalculateScore() {
            this.$emit('buttonClick', 'recalculate_score');
        },
        focusComment( ) {
            this.$emit('buttonClick', 'focus_comment_' +this.componentId);
        },
        restoreOriginal( ) {
            this.$emit('buttonClick', 'restore_original_' +this.componentId);
        },
        restoreGenerated( ) {
            this.$emit('buttonClick', 'restore_generated_' +this.componentId);
        },
        handleMouseOver() {
            this.isHovered=true;
        },
        handleMouseOut() {
            this.isHovered=false;
        },
        //The icon color to use give a particular score
        scoreToColor (value) {
            if (value<35) {
                return 'var(--danger-700)';
            } else if (value<65) {
                return 'var(--warning-600)';
            } else if (value<90) {
                return 'var(--success-500)';
            }else {
                return 'var(--success-700)';
            }
        },
        //The text to show given a particular score 
        scoreToText (value) {
            if (value<35) {
                return 'Low';
            } else if (value<65) {
                return 'Medium';
            } else if (value<90) {
                return 'High';
            }else {
                return 'Superb';
            }
        },
        // Limit the number of keywords shown to 6
        // If there are more than 6, list 5 keywords and the show '..and x more' button
        limitedKeywords(keywords) {
            if (keywords.length > 6) {
                return keywords.slice(0, 5);
            } else {
                return keywords;
            }
        },
        // If there are more than 6 keywords, show '..and x more'
        // Otherwise, return null (to indicate no button should be shown)
        getMoreButtonLabel(keywords) {
            if (keywords.length > 6) {
                return `${this.BUTTON_LABEL_AND} ${keywords.length-5} ${this.BUTTON_LABEL_MORE}`
            } else {
                return null;
            }
        },
        executeHide(hide, id) {
            const eventDetails = {
                recommendationKey: this.recommendationKey,
                id: id,
                accept: hide,
                hideType: 'hide_item'
            };
            this.$emit('recommendationAction', eventDetails);
        },
        executeDateHide(hide) {
            const eventDetails = {
                recommendationKey: this.recommendationKey,
                accept: hide,
                hideType: 'hide_date'
            };
            this.$emit('recommendationAction', eventDetails);
        },
        // Expand/collapse the increased keywords section
        toggleIncreasedKeywordsSection() {
            this.keywords_increased_expanded = !this.keywords_increased_expanded;
        },
        // Expand/collapse the missing keywords section
        toggleMissingKeywordsSection() {
            this.keywords_missing_expanded = !this.keywords_missing_expanded;
        },
    },
    // created() {
    //     console.log('*** CommentCard ' + this.componentId + ', content status: ' + this.contentStatus +' contents:\n'+JSON.stringify(this.content));
    // },
  };
  </script>
  
  <style scoped>
    .CommentCard {
        margin: 0.625rem 1rem;
        padding: 1rem 0.625rem;
        width: calc(100% - 2rem);
        color: var(--gray-900);
        background-color: var(--gray-100);
        border-radius: var(--card-border-radius);
        border: solid var(--border-thickness-normal) var(--color-card-border);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        transition: transform var(--transition-time) ease-in-out;
    }
    .CommentCard.hide {
        transform: translateY(-250rem);
    }

    .CommentCard.focus{
        background-color: var(--color-background-canvas);
        border-color: var(--color-input-normal);
        /* transform: translateX(-10px); */
        box-shadow: var(--shadow-elevation-three);
    }

    .CommentCard:hover{
        background-color: var(--color-background-canvas);
        /* box-shadow: var(--shadow-elevation-three); */
    }

    .CommentList {
        list-style-type: none; /* Remove default bullet point */
        margin: 0;
        margin-top: 0.25rem;
        width: 100%;
    }
    .CommentRow{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0.4rem 0;
        width: 100%;
    }
    .CommentHeaderRow{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.625rem 0 0.625rem var(--border-thickness-selected);
        width: 100%;
    }
    .CommentHeaderText {
        position: relative;
        font-size: var(--size-normal); 
        font-weight: var(--weight-bold); ; 
        line-height: var(--size-normal);
        color: var(--color-text-paragraph);
        text-align: left;
        align-self: center;
    }

    .CommentHeaderText.normal{
        color: var(--color-text-description);
    }
    .CommentHeaderText.focus{
        color: var(--color-input-selected);
    }
    .VerticalCommentStack {
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        width: calc(100% - 2.5rem);
    }
    .CommentText {
        position: relative;
        font-size: var(--size-small); 
        font-weight: var(--weight-normal); ; 
        line-height: var(--size-small);
        color: var(--color-text-paragraph);
        text-align: left;
        width: calc(100% - 2.5rem);
    }

    .CommentBulletContainer {
        height: 1.25rem;
        width: 1.25rem;
        margin: var(--border-thickness-selected);
        /* Color is set in main.css */
        align-self: flex-start;
    }

    .CommentIconButton {
        width: 1.25rem;
        height: 1.25rem;
        margin: 0 0.625rem 0 0;
        align-self: center;
    }
  .CommentSummaryRow {
    display: flex;
    align-items: center;
    gap: 0.35rem;
    font-size: var(--size-small); 
  }
  .CommentChartContainer {
    width: 10rem;
    height: 10rem;
  }
  .ChartSection{
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .CommentButtonContainer {
    width: calc( 100% - 3.75rem);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.75rem 0;
    align-self: center;
    gap: 0.625rem;
 }
  .TwoButtonRow {
    width: 50%;
    display: flex;
    justify-content: center;
    gap: 0.625rem;
 }
  .addBackground {
    background-color: var( --success-100);
  }
  .addForeground {
    color: var(--success-700);
  }
  .deleteBackground {
    background-color: var(--danger-50);
  }
  .deleteForeground {
    color: var(--danger-900);
  }
  .hide {
    display: none;
  }
  .lessPadding {
    padding-bottom: 0.25rem;
  }
  .noTopPadding {
    padding-top: 0;
  }
  </style>