<template>
    <template v-if="visible">
      <div class="_resume_section"
        :class="{'_more_margin': template === 'NewYork' && pageLayout !== 'Center'}"
        :data-section-id="sectionKey">
        <div class="_resume_title" :class="{
          '_less_margin': template === 'SanFrancisco' || template === 'NewYork' ,
        }">
          <label>{{ section.name }}</label>
          <!-- Section Comments & Recommendations -->
          <feedback
            v-if="showCommentIndicators"
            :comments="sectionLevelComments"
            :recommendations="sectionLevelRecommendations"
            :on-margin="false"
          />
        </div>
        <hr v-if="template !== 'Berlin' && (template !== 'NewYork' || pageLayout === 'Center')"/>
        <!-- Iterate through skill groups -->
        <template v-if="pageLayout ==='Center'">
          <!-- Center Layout -->
          <ul class="_custom_list">
            <template v-for="(skillGroup, groupIndex) in section.skill_groups" 
              :key="groupIndex" >
              <li v-if="hasVisibleSkillGroup(skillGroup.title)"> 
                <div class="_card_row" :item-id="groupIndex">
                    <span class="_section_title"> {{ skillGroup.title + ': '}}</span>
                    <label>{{ getSkills(skillGroup) }}</label>
                </div>
              </li>
            </template>
          </ul> 
        </template>
        <template v-else>
          <!-- Left & Right Layout -->
          <div class="_resume_grid">
            <template v-for="(skillGroup, groupIndex) in section.skill_groups" 
              :key="groupIndex" >
              <template v-if="hasVisibleSkillGroup(skillGroup.title)">
                <div class="_resume_card _sidebar_card" :item-id="groupIndex">
                    <div class="_section_title"> {{ skillGroup.title}}</div>
                </div>
                <template v-for="(skill, skillIndex) in skillGroup.items_array">
                    <div class="_resume_card _sidebar_card" v-if="skill !== ''" :item-id="groupIndex + '-' + skillIndex">
                      <label>{{ skill }}</label>
                    </div>
                </template>
              </template>
            </template>
          </div>
        </template>
      </div>
      <hr v-if="template === 'Berlin' && hasNextSection"/>
    </template>
  </template>
  
  <script>
  import Feedback from './Feedback.vue'
  import { labelHelpers } from '../mixins/labelHelpers'
  import { layoutHelpers } from '../mixins/layoutHelpers'
  
  export default {
    name: 'ResumeSkills',
    components: {
      Feedback
    },
    mixins: [labelHelpers, layoutHelpers],
    props: {
      template: {
        type: String,
        required: true
      },
      section: {
        type: Object,
        required: true,
        validator: (obj) => {
          const requiredProps = ['name', 'skill_groups']
          return requiredProps.every(prop => prop in obj)
        }
      },
      visible: {
        type: Boolean,
        default: false
      },
      pageLayout: {
        type: String,
        default: 'Center',
        validator: value => ['Left', 'Right', 'Center'].includes(value)
      },
      showCommentIndicators: {
        type: Boolean,
        default: false
      },
      hasComments: {
        type: Boolean,
        default: false
      },
      comments: { //array of comments
        type: Array,
        required: true
      },
      hasRecommendations: {
        type: Boolean,
        required: true
      },
      recommendations: { //array of recommendations
        type: Array,
        required: true
      },
      hasVisibleSkillGroup: {
        type: Function,
        required: true,
      },
      getSkills: {
        type: Function,
        required: true,
      },
      hasNextSection: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        sectionKey: 'professional_skills',
      }
    }
  }
  </script>