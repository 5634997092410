import { createApp } from 'vue';
import axios from 'axios';
import App from './App.vue';
import router from './controllers';
import store from './store';
// Impoort Quasar UI Control Components
// import { Quasar, QBtn, QBtnDropdown, QMenu, QList, QItem, QItemSection, QItemLabel, ClosePopup} from 'quasar'
// import quasarLang from 'quasar/lang/en-US'
// import '@quasar/extras/material-icons/material-icons.css'
// import 'quasar/dist/quasar.css';
// Import the project styles here
import './main.css'; 


axios.defaults.xsrfCookieName = 'csrf-token';
axios.defaults.xsrfHeaderName = 'X-csrf-token';

const app = createApp(App);
app.use(router).use(store)
// app.use(Quasar, {
//     plugins: {}, // import Quasar plugins and add here
//     lang: quasarLang,
//     components: { QBtn, QBtnDropdown, QMenu, QList, QItem, QItemSection, QItemLabel },
//     directives: { ClosePopup },
// })
app.mount('#app');
