<template>
    <div 
      v-if="showAnyIndicators"
      :class="[
        containerBaseClass,
        positionClass
      ]"
    >
      <!-- Loop through Regular Comments -->
      <template v-for="comment in comments" :key="comment.label">
        <div 
          class="CommentIcon"
          :data-comment-id="comment.id"
        >
          <icon icon-name="chat-full"></icon>
          <div class="CommentIndex ContrastColor">{{ comment.label }}</div>
        </div>
      </template>
      <!-- Loop through Recommendations -->
      <template v-for="(recommendation) in recommendations" :key="recommendation.label">
        <div 
          class="CommentIcon RecommendationsColor"
          :data-comment-id="'reco_' + recommendation.id"
        >
          <icon icon-name="chat-full"></icon>
          <div class="CommentIndex ContrastColor">{{ recommendation.label }}</div>
        </div>
      </template>
    </div>
</template>

<script>
import Icon from '../../ui_controls/Icon.vue'

export default {
  name: 'Feedback',
  components: {
    Icon
  },
  props: {
    comments: { //list of comments to show inside the container
      type: Array,
      default: () => []
    },
    recommendations: { //List of recommendations to show inside the container
      type: Array,
      default: () => []
    },
    onMargin: { //whether this is a margin comment
      type: Boolean,
      default: false
    },
    position: { // for margin comments only; whether on left or right margin
      type: String,
      validator: value => ['left', 'right', 'top'].includes(value)
    }
  },
  computed: {
    containerBaseClass() {
      return this.onMargin ? 'CommentIconContainerMargin' : 'CommentIconContainerInline'
    },
    positionClass() {
      if (this.position === 'top') return 'PositionTop';
      else if (this.position === 'right') return 'PositionRight';
      else if (this.position === 'left') return 'PositionLeft';
      return ''; //default
    },
    showAnyIndicators() {
      return this.comments.length > 0 || this.recommendations.length > 0
    }
  },
}
</script>