export const urlHelpers = {
    //Methods to handle different URLs in the resume template
    methods: {
        getURLString(url_string) {
            if (url_string && !url_string.startsWith('http')) {
                return 'http://' + url_string;
            }
            return url_string;
        },

        getLinkedInURL(linkedin_id) {
            const linkedInRegex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\//i;
            if (linkedin_id) {
                if (!linkedInRegex.test(linkedin_id)) {
                    return 'https://www.linkedin.com/in/' + linkedin_id;
                } else if (!linkedin_id.startsWith('http://') && !linkedin_id.startsWith('https://')) {
                    return 'https://' + linkedin_id;
                }
            }
            return linkedin_id;
        },

        getLinkedInId(linkedin_url) {
            const linkedInRegex = /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/([^\/]+)\/?/i;
            if (linkedin_url) {
                let match = linkedin_url.match(linkedInRegex);
                if (match) {
                    return `linkedin.com/in/${match[1]}`;
                }
                const linkedin_alt_url = this.getLinkedInURL(linkedin_url);
                match = linkedin_alt_url.match(linkedInRegex);
                if (match) {
                    return `linkedin.com/in/${match[1]}`;
                }
            }
            return linkedin_url;
        },

        getGitHubURL(github_id) {
            const gitHubRegex = /^(https?:\/\/)?(www\.)?github\.com\//i;
            if (github_id) {
                if (!gitHubRegex.test(github_id)) {
                    return 'https://www.github.com/' + github_id;
                } else if (!github_id.startsWith('http://') && !github_id.startsWith('https://')) {
                    return 'https://' + github_id;
                }
            }
            return github_id;
        },

        getGitHubId(github_url) {
            const gitHubRegex = /^(?:https?:\/\/)?(?:www\.)?github\.com\/([^\/]+)\/?/i;
            if (github_url) {
                let match = github_url.match(gitHubRegex);
                if (match) {
                    return `github.com/${match[1]}`;
                }
                const github_alt_url = this.getGitHubURL(github_url);
                match = github_alt_url.match(gitHubRegex);
                if (match) {
                    return `github.com/${match[1]}`;
                }
            }
            return github_url;
        }
    }
};
