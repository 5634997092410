<template>
    <template v-if="visible">
      <div class="_resume_section" 
        :class="{'_more_margin': template === 'NewYork' && pageLayout !== 'Center'}"
        :data-section-id="sectionKey">
        <div class="_resume_title" :class="{
          '_less_margin': template === 'SanFrancisco' || template === 'NewYork' ,
        }">
          <label>{{ section.name }}</label>
          <!-- Section Comments & Recommendations -->
          <feedback
            v-if="showCommentIndicators"
            :comments="sectionLevelComments"
            :recommendations="sectionLevelRecommendations"
            :on-margin="false"
          />
        </div>
        <hr v-if="template !== 'Berlin' && (template !== 'NewYork' || pageLayout === 'Center')"/>
        <template v-if="pageLayout === 'Center'">
          <!-- Center Layout -->
          <div class="_section_card_more_margin">
            <div class="_grid_2x"> 
                <template v-for="(item, index) in section.items_array">
                    <ul class="_custom_list NoTopMargin NoBottomMargin" :item-id="index" v-if="item.item_name !==''">
                      <li> <label>{{ item.item_name }}</label></li>
                    </ul>
                </template>
            </div>
          </div>
        </template>
        <template v-else>
          <!-- Left & Right Layout -->
          <div class="_resume_grid">
            <template v-for="(item, index) in section.items_array">
              <div class="_resume_card _sidebar_card" v-if="item.item_name !== ''" :item-id="index">
                <label>{{ item.item_name }}</label>
              </div>
            </template>
          </div>
        </template>
      </div>
      <hr v-if="template === 'Berlin' && hasNextSection"/>
    </template>
  </template>
  
  <script>
  import Feedback from './Feedback.vue'
  import { labelHelpers } from '../mixins/labelHelpers'
  import { layoutHelpers } from '../mixins/layoutHelpers'
  
  export default {
    name: 'NonDateSection',
    components: {
      Feedback
    },
    mixins: [labelHelpers, layoutHelpers],
    props: {
      template: {
        type: String,
        required: true
      },
      section: {
        type: Object,
        required: true,
        validator: (obj) => {
          const requiredProps = ['name']
          return requiredProps.every(prop => prop in obj)
        }
      },
      sectionKey: {
        type: String,
        required: true
      },
      visible: {
        type: Boolean,
        default: false
      },
      pageLayout: {
        type: String,
        default: 'Center',
        validator: value => ['Left', 'Right', 'Center'].includes(value)
      },
      showCommentIndicators: {
        type: Boolean,
        default: false
      },
      hasComments: {
        type: Boolean,
        default: false
      },
      comments: { //array of comments
        type: Array,
        required: true
      },
      hasRecommendations: {
        type: Boolean,
        required: true
      },
      recommendations: { //array of recommendations
        type: Array,
        required: true
      },
      hasNextSection: {
        type: Boolean,
        default: false
      }
    },
  }
  </script>