export const layoutHelpers = {
    // Computed properties to handle different layouts, contact info, recommendations etc
    computed: {
        columnOrder() {
            switch (this.pageLayout) {
                case 'Left': return 1;
                case 'Right': return 0;
                default: return 0;
            }
        },
        // Whether the section has contact info
        hasContactInfo() {
            const contact = this.contactInfo;
            if (!contact) return false;

            return Boolean(
                contact.email ||
                contact.phone_number ||
                contact.city ||
                contact.linkedin_id ||
                contact.website ||
                contact.github ||
                contact.other_social_media
            );
        },
        // Section level recommendations array
        sectionLevelRecommendations() {
            const id = this.sectionKey;
            if (!this.hasRecommendations) return [];
            return [
                {
                    id: id,
                    label: this.getRecommendationLabel(id)
                }
            ];
        },
        sectionLevelComments() {
            const id = this.section.id;
            if (!this.hasComments) return [];
            return [
                {
                    id: id,
                    label: this.getCommentIndex(id)
                }
            ];
        }
    }
};