export const labelHelpers = {
    //Methods to get the comment and recommendation labels
    methods: {
        arrayToString(array) {
            if (array && Array.isArray(array) && array.length > 0) return array.join(' ');
            if (typeof array === 'string') return array;
            return '';
        },
        getCommentIndex(id) {
            if (this.comments && this.comments.length > 0) {
                return this.comments.findIndex(comment => comment.id === id) + 1;
            }
            return 'C'; // Default value
        },
        // The label to show on the recommendation indicator
        getRecommendationLabel(section) {
            const id = 'reco_' + section;
            console.log('Gettign recommendation label for recommendation id: ' + id + ' and length: ' + this.recommendations.length);
            if (this.recommendations && this.recommendations.length > 0) {
                const index = this.recommendations.findIndex(comment => comment.id === id);
                // Convert index to corresponding uppercase letter (A=0, B=1, C=2, etc.)
                console.log('Recommendation Index: ' + index);
                return String.fromCharCode(65 + index);
            }
            return 'R'; // Default value
        },
        // Get date text given start and end dates
        getDateText(start_date, end_date) {
            const trimmed_start_date = start_date.trim();
            const trimmed_end_date = end_date.trim();
            if (trimmed_start_date && trimmed_end_date) {
                return `${trimmed_start_date} - ${trimmed_end_date}`;
            } else if (trimmed_end_date) {
                return trimmed_end_date;
            }

        }
    }
};