<template>
    <template v-if="visible">
      <div class="_resume_section" :data-section-id="sectionKey">
        <div class="_resume_title" :class="{
          '_less_margin': template === 'SanFrancisco' || template === 'NewYork' ,
        }">
          <label>{{ sectionName }}</label>
          <!-- Experience Section Recommendations -->
          <feedback
            v-if="showCommentIndicators"
            :recommendations="sectionLevelRecommendations"
            :on-margin="false"
          />
        </div>
        <hr v-if="template !== 'Berlin'"/>
        <div class="_center_column">
          <template v-for="(experience, index) in section">
              <div class="_card_row" :experience-id="index" v-if="hasVisibleOrganization(index)">
                  <div class="_company_header">
                      <div class="_section_subtitle">{{ experience.company_name }} </div>
                      <div class="_resume_date" v-if="experience.company_description">{{ experience.company_description }}</div>
                  </div>
                      <template v-for="(position, positionIndex) in experience.positions">
                      <div class="_header_with_date" v-if="!position.hide || !userEditedResume.hide_experiences">
                          <!-- Position Comment -->
                          <feedback
                            v-if="showCommentIndicators && sectionHasComments(sectionKey,index,positionIndex)"
                            :comments="positionComments(index,positionIndex)"
                            :on-margin="true"
                            :position="pageLayout=='Left'? 'right' : 'left'"
                          />
                          <div class="_section_title">{{ position.job_title }}</div>
                          <div class="_resume_date"  v-if="getDateText(position.job_start_date, position.job_end_date)">
                            {{ getDateText(position.job_start_date, position.job_end_date)}}
                          </div>
                      </div>
                      <ul class="_custom_list" v-if="!position.hide || !hideExperiences">
                          <template v-for="(responsibility) in position.job_responsibilities" >
                              <li v-if="responsibility.length > 0"><div> {{ responsibility }} </div></li>                                  
                          </template>
                      </ul>
                  </template>
              </div>
          </template>                                     
        </div> 
      </div>
      <hr v-if="template === 'Berlin' && hasNextSection"/>
    </template>
  </template>
  
  <script>
  import Feedback from './Feedback.vue'
  import { labelHelpers } from '../mixins/labelHelpers'
  import { layoutHelpers } from '../mixins/layoutHelpers'
  
  export default {
    name: 'ResumeExperiences',
    components: {
      Feedback
    },
    mixins: [labelHelpers, layoutHelpers],
    props: {
      template: {
        type: String,
        required: true
      },
      section: {
        type: Array,
        required: true,
      },
      visible: {
        type: Boolean,
        default: false
      },
      pageLayout: {
        type: String,
        default: 'Center',
        validator: value => ['Left', 'Right', 'Center'].includes(value)
      },
      showCommentIndicators: {
        type: Boolean,
        default: false
      },
      comments: { //array of comments
        type: Array,
        required: true
      },
      hideExperiences: {
        type: Boolean,
        required: true
      },
      hasRecommendations: {
        type: Boolean,
        required: true
      },
      recommendations: { //array of recommendations
        type: Array,
        required: true
      },
      hasVisibleOrganization: {
        type: Function,
        required: true
      },
      sectionHasComments: {
        type: Function,
        required: true,
      },
      hasNextSection: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        sectionKey: 'experiences',
        sectionName: 'Experience',
      }
    },
    computed: {
      positionComments() {
        return (experienceIndex, positionIndex) => {
          if (!this.sectionHasComments(this.sectionKey,experienceIndex,positionIndex)) return [];
          const id = this.section[experienceIndex].positions[positionIndex].id;
          return [ 
            { 
              id: id,
              label: this.getCommentIndex(id) 
            }
          ];
        }
      },
    }
  }
  </script>