<template>
  <div class="Scaffold CoverLetterWidth">
    <div class="Background">
      <div class="ContentContainer">
        <!-- Editor Panel -->
        <div class="EditorPanel" v-if="userCoverLetterContent">
          <div class="EditorScaffold">
            <!-- Stepper Menu -->
            <div class="StickyContainer BorderBottom">
              <div class="TopMenuScaffold JustifyCenter">
                <stepper-button componentId="input" :isComplete="true" :hasRightSibling="true" :isSelected="false" :isDisabled="false" 
                @stepperClick="handleButtonClick" :text="LABEL_HOME"/>
                <stepper-button componentId="resume" :isComplete="true" :hasRightSibling="true" :isSelected="false" :isDisabled="false" 
                @stepperClick="handleButtonClick" :text="LABEL_RESUME"/>
                <stepper-button componentId="cover_letter" :isComplete="userCoverLetterContent !== null && userCoverLetterContent !==''" :hasRightSibling="false" :isSelected="true" :isDisabled="false" :text="LABEL_COVER_LETTER"/>
              </div>
            </div>
            <div class="PanelContainer">
              <div class="AltCard">
                <div class="RowAlignedLeft">
                  <div class="TextParagraph">Please review your customized cover letter and update it as necessary.</div>
                </div>
                <div class="RowAlignedLeft">
                    <textarea class="ThemedInput" id="cover_letter_editor" v-model="userEditedContent" rows="40"></textarea>
                </div>
              </div>
            </div>
            <!--CTA for Regenerating Cover Letter-->
            <!-- <div class="StickyContainer BorderTop BorderRight">
              <div class="RowAlignedCenterWithMargin">
                <themed-button componentId="generate" button-type="primary" label ="Rephrase Cover Letter" width="full"
                @buttonClick="handleButtonClick"/>
              </div>
            </div> -->
            <!-- end editor panel -->
          </div>
        </div>
          <!-- <div class="VerticalSeparator"></div> -->
          <div class="PreviewPanel" v-if="userCoverLetterContent">
            <div class="TopMenuContainer BorderBottom">
              <!-- Top Menu Row -->
              <div class="TopMenuRow">
                <!-- Done Button -->
                <div class="PageHeaderTextButton">
                  <themed-button componentId="complete" button-type="secondary" width="fit"
                  :isDisabled=api_call_in_progress 
                  :label = TOOLTIP_COMPLETE
                  @buttonClick="handleButtonClick"/>
                </div>
                <!-- Download Button -->
                <div class="PageHeaderTextButton">
                  <themed-button componentId="download" button-type="primary"
                      :label=TOOLTIP_DOWNLOAD :is-disabled=api_call_in_progress width="fit"
                    @buttonClick="handleButtonClick"/>
                </div>
              </div>
              <!-- Sub Menu Row -->
              <div class="SubMenuRow">
                <!-- Left Aligned Buttons -->
                <div class="IconContainerMini">
                  <themed-button componentId="generate" button-type="icon" icon="ai"
                  :isDisabled="api_call_in_progress" 
                  :tooltip-text = TOOLTIP_GENERATE align="left" color="var(--gray-900)"
                  @buttonClick="handleButtonClick"/>
                </div>
                <div class="IconContainerMini">
                  <themed-button componentId="copy" button-type="icon" icon="copy"
                  :is-disabled=api_call_in_progress
                  :tooltip-text = TOOLTIP_COPY align="left" color="var(--gray-900)"
                  @buttonClick="handleButtonClick"/>
                </div>
                <!-- Separator -->
                <div class="VerticalSeparator"></div>
                <!-- Right Aligned Buttons -->
                <div class="SubMenuRightButtonPanel">
                  <!-- Separator -->
                  <div class="VerticalSeparator"></div>
                  <!-- Resume Button -->
                  <div class="IconContainerMini">
                    <themed-button componentId="resume" button-type="icon" icon="previous"
                    :isDisabled="api_call_in_progress" color="var(--gray-900)"
                    :tooltip-text = "TOOLTIP_RESUME" align="right"
                    @buttonClick="handleButtonClick"/>
                  </div>
                  <!-- Save Button -->
                  <div class="IconContainerMini">
                    <themed-button componentId="save" button-type="icon" icon="save"
                    :is-disabled=api_call_in_progress color="var(--gray-900)"
                    :tooltip-text = TOOLTIP_SAVE align="right"
                    @buttonClick="handleButtonClick"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="CoverLetterPanelContainer">
              <div class="PreviewScaffold">
                <template v-if="userEditedContent">
                  <div class="CoverLetterPreview">
                    <!-- <pre class="TextPreview">{{ userEditedContent }}</pre> -->
                    <Markdown :source="userEditedContent" :options="{ breaks: true, html: true }" />
                  </div>
                </template>
              </div>
            </div>
            <!-- end preview panel -->
          </div>
          <!-- If there's no content to display -->
          <div class="ErrorStateContainer"  :class="userCoverLetterContent?'Hide':''">
            <div class="ErrorState">
              <div class="TextHeading2">{{ api_call_in_progress? LOADING_STATE_LINE1: ERROR_STATE_LINE1 }}</div>
              <Icon iconName="sorry"/>
              <div class="TextParagraph">{{ api_call_in_progress? LOADING_STATE_LINE2: ERROR_STATE_LINE2 }}.</div>
              <div class="HorizontalGroup JustifyCenter" v-if="!api_call_in_progress">
                <themed-button componentId="home" button-type="secondary"
                :label = BUTTON_LABEL_HOME width="fit"
                @buttonClick="handleButtonClick"/>
                <themed-button componentId="generate" button-type="secondary"
                  :label = BUTTON_LABEL_RETRY width="fit"
                  @buttonClick="handleButtonClick"/>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import Markdown from 'vue3-markdown-it';
import StepperButton from './ui_controls/StepperButton.vue'; 
import ThemedButton from './ui_controls/ThemedButton.vue'; 
import ToastMessage from './ui_controls/ToastMessage.vue';
import Icon from './ui_controls/Icon.vue'; 
import eventBus from '../utils/event-bus';
import { ToastEvents } from '../utils/toast-events';

export default {
  components: {
    StepperButton,
    ThemedButton,
    ToastMessage,
    Icon,
    Markdown,
  },
  data() {
    return {
      userEditedContent: '', // Local data property to store user-edited content
      selectedVersion: 1, // the cover letter version selected
      // API Call related
      cancel_token_source: null, //Token to handle cancellations for cover letter requests
      api_call_in_progress: false, //whenever an API call is being made
      progress_toast_id: '', // id of the cover letter gen toast message
      // Content
      MESSAGE_COVER_LETTER_IN_PROGRESS: 'Generating cover letter.\nThis may take a few seconds ...',
      MESSAGE_COVER_LETTER_SUCCESS: 'Please review your tailored cover letter.\nYou can update the contents using the editor on the left.',
      MESSAGE_COVER_LETTER_FAILURE: 'Sorry! We could not generate your tailored cover letter at this time. We recommend that you retry in a bit.',
      MESSAGE_COPIED: 'Cover letter copied to clipboard.',
      MESSAGE_SAVED: 'Cover letter successfully saved.',
      LOADING_STATE_LINE1: 'Preparing your cover letter...',
      LOADING_STATE_LINE2: 'This can take a few seconds...',
      ERROR_STATE_LINE1: 'Oops! Something went wrong...',
      ERROR_STATE_LINE2: 'Let\'s start over...',
      BUTTON_LABEL_HOME: 'Home',
      BUTTON_LABEL_RETRY: 'Retry',
      LABEL_HOME: 'Input',
      LABEL_RESUME: 'Resume',
      LABEL_COVER_LETTER: 'Cover Letter',
      TOOLTIP_RESUME: 'View Resume',
      TOOLTIP_SAVE: 'Save',
      TOOLTIP_DOWNLOAD: 'Download',
      TOOLTIP_COMPLETE: 'New Application',
      TOOLTIP_COPY: 'Copy Cover Letter',
      TOOLTIP_GENERATE: 'Rephrase Cover Letter',
    };
  },

  computed: {
    applicationId() {
      return this.$route.query.application_id;
    },
    ...mapGetters(['getApplicationJSONByVersion']),
    applicationJSONVue: function () {
      return this.getApplicationJSONByVersion(this.selectedVersion);
    },
    ...mapGetters(['isApplicationVersionAbsent']),
    ...mapGetters(['previousRoute']), //get the previous page name
    applicationIdAbsent: function () {
      // If selected version is present in the Vue store return true; else return false
      return this.isApplicationVersionAbsent(this.selectedVersion);
    },
    userCoverLetterContent() {
      // Access the cover letter content from VueX store based on application_id
      const cover_letter= this.applicationJSONVue?.cover_letter || null;
      return cover_letter;
    },
  },
  async created() {
    console.log('CoverLetter.vue navigated from page: ' + this.previousRoute);
    if (this.applicationId === null || this.applicationId === undefined || this.applicationId==='') {
      //If application_id param isn't present in the URL 
      this.navigateHome() // Return the user to Home screen
    } else {
      // i.e. if application_id param is present in the URL
      if (!this.userCoverLetterContent) {
        //If cover letter isn't present in Vue store
        console.log('Cover letter not available in Vue store. Fetching application from server...');
        // Get the application details from the server
        const isApplicationJSONPresent = await this.getApplicationFromServer();
        if (!isApplicationJSONPresent) {
          // If application isn't present in the server either
          console.log('Application '+this.applicationId + ' not present is server. Redirecting to home..');
          setTimeout(() => {
            this.navigateHome(); // Return the user to Home screen 
          }, 3000); // After a 3 second delay
        } else if (!this.userCoverLetterContent) {
          //If application is present in server but cover letter isn't
          console.log('Application JSOn present in Server but cover letter not found. Generating cover letter ..');
          this.generateCoverLetter();
        }
      } else { //If cover letter is present in Vue store
          // Set the initial value of userEditedContent to the cover letterfrom Vue store
          console.log('Getting application details from Vue store');
          this.userEditedContent = this.userCoverLetterContent;
      }
    }
  },

  watch: {
      // Watch for changes to userEditedContent and update userCoverLetterContent in Vuex store
      userEditedContent(newValue) {
      if (newValue != null) {
        console.log('User edited content was updated to:\n'+JSON.stringify(newValue));
        this.updateUserCoverLetterContent(this.selectedVersion, newValue);
      }
    }
  },

  methods: {
    handleButtonClick (id){
      console.log('Clicked button ID: '+id);
      if (id==='generate') this.generateCoverLetter();
      else if (id==='complete') this.completeApplication();
      else if (id==='home') this.navigateHome();
      else if (id==='input') this.saveAndNavigate('Home');
      else if (id==='resume') this.saveAndNavigate('Resume');
      else if (id==='copy') this.copyToClipboard();
      else if (id==='download') this.download();
      else if (id==='save') this.saveApplicationToServer();
    },
    // Update the userCoverLetterContent in the Vuex store when the user makes changes
    updateUserCoverLetterContent(version, cover_letter) {
      // this.applicationJSONVue.cover_letter=this.userEditedContent,
      this.$store.commit('updateCoverLetter', {
        version: version,
        cover_letter: cover_letter,
      });
    },
    //Complete the application wofklow by saving to server and cleaning out Vue store
    async completeApplication() {
      //Save to server and navigate home
      await this.saveApplicationToServer();
      //Delete the application JSON from Vue store
      this.$store.commit('deleteApplicationJSON');
      //Redirect to home without any query param
      this.navigateHome();
    },
    //Get the job application details from server
    async getApplicationFromServer() {
      console.log('Getting Application details from server for application: ' + this.applicationId);
      this.api_call_in_progress=true; //set the api call status tracker
      let api_status = false; //whether a successful API response was obtained
      try {
          const response = await axios.get(`/api/v1/user_applications/${this.applicationId}`);
          console.log('Status for getting job application details from server: ' + response.status);
          // set userEditedContent to the cover letter from the server
          this.userEditedContent = response.data.cover_letter;
          // save the application JSON to the Vue store
          this.$store.commit('setApplicationJSON', { version: this.selectedVersion, response: response.data });
          api_status = true;
      } catch (error) {
          console.error('Error fetching job application:', error);
      }
      this.api_call_in_progress=false;
      return api_status;
    },
    //Update the job application details in server
    async saveApplicationToServer() {
      console.log('Saving application to server');
      const now = new Date();
      try {
        const response = await axios.patch(`/api/v1/user_applications/${this.applicationId}`, {
          user_application: {
            cover_letter: this.applicationJSONVue?.cover_letter, //save the cover letter
            applied_at: now.toISOString(),   //update the application time
          },
        },);
        console.log('Job Application Update Status: ' + response.status);  
        this.showToast('Success',this.MESSAGE_SAVED); //show a success message
      } catch (error) {
        // In case there's an error making any of the API calls
        console.error('Error creating a job application:', error);
        // Do not navigate users to the job match screen
      }
    },
    // Return to home screen without saving
    navigateHome(){
      this.$store.commit('setPreviousRoute', this.$route.name); //save the previous route name in Vue store
      this.$router.push({ name: 'Home' });
    },
    //Save the application to server and navigate to the destination screen
    async saveAndNavigate(destination) {
      await this.saveApplicationToServer();
      this.$store.commit('setPreviousRoute', this.$route.name); //save the previous route name in Vue store
      if (destination ==='Home') {
        this.$router.push({ name: destination, query: { application_id: this.applicationId }});
      } else {
        this.$router.push({ name: destination, query: { application_id: this.applicationId }});
      }
    },
     //Generate a cover letter for the given application id
    async generateCoverLetterOld() {
      console.log('Inside Generate Cover Letter')
      this.api_call_in_progress = true; //set the api call status tracker
      this.progress_toast_id = this.showToast('Wait', this.MESSAGE_COVER_LETTER_IN_PROGRESS);
      console.log('Progress toast id: '+this.progress_toast_id);
      let api_status = false; //status of the API call
      // Cancel the previous request if it exists
      if (this.cancel_token_source) {
        this.cancel_token_source.cancel('Request canceled by the user');
      }
      // Create a new CancelToken source for the current request
      this.cancel_token_source = axios.CancelToken.source();
      try {
        // Make an API call to generate the cover letter
        const response = await axios.get(`/api/v1/user_applications/${this.applicationId}/generate_cover_letter`,
        { cancelToken: this.cancel_token_source.token,} );
        // Check if the response contains the cover letter
        if (response.data && response.data.cover_letter) {
          api_status=true;
          this.userEditedContent = response.data.cover_letter;
          console.log('Cover Letter Generated:\n'+ response.data.cover_letter);
          // Save cover letter to Vue store
          this.$store.commit('setApplicationJSON', {
            version: this.selectedVersion,
            response: {
              ...this.applicationJSONVue,
              cover_letter: response.data.cover_letter,
            },
          });
        } else {
            console.log('Failed to generate a cover letter');
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          // Request was canceled by user
          console.log(error.message);
        } else {
          console.error('Error generating cover letter:', error);
        }
      }
      // Hide the progress toast
      console.log('Removing toast '+this.progress_toast_id);
      this.removeToast(this.progress_toast_id);
      this.api_call_in_progress = false; //reset the api call tracker
      if (api_status) {
        //If successfully retrieved a cover letter
        this.showToast('Success', this.MESSAGE_COVER_LETTER_SUCCESS);
      } else {
        //Show a retry message
        this.showToast('Error', this.MESSAGE_COVER_LETTER_FAILURE);
      }
    },
    //Queue cover letter generation, get the cover letter and show status update messages
    async generateCoverLetter() {
      console.log('Inside Generate Cover Letter')
      this.api_call_in_progress = true; //set the api call status tracker
      this.progress_toast_id = this.showToast('Wait', this.MESSAGE_COVER_LETTER_IN_PROGRESS);
      let api_status = false; //initialize the status of the cover letter generation request
      try {
        // Make an API call to queue the cover letter generation
        const response = await axios.post(`/api/v1/user_applications/${this.applicationId}/generate_cover_letter`);
        if (response.status === 202) { //if the application was successfully queued
          const cover_letter_job_id = response.data.job_id// get the job id
          api_status = await this.coverLetterGenerated(cover_letter_job_id);
        } else {
            console.log('Failed to queue cover letter job');
        }
      } catch (error) {
          console.error('Error queuing cover letter generation', error);
      }
      // Hide the progress toast);
      this.removeToast(this.progress_toast_id);
      this.api_call_in_progress = false; //reset the api call tracker
      if (api_status) {
        //If successfully retrieved a cover letter
        this.showToast('Success', this.MESSAGE_COVER_LETTER_SUCCESS);
      } else {
        //Show a retry message
        this.showToast('Error', this.MESSAGE_COVER_LETTER_FAILURE);
      }
    },
    // Get the cover letter once available and save it to the Vue store
    // Return true if job is successful, false otherwise
    async coverLetterGenerated(job_id) {
      console.log( 'Inside Poll and Get Cover Letter for job id: '+job_id);
      const poll_interval = 10000; //poll every 10 seconds
      const max_poll_attempts = 5; //poll a max of 5 times
      const generated_cover_letter = await this.jobSuccessful(job_id, max_poll_attempts, poll_interval);

      if(generated_cover_letter) {
        console.log('Cover letter returned by server..');
        this.userEditedContent = generated_cover_letter;
        // save cover letter to Vue store
        this.$store.commit('updateCoverLetter', {
          version: 1, 
          cover_letter: this.userEditedContent,
        });
        return true;
      } else {
        console.warn('Server failed to generate cover letter');
        return false;
      }
    },
    // Poll the backend to check the status of a job
    // Return the actual result if job has completed successfully, null otherwise
    async jobSuccessful(job_id, max_poll_attempts, poll_interval) {
      if (!job_id || this.cancel_application) return null; // return null if an empty job id was passed

      let poll_attempts = 0;
      return new Promise((resolve, reject) => {
        const pollStatus = async () => {
          try {

            const statusResponse = await axios.get(`/api/v1/user_applications/${this.applicationId}/job_status?job_id=${job_id}`);
            const jobStatus = statusResponse.data.status;

            if (jobStatus === 'completed') {
              // Job succeeded
              console.log('Job id: ' + job_id + ' successfully completed');
              console.log(statusResponse.data.result);
              resolve(statusResponse.data.result);
            } else if (jobStatus === 'working' || jobStatus === 'queued' || !jobStatus) {
              // Job still in progress, continue polling
              console.log('Job id: ' + job_id + ' is still in progress..will poll again in a few...');
              poll_attempts++;

              if (poll_attempts < max_poll_attempts) {
                setTimeout(pollStatus, poll_interval);
              } else {
                console.log('Job id: ' + job_id + ' Hit max poll attempts. Quitting ...');
                resolve(null);
              }
            } else {
              // Job failed
              console.log('Job id: ' + job_id + ' failed with status: ' + jobStatus);
              resolve(null);
            }
          } catch (error) {
            console.log('Job id: ' + job_id + ' failed with error: ' + error);
            reject(error);
          }
        };
        // Start polling
        pollStatus();
      });
    },
    //Cancel the cover letter request/ API call
    cancelRequest() {
      if (this.cancel_token_source) {
        this.cancel_token_source.cancel('Request canceled by the user');
        this.cancel_token_source = null; // Reset the cancel token source
      }
    },
    //Copy cover letter text to clipboard
    copyToClipboard() {
      const textarea = document.getElementById('cover_letter_editor'); // Get the textarea element by its ID
      try {
        // Use the Clipboard API to copy the textarea content to the clipboard
        navigator.clipboard.writeText(textarea.value);
        this.showToast('Success',this.MESSAGE_COPIED);
      } catch (error) {
        console.log('Failed to copy cover letter to clipboard: ', error); // Handle any errors that may occur
      }
    },
    // Download the cover letter as a .pdf file
    async download() {
      // Get the cover letter preview div to get the HTML content
      const previewDiv = document.querySelector('.CoverLetterPreview');

      if (!previewDiv) {
        console.error('Cover letter preview element not found');
        return;
      }
      await this.saveApplicationToServer(); // Save the latest content to server
      const clonedElement = previewDiv.cloneNode(true);
      // Adjust styles for list items
      const style = document.createElement('style');
      style.textContent = `
        ul, ol {
          padding-left: 0.5em;
          margin-left: 0;
        }
        li {
          position: relative;
          padding-left: 0.5em;
          text-indent: -0.5em;
          margin-bottom: 0.5em;
        }
      `;
      clonedElement.appendChild(style);
      const doc = new jsPDF({
        unit: 'mm',
        format: 'letter',
        orientation: 'portrait',
        // font: 'helvetica'
      });
      await doc.html(clonedElement, {
        callback: (doc) => {
          const name = this.applicationJSONVue.resume.name.replace(/\s+/g, '_');
          doc.save(`${name}_cover_letter.pdf`);
        },
        x: 10,
        y: 10,
        width: doc.internal.pageSize.getWidth() - 20, // 10mm margin on each side
        windowWidth: 816 // Letter width in pixels at 96 DPI
      });
    },
    // Show a toast message (code copied from InputForm.vue)
    showToast(toastType, toastMessage) {
      const toastId = Date.now(); // Use a unique identifier for each toast
      const newToast = {
        id: toastId,
        type: toastType,
        message: toastMessage,
        visible: true,
      };
      eventBus.emit(ToastEvents.ADDED, newToast); //publish an add toast event to the event bus so that App.vue is notified
      return toastId;
    },
    // Remove a toast message with a given id (code copied from InputForm.vue)
    removeToast(toastId) {
      if (toastId && toastId !='') {
        eventBus.emit(ToastEvents.REMOVED, toastId); //publish a remove toast event to the event bus so that App.vue is notified
      }
    },
  },
};
</script>