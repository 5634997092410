<template>
  <div class="ToastOuterContainer"  :class="{ 'hidden': !displayToast, 'visible': displayToast}">
    <div class="ToastDismissButtonContainer" @click="dismissToast">
      <Icon iconName="remove"/>
    </div>
    <div :class="['ToastContainer', messageTypeClass ]" @click="dismissToast">
      <div :class="['ToastIconContainer'], messageTypeClass" >
        <div class="IconWrapper">
          <Icon :iconName="iconType"/>
        </div>
      </div>
      <div class="ToastMessageContainer">
        <div class="ToastMessageRow" v-for="message_line in messageLines" :key="message_line">
          <div class="TextDescription ToastTextColor">{{message_line }}</div>
        </div>  
        <div class="ToastFooterRow">
          <div class="TextMini ToastFooterColor"> {{displayTime}} </div>
          <div class="TextMini ToastFooterColor">1 of {{ count }}</div>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue'; 

export default {
  components: {
    Icon,
  },
  props: {
    id: {
      type: Number,
    },
    type: {
        type: String,
        validator: value => ['Success', 'Error', 'Warning', 'Info', 'Wait'].includes(value),
        default: 'Error', //4 types: Success, Error, Warning, Info, Wait
      },
    message: {
      type: String,
    },
    visible: {
      type: Boolean,
      default: true,  //true = display the toast, false = hide the toast
    },
    displayDuration: {
      type: Number,
      default: 5000, //How long in mili seconds the toast message should be displayed if auto dismiss = true
    },
    count: {
      type: Number, //How many toasts are there in the toast array
    },
  },
  data() {
    return {
      displayToast : false,
      displayTime: 'now', //time to display in the toast notification
      intervalId: 0,
    };
  },
  mounted() {
    this.intervalId = setInterval(this.getDisplayTime, 60000); // Update the time difference every minute
    setTimeout(() => {  //Show a slide in from left to right animation
      this.displayToast = this.visible;
    }, 300); 
    setTimeout(() => { 
      if (this.autoDismiss) {
        console.log('Toast id= '+this.id+' will be auto dismissed');
        this.dismissToast();     // Automatically dismiss the toast after display duration if auto dismiss = true
      }
    }, this.displayDuration);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  },
  methods: {
    dismissToast() {
      console.log('Dismiss Toast event fired for toast id= '+this.id);
      this.displayToast = false;
      this.$emit('dismissToast', this.id);
    },
    //Returns how long ago the notification was displayed
    getDisplayTime() {
      let timeUnit=0;
      const timeElapsed=Date.now() - this.id;
      // console.log('Calling display time: elapsed time ='+timeElapsed);
      if (timeElapsed < 60 * 1000) { //Less than a minute
        this.displayTime= 'now';
      } else if (timeElapsed < 60 * 60 * 1000) { //Less than an hour
        timeUnit = Math.floor(timeElapsed / (1000 * 60));
        this.displayTime = (timeUnit + 'm');
      } else if (timeElapsed < 24 * 60 * 60 * 1000) { //Less than a day
        timeUnit = Math.floor(timeElapsed / (1000 * 60 * 60));
        this.displayTime= (timeUnit + 'h');
      } else if (timeElapsed < 365 * 24 * 60 * 60 * 1000) { //Less than a year
        timeUnit = Math.floor(timeElapsed / (1000 * 24* 60 * 60));
        this.displayTime = (timeUnit + 'd');
      } else {
        this.displayTime= '1y+';
      }
    },
  },
  watch: {
    visible(newValue) { //Monitor changes to the 'visible' property
      this.displayToast = newValue;
    },
  },
  computed: {
    messageTypeClass() {
      switch (this.type) {
        case 'Success':
          return 'success';
        case 'Warning':
          return 'warning';
        case 'Error':
          return 'error';
        default:
          return 'info';
      }
    },
    //Return the icon name based on the toast type
    iconType() {
      switch (this.type) {
        case 'Success':
          return 'circle-check';
        case 'Warning':
          return 'warning';
        case 'Error':
          return 'error';
        case 'Wait':
          return 'spinning-animation';
        default:
          return 'info';
      }
    },
    // Split the message string by the newline character (\n) into an array of strings
    messageLines() { 
      if(this.message) {
        return this.message.split('\n');
      }
    },
    // Whether the toast message should be automatially dismissed without requiring additional user input
    // if type = info or success
    autoDismiss(){
      return (this.type ==='Success' || this.type ==='Info' || this.type ==='Warning');
    }
  },
};
</script>

<style scoped>
.ToastOuterContainer {
  position: fixed;
  bottom: 7rem;
  left: 1.75rem;
  height: fit-content;
  z-index: var(--z-index-toast);
  transform: translateX(-26rem);
  transition: transform var(--transition-time) ease;
}
.ToastOuterContainer.hidden {
  transform: translateX(-26rem); /* Slide out to the left: Position from left margin: 1.25 rem, width: 23.5rem => total = 24.75rem*/
}

.ToastOuterContainer.visible {
  transform: translateX(0); /* Slide in from the left */
}

.ToastDismissButtonContainer {
  position: relative;
  left: 22.5rem;
  top: 1rem;
  height: 2rem;
  width: 2rem;
  padding: 0.25rem;
  border: solid var(--border-thickness-normal) var(--gray-10);
  background-color: var(--gray-10);
  border-radius: 9999px;
  color: var(--color-outline-normal);
  opacity: 0; /* Initially hide the dismiss button */
  transition: opacity var(--transition-time) ease; /* Add a smooth transition effect */
  box-shadow: var(--shadow-elevation-two);
}

.ToastOuterContainer:hover .ToastDismissButtonContainer {
  opacity: 1; /* Show the dismiss button on hover */
}
.ToastContainer {
  padding: 1rem 1rem 1rem 0;
  width: 23.5rem;
  color: var(--gray-900);
  background-color: var(--gray-10);
  border-radius: var(--card-border-radius);
  border-left: solid 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 0.5rem;
  box-shadow: var(--shadow-elevation-three);
}

.ToastIconContainer {
  padding: 0.5rem;
  width: 3.5rem;
  display: flex;
  align-items: center;
}
.IconWrapper {
  display: flex;
  align-items: center;
  width: 98%;
  height: 98%;
}

.ToastMessageContainer {
  display: flex;
  flex-direction: column;
  width: 19.5rem;
}

.ToastMessageRow {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
}
.ToastFooterRow {
  display: flex;
  justify-content: space-between;
}

.ToastTextColor {
  color: var(--color-text-paragraph);
}
.ToastFooterColor {
  color: var(--gray-500);
}

.info {
  border-color: var(--color-input-selected);
  color: var(--color-input-selected);
}
.success {
  border-color: var(--success-700);
  color: var(--success-700);
}

.warning {
  border-color: var(--warning-600);
  color: var(--warning-600);
}

.error {
  border-color: var(--danger-700);
  color: var(--danger-700);
}
</style>