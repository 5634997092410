<template>
    <div class="tooltip-wrapper" @mouseenter="showTooltip" @mouseleave="hideTooltip">
      <!-- Default slot for the element that needs a tooltip -->
      <slot></slot>
      <div class="tooltip" 
           :class="{ 
             'hidden': !isVisible,
             'align-left': horizontalAlign === 'left',
             'align-right': horizontalAlign === 'right',
             'position-top': verticalAlign === 'top',
             'position-bottom': verticalAlign === 'bottom'
           }">
        {{ text }}
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Tooltip',
    props: {
      text: {
        type: String,
        required: true
      },
      horizontalAlign: { /* possible values: left, right */
        type: String,
        default: 'right',
        validator: (value) => ['left', 'right'].includes(value)
      },
      verticalAlign: { /* possible values: top, bottom */
        type: String,
        default: 'bottom',
        validator: (value) => ['top', 'bottom'].includes(value)
      }
    },
    data() {
      return {
        isVisible: false
      }
    },
    methods: {
      showTooltip() {
        this.isVisible = true;
      },
      hideTooltip() {
        this.isVisible = false;
      }
    }
  }
  </script>
  
  <style scoped>
  .tooltip-wrapper {
    position: relative;
    width: fit-content;
  }
  
  .tooltip {
    position: absolute;

    background-color: var(--color-input-secondary);
    color: var(--color-background-canvas);
    padding: 0 0.25rem;
    border-radius: var(--button-border-radius);
    font-size: var(--size-x-small);
    white-space: nowrap;
    z-index: var(--z-index-tooltip);
    transition: visibility var(--transition-time) ease-in-out;
  }
  
  /* Vertical positioning */
  .tooltip.position-top {
    bottom: 100%;
    margin-bottom: 0.375rem;
    margin-top: 0;
  }
  
  .tooltip.position-bottom {
    top: 100%;
    margin-top: 0.375rem;
    margin-bottom: 0;
  }
  
  /* Horizontal positioning */
  .tooltip.align-right {
    right: 0;
  }
  
  .tooltip.align-left {
    left: 0;
  }
  
  .tooltip.hidden {
    visibility: hidden;
  }
  </style>