<template>
    <div class="menu-button-container" :class="containerClasses">
      <!-- Button Header -->
      <button class="menu-button" 
        :class="buttonClasses" 
        :aria-disabled="isDisabled"
        :disabled="isDisabled"
        :aria-expanded="isMenuExpanded"
        :aria-haspopup="true"
        :aria-controls="menuId"
        :aria-label="ariaLabel || label"
        role="button"
        @mouseover="handleMouseOver" 
        @mouseout="handleMouseOut" 
        @keydown="handleKeyDown" 
        @click="handleButtonClick"
        @blur="handleBlur" 
        @focus="handleFocus">
        <div class="menu-button-icon" v-if="icon" aria-hidden="true">
          <icon :iconName="icon"/> 
        </div>
        <div class="menu-button-label">{{ label }}</div>
        <div class="dropdown-arrow" aria-hidden="true">
          <icon :iconName="isMenuExpanded ? 'up-arrow-filled' : 'down-arrow-filled'"/>
        </div>
      </button>
      <div class="menu-button-tooltip" 
        role="tooltip" 
        :id="tooltipId"
        :class="tooltipClasses">{{ tooltipText }}</div>
  
      <!-- Dropdown Menu -->
      <transition name="fade">
        <div v-if="isMenuExpanded"
          class="menu-dropdown" 
          :class="dropdownClasses"
          :id="menuId"
          role="menu"
          tabindex="-1"
          @keydown="handleMenuKeyDown"
          ref="menuList">
          <div v-for="(option, index) in options" 
            :key="option.id"
            :id="`${menuId}-item-${index}`"
            class="menu-item" 
            :class="{
              'hover': (hoveredItem === option.id) || (focusedIndex === index && !hoveredItem),
              'focused': focusedIndex === index
            }"
            role="menuitem"
            tabindex="-1"
            :aria-selected="focusedIndex === index"
            @click="handleMenuItemClick(option)"
            @mouseover="handleItemMouseOver(option.id)"
            @mouseout="handleItemMouseOut">
            <div class="menu-item-icon" v-if="option.icon" aria-hidden="true">
              <icon :iconName="option.icon"/> 
            </div>
            <div class="menu-item-label">{{ option.label }}</div>
          </div>
        </div>
      </transition>
    </div>
</template>
  
<script>
  import Icon from './Icon.vue';
  
  export default {
    name: 'menu-button',
    components: {
      Icon,
    },
    props: {
      componentId: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      ariaLabel: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: null,
      },
      options: {
        type: Array,
        required: true,
        validator: (value) => {
          return value.every(item => 
            item.hasOwnProperty('id') && 
            item.hasOwnProperty('label')
          );
        }
      },
      tooltipText: {
        type: String,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      width: {
        type: String,
        default: 'fit', // options: 'full' or 'fit'
      },
    },
    emits: ['buttonClick'],
    data() {
      return {
        isMenuExpanded: false,
        tooltipVisible: false,
        isHoverState: false,
        isFocusState: false,
        isActiveState: false,
        hoveredItem: null,
        handlingClick: false,
        focusedIndex: -1,
        menuId: `menu-${this.componentId}`,
        tooltipId: `tooltip-${this.componentId}`,
      };
    },
    computed: {
      containerClasses() {
        return {
          'full-width': this.width === 'full',
          'fit-width': this.width === 'fit',
        };
      },
      buttonClasses() {
        return {
          'hover': this.isHoverState && !this.isMenuExpanded,
          'active': this.isActiveState || this.isMenuExpanded,
          'focused': this.isFocusState,
          'disabled': this.isDisabled,
          'highlight': this.handlingClick,
          'with-icon': this.icon != null,
        };
      },
      dropdownClasses() {
        return {
          'visible': this.isMenuExpanded && !this.isDisabled,
          'hidden': !this.isMenuExpanded || this.isDisabled,
        };
      },
      tooltipClasses() {
        return {
          'visible': !this.isMenuExpanded && this.tooltipVisible,
          'hidden': this.isMenuExpanded || !this.tooltipVisible,
        };
      },
    },
    methods: {
      toggleMenu() {
        if (!this.isDisabled) {
            this.isMenuExpanded = !this.isMenuExpanded;
            if (!this.isMenuExpanded) {
            this.focusedIndex = -1;
            this.hoveredItem = null;
            }
        }
     },
      handleMouseOver() {
        if (!this.isMenuExpanded) {
          this.isHoverState = true;
          if (this.tooltipText) {
            this.tooltipVisible = true;
          }
        }
      },
      handleMouseOut() {
        this.isHoverState = false;
        this.tooltipVisible = false;
      },
      handleKeyDown(event) {
        if (this.isDisabled) return;
        
        switch (event.key) {
            case 'Enter':
            case ' ':
                event.preventDefault();
                this.toggleMenu();
                break;
            case 'ArrowDown':
                event.preventDefault();
                if (!this.isMenuExpanded) {
                    this.toggleMenu();
                }
                if (this.focusedIndex === -1) {
                    this.focusFirstItem();
                } else {
                    this.focusNextItem();
                }
                break;
            case 'ArrowUp':
                event.preventDefault();
                if (this.isMenuExpanded) {
                    if (this.focusedIndex === -1) {
                    this.focusLastItem();
                    } else {
                    this.focusPreviousItem();
                    }
                }
                break;
            case 'Escape':
                if (this.isMenuExpanded) {
                    event.preventDefault();
                    this.closeMenu();
                }
                break;
        }
    },
      handleMenuKeyDown(event) {
        if (this.isDisabled) return;
  
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            this.focusNextItem();
            break;
          case 'ArrowUp':
            event.preventDefault();
            this.focusPreviousItem();
            break;
          case 'Home':
            event.preventDefault();
            this.focusFirstItem();
            break;
          case 'End':
            event.preventDefault();
            this.focusLastItem();
            break;
          case 'Enter':
          case ' ':
            event.preventDefault();
            if (this.focusedIndex >= 0) {
              this.handleMenuItemClick(this.options[this.focusedIndex]);
            }
            break;
          case 'Escape':
            event.preventDefault();
            this.closeMenu();
            break;
          case 'Tab':
            event.preventDefault();
            this.closeMenu();
            break;
        }
      },
      focusMenuItem(index) {
        this.$nextTick(() => {
          const menuItems = this.$refs.menuList?.querySelectorAll('[role="menuitem"]');
          if (menuItems && menuItems[index]) {
            menuItems[index].focus();
            this.focusedIndex = index;
          }
        });
      },
      focusNextItem() {
        const nextIndex = this.focusedIndex + 1 >= this.options.length ? 0 : this.focusedIndex + 1;
        this.focusMenuItem(nextIndex);
      },
      focusPreviousItem() {
        const prevIndex = this.focusedIndex - 1 < 0 ? this.options.length - 1 : this.focusedIndex - 1;
        this.focusMenuItem(prevIndex);
      },
      focusFirstItem() {
        this.focusMenuItem(0);
      },
      focusLastItem() {
        this.focusMenuItem(this.options.length - 1);
      },
      closeMenu() {
        this.isMenuExpanded = false;
        this.focusedIndex = -1;
        this.hoveredItem = null;
        this.$el.querySelector('button').focus();
      },
      handleItemMouseOver(id) {
        this.hoveredItem = id;
      },
      handleItemMouseOut() {
        this.hoveredItem = null;
      },
      handleFocus() {
        if (!this.isMenuExpanded) {
          this.isFocusState = true;
          if (this.tooltipText) {
            this.tooltipVisible = true;
          }
        }
      },
      handleBlur() {
        this.isFocusState = false;
        this.tooltipVisible = false;
      },
      handleButtonClick() {
        this.handlingClick = true;
        this.isActiveState = true;
        this.toggleMenu();
        
        setTimeout(() => {
          this.isActiveState = false;
          this.handlingClick = false;
        }, 100);
      },
      handleMenuItemClick(option) {
        this.handlingClick = true;
        this.isMenuExpanded = false;
        this.$emit('buttonClick', option.id);
 
        
        setTimeout(() => {
            this.handlingClick = false;
            this.focusedIndex = -1;
            //wait for 100ms before blur
          this.$el.querySelector('button').blur();
        },100); 
      },
      handleClickOutside(event) {
        if (!this.handlingClick && this.isMenuExpanded) {
          const container = this.$el;
          if (!container.contains(event.target)) {
            this.closeMenu();
            this.isActiveState = false;
          }
        }
      },
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
      document.removeEventListener('click', this.handleClickOutside);
    },
  };
</script>

 <style scoped>
  .menu-button-container {
    position: relative;
    display: inline-flex;
    flex-direction: column;
  }
  
  .menu-button-container.full-width {
    width: 100%;
  }
  
  .menu-button-container.fit-width {
    width: fit-content;
  }
  
  .menu-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.25rem 0.85rem;
    color: var(--color-input-selected);
    background-color: var(--color-background-canvas);
    border: solid var(--border-thickness-selected) var(--color-input-selected);
    border-radius: var(--button-border-radius);
    font-weight: var(--weight-normal);
    cursor: pointer;
    outline: none;
    width: 100%;
  }
  
  .menu-button.with-icon {
    padding: 0.25rem 0.5rem;
  }
  
  .menu-button:hover,
  .menu-button.hover {
    color: var(--color-input-normal);
    background-color: var(--color-background-primary);
    border-color: var(--color-input-normal);
  }
  
  .menu-button.active,
  .menu-button.focused {
    color: var(--color-input-normal);
    background-color: var(--color-background-primary);
    border-color: var(--color-input-normal);
    box-shadow: 0 0 0 var(--shadow-thickness) var(--color-outline-hover);
  }
  
  .menu-button.highlight {
    color: var(--color-input-secondary);
    background-color: var(--secondary-50);
    border-color: var(--secondary-50);
  }
  
  .menu-button:disabled,
  .menu-button.disabled {
    color: var(--color-outline-disabled);
    background-color: var(--color-input-disabled);
    border-color: var(--color-input-disabled);
    cursor: not-allowed;
  }
  .menu-button-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .menu-button-label {
    flex: 1;
    text-align: left;
  }

  .dropdown-arrow {
    display: flex;
    align-items: center;
    width: 0.5rem;
    height: 0.5rem;
  }

  .menu-dropdown {
    position: absolute;
    top: calc(100% + 0.25rem);
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: max-content;
    background-color: var(--color-background-canvas);
    border: var(--border-thickness-normal) solid var(--color-card-border);
    border-radius: var(--card-border-radius);
    padding: 0.5rem 0;
    z-index: var(--z-index-tooltip);
    box-shadow: var(--shadow-elevation-one);
  }  
  .menu-item {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: var(--gray-700);
    border-left: 0.25rem solid;
    border-color: transparent;
    outline: none;
  }
  
  .menu-item.hover,
  .menu-item.focused {
    background-color: var(--color-background-primary);
    color: var(--color-input-normal);
  }
  
  .menu-item:focus-visible {
    /* outline: var(--border-thickness-selected) solid var(--color-input-normal);
    outline-offset: -2px; */
    border-color:  var(--color-input-normal);
  }
  

.menu-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  aspect-ratio: 1 / 1;
}

.menu-item-label {
  flex: 1;
  text-align: left;
}

.menu-button-tooltip {
  position: absolute;
  top: calc(100% + 0.375rem);
  left: 0;
  background-color: var(--color-input-secondary);
  color: var(--color-background-canvas);
  padding: 0 0.25rem;
  border-radius: var(--button-border-radius);
  font-size: var(--size-x-small);
  white-space: nowrap;
  z-index: var(--z-index-tooltip);
}

.menu-button-tooltip.visible {
  visibility: visible;
}

.menu-button-tooltip.hidden {
  visibility: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity var(--transition-time);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>