<template>
  <div class="Scaffold" v-if="user_authenticated">
    <div class="Background">
      <div class="ContentContainer">
        <div class="EditorPanel">
          <div class="EditorScaffold">
            <!-- Menu -->
            <div class="StickyContainer BorderBottom">
              <div class="TopMenuScaffold JustifyCenter">
                <stepper-button componentId="home" :isComplete="generatedResumeAvailable" :hasRightSibling="true" :isSelected="true" :isDisabled="false" text="input"/>
                <stepper-button componentId="resume" :isComplete="generatedResumeAvailable" :hasRightSibling="true" :isSelected="false" :isDisabled="!generatedResumeAvailable" 
                text="resume" @stepperClick="handleButtonClick"/>
                <stepper-button componentId="cover_letter" :isComplete="coverLetterAvailable" :hasRightSibling="false" :isSelected="false" :isDisabled="!coverLetterAvailable" 
                text="cover letter" @stepperClick="handleButtonClick"/>
              </div>
            </div>
            <div class="PanelContainer">
              <!--Header-->
              <div class="RegularCard MarginBottom">
                <div v-if="userDetails.existing_user" id="Header">
                  <div class="CardHeaderRow">
                    <div class="TextParagraph">Welcome back {{ userDetails.user_first_name }},</div>
                  </div>
                  <div class="RowAlignedLeft">
                    <div class="TextParagraph">{{ PAGE_INSTRUCTIONS_LINE_1 }}</div>
                  </div>
                </div>
                <div v-else id="Header">
                  <div class="CardHeaderRow">
                    <div class="TextParagraph">Welcome {{ userDetails.user_first_name }},</div>
                  </div>
                  <div class="RowAlignedLeft">
                    <div class="TextParagraph">{{ PAGE_INSTRUCTIONS_LINE_1 }}</div>
                  </div>
                </div>
              </div>
              <!-- Resume spec -->
              <div class="RegularCard MarginTop NoBottomMargin">
                <!-- Card Header -->
                <div class="CardDoubleHeaderRow">
                  <div class="CardHeaderIconContainer" @click="!isPreviousApplication? 'toggleResumeCard':''">
                    <div class="CardDoubleHeaderIcon">
                      <icon icon-name="summary"></icon>
                    </div>
                    <!-- Complete Icon -->
                    <div class="CardStatusIndicator SuccessColor"  v-if="isResumeSpecComplete">
                      <Icon iconName="circle-check-filled"/>
                    </div>
                    <!-- Incomplete Icon -->
                    <div class="CardStatusIndicator WarningColor" v-else-if="!isResumeSpecComplete">
                      <Icon iconName="circle-warning-filled"/>
                    </div>
                  </div>
                  <div class="CardHeaderSection" @click="!isPreviousApplication? 'toggleResumeCard':''">
                    <div class="TextHeading1">{{CARD_HEADER_RESUME}}</div>
                    <div class="TextDescription MarginTop NoWrap">{{ getResumeSpecSummary }}</div>
                  </div>
                  <!-- Reset Button -->
                  <div class="DoubleHeaderCardIconButton">
                  <themed-button  componentId="reset_resume_inputs" button-type="icon" :is-disabled="(selected_file==null && text_resume =='') || inputsDisabled" 
                    icon="reset" :tooltip-text="TOOLTIP_CLEAR" @buttonClick="handleButtonClick"/>
                  </div>
                  <!-- Resume Card Toggle Button -->
                  <div class="DoubleHeaderCardIconButton">
                    <themed-button componentId="toggle_resume_card" button-type="icon" :is-disabled="isPreviousApplication"
                      icon="circle-up-arrow" :rotate="true" :toggle-state="resume_spec_card_expanded"
                      :tooltip-text="resume_spec_card_expanded? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                      @buttonClick="handleButtonClick"/>
                  </div>
                </div>
                <!-- Card Body -->
                <div :class="['RegularCardSection', resumeCardClass ]" ref="resumeCard">
                  <!--Resume Selector--> 
                  <!-- Last Application Resume -->
                  <div class="RowAlignedLeft NoBottomPadding" v-if="last_application.available">
                    <input class="ThemedRadioButton" type="radio" id="option_last_application" value="last_application" v-model="resume_spec_option"
                    :disabled="inputsDisabled">
                    <label class="TextParagraph TextTruncate " for="option_last_application">{{ "Use resume from last application (" + formatTime(last_application.time) + ")"}} </label>
                  </div>
                  <div class="RadioButtonDescriptionRow" v-if="last_application.available">
                    <div class="Pill">{{ lastApplicationString }}</div>
                  </div>
                  <!-- Last Uploaded Resume -->
                  <div class="RowAlignedLeft NoBottomPadding" v-if="last_uploaded_resume.available">
                    <input class="ThemedRadioButton" type="radio" id="option_existing_resume" value="existing_resume" v-model="resume_spec_option"
                    :disabled="inputsDisabled">
                    <label class="TextParagraph TextTruncate " for="option_existing_resume">{{ "Use last uploaded resume (" + formatTime(last_uploaded_resume.timestamp) + ")"}} </label>
                  </div>
                  <div class="RadioButtonDescriptionRow" v-if="last_uploaded_resume.available">
                    <div class="Pill">{{ last_uploaded_resume.file_name }}</div>
                  </div>
                  <!-- Upload file -->
                  <div class="RowAlignedLeft">
                    <input class="ThemedRadioButton" type="radio" id="option_upload" value="upload" v-model="resume_spec_option"
                    :disabled="inputsDisabled">
                    <label class="TextParagraph TextTruncate" for="option_upload">{{ RESUME_FILE_INSTRUCTIONS }}</label>
                  </div>
                  <template v-if="resume_spec_option=='upload'">
                    <!-- File Selector -->
                    <div class="RowAlignedLeft NoVerticalPadding" v-if=" !selected_file">
                      <div class="RadioButtonTextRow" >
                        <input class="ThemedInput" type="file" id="resume_input" accept=".pdf,.txt,.doc,.docx" @change="handleFileChange"
                        :disabled="resume_spec_option!='upload' || inputsDisabled">
                      </div>
                    </div>
                    
                    <div class="RadioButtonDescriptionRow" v-else>
                      <div class="Pill">
                        <label for="option_upload">{{selected_file.name}}</label>
                      </div>
                      <!-- Change File Button -->
                      <div class="DescriptionRowIconButton">
                        <themed-button componentId="change_resume_file" button-type="icon" color="var(--color-text-input)" :is-disabled="inputsDisabled" icon="edit" :tooltip-text="FILE_CHANGE_TOOLTIP"
                        @buttonClick="handleButtonClick"/>
                      </div>
                    </div>
                    <!-- Error Message -->
                    <div class="RadioButtonDescriptionRow" v-if="resume_file_error_message">
                      <!-- <div class="TextParagraph ErrorColor" for="option_upload" v-if="resume_spec_option=='upload' && resume_file_error_message">{{ resume_file_error_message }}</div> -->
                      <div class="InputError WiggleBox" for="option_upload">
                        <div class="InputErrorIconContainer ErrorColor">
                          <Icon iconName="warning"/>
                        </div>
                        <div class="InputErrorText TextTruncate">{{ resume_file_error_message }}</div>
                      </div>
                    </div>
                  </template>
                  <!-- Manual Entry -->
                  <div class="RowAlignedLeft">
                    <input class="ThemedRadioButton" type="radio" id="option_manual_entry" value="manual_entry" v-model="resume_spec_option" :disabled="inputsDisabled">
                    <label class="TextParagraph" for="option_manual_entry">{{ INSTRUCTION_COPY_RESUME }}</label>
                    
                  </div>
                  <template v-if="resume_spec_option=='manual_entry'">         
                    <!-- Text Editor -->
                    <div class="RowAlignedLeft">
                      <div class="RadioButtonTextRow">
                        <textarea class="ThemedInput" id="resume_text" v-model="text_resume" required 
                        :disabled="inputsDisabled" 
                        :placeHolder="PLACE_HOLDER_RESUME"
                        rows="15"></textarea>
                        <div class="InputLabel">
                          <div class="HorizontalGroup NoHorizontalSpace">
                            <div for="resume_text">{{LABEL_RESUME.toUpperCase()}}</div>
                            <div class="ErrorColor">*</div>
                          </div>
                        </div>
                      </div>       
                    </div>
                    <!-- Error Message -->
                    <div class="RadioButtonDescriptionRow"  v-if="text_error_message">
                      <!-- <div class="TextParagraph ErrorColor" for="option_upload" v-if="text_error_message">{{ text_error_message }}</div> -->
                      <div class="InputError WiggleBox" for="option_upload">
                        <div class="InputErrorIconContainer ErrorColor">
                          <Icon iconName="warning"/>
                        </div>
                        <div class="InputErrorText TextTruncate">{{ text_error_message }}</div>
                      </div>
                    </div>
                    <div class="RadioButtonDescriptionRow">       
                    </div>
                  </template>
                  <div class="RowAlignedLeft">
                    <themed-button componentId="resume_complete" button-type="primary"
                      :label = "getResumeCTALabel"
                      :isDisabled="!isResumeSpecComplete || inputsDisabled" 
                      width="full"
                      @buttonClick="handleButtonClick"/>
                  </div>
                </div>
              </div>
              <div class="CardConnector">
                <icon icon-name="down-arrow-connector"></icon>
              </div>
              <!-- Job specifications -->
              <div class="RegularCard NoTopMargin">
                <div class="CardDoubleHeaderRow">
                  <div class="CardHeaderIconContainer"  @click="!isPreviousApplication? 'toggleJobCard':''">
                    <div class="CardDoubleHeaderIcon ">
                      <icon icon-name="experience"></icon>
                    </div>
                    <!-- Spinner -->
                    <div class="CardStatusIndicator SelectedStateColor" v-if="fetching_job">
                      <Icon iconName="spinning-animation"/>
                    </div>
                    <!-- CompleteIcon -->
                    <div class="CardStatusIndicator SuccessColor" v-else-if="isJobSpecComplete">
                      <Icon iconName="circle-check-filled"/>
                    </div>
                    <!-- Incomplete Icon -->
                    <div class="CardStatusIndicator WarningColor" v-else-if="!isJobSpecComplete">
                      <Icon iconName="circle-warning-filled"/>
                    </div>
                  </div>
                  <div class="CardHeaderSection"  @click="!isPreviousApplication? 'toggleJobCard':''">
                    <div class="TextHeading1">{{ CARD_HEADER_JOB }}</div>
                    <div class="TextDescription MarginTop NoWrap">{{ getJobSpecSummary }}</div>
                  </div>
                  <div class="DoubleHeaderCardIconButton">
                      <themed-button componentId="reset_job_inputs" button-type="icon" :is-disabled="jobInputsEmpty || inputsDisabled" 
                      icon="reset" :tooltip-text="TOOLTIP_CLEAR" @buttonClick="handleButtonClick"/>
                    </div>
                  <div class="DoubleHeaderCardIconButton">
                    <themed-button componentId="toggle_job_card" button-type="icon" :is-disabled="isPreviousApplication" 
                    icon="circle-up-arrow" :rotate="true" :toggle-state="job_spec_card_expanded"
                    :tooltip-text="job_spec_card_expanded? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                    @buttonClick="handleButtonClick"/>
                  </div>
                </div>
                <!-- Card Body -->
                <div :class="['RegularCardSection', jobCardClass ]" ref="jobCard">
                  <!-- Job Selector -->
                  <!-- Job URL -->
                  <div class="RowAlignedLeft">
                    <input class="ThemedRadioButton" type="radio" id="option_job_url" value="job_url" v-model="job_spec_option" :disabled="inputsDisabled">
                    <label class="TextParagraph" for="option_job_url">{{JOB_URL_INSTRUCTIONS}}</label>
                  </div>
                  <template v-if="job_spec_option == 'job_url'">
                    <div class="RowAlignedLeft" >
                      <div class="RadioButtonTextRow">
                        <input class="ThemedInput" type="text" id="job_url_input" 
                        :disabled="fetching_job || inputsDisabled" v-model="job_url_input" required
                        :placeHolder="PLACE_HOLDER_JOB_URL">
                        <div class="InputLabel">
                          <div class="HorizontalGroup NoHorizontalSpace">
                            <label for="job_url_input">{{LABEL_JOB_URL.toUpperCase()}}</label>
                            <div class="ErrorColor">*</div>
                          </div>
                        </div>
                      </div>            
                    </div>
                    <!-- Error Message -->
                    <div class="RadioButtonDescriptionRow" v-if="job_url_error_message">
                      <div class="InputError WiggleBox">
                        <div class="InputErrorIconContainer ErrorColor">
                          <Icon iconName="warning"/>
                        </div>
                        <div class="InputErrorText TextTruncate">{{ job_url_error_message }}</div>
                      </div>
                      
                    </div>
                  </template>
                  <!-- Copy Paste  -->
                  <div class="RowAlignedLeft">
                    <input class="ThemedRadioButton" type="radio" id="option_copy_paste" value="copy_paste"
                      v-model="job_spec_option" :disabled="inputsDisabled">
                    <label class="TextParagraph" for="option_copy_paste">{{ JOB_COPY_PASTE_INSTRUCTIONS }}</label>
                  </div>
                  <!--Copy Paste Inputs-->
                  <template v-if="job_spec_option === 'copy_paste'">
                    <!-- Specify Position -->
                    <div class="RowAlignedLeft">
                      <div class="RadioButtonTextRow">
                        <input class="ThemedInput" type="text" id="job_title" v-model="job_title" required 
                        :disabled="inputsDisabled"
                        :placeHolder="PLACE_HOLDER_POSITION">
                        <div class="InputLabel">
                          <div class="HorizontalGroup NoHorizontalSpace">
                            <div for="job_title">{{LABEL_POSITION.toUpperCase()}}</div>
                            <div class="ErrorColor">*</div>
                          </div>
                        </div>
                      </div>    
                    </div>
                    <!-- Specify Additional details -->
                    <div class="RowAlignedLeft">
                      <div class="ChildCard">
                        <div class="CardHeaderRow">
                          <div class="TextParagraph FontBold"> {{ LABEL_OTHER_DETAILS}} </div>
                          <!-- Expand/ Collapse Button -->
                          <div class="ChildCardIconButton">
                            <themed-button componentId="toggle_job_description" button-type="icon" :is-disabled="false" 
                            icon="up-arrow" :rotate="true" :toggle-state="job_description_expanded"
                            :tooltip-text="job_description_expanded ? TOOLTIP_COLLAPSE : TOOLTIP_EXPAND"
                            @buttonClick="handleButtonClick"/>
                          </div>
                        </div>
                        <div class="RegularCardSection" :class="{ 'collapsed': !job_description_expanded }">
                          <!-- Instructions -->
                          <div class="RowAlignedLeft MarginBottom">
                            <div class="TextDescription">{{ JOB_DESCRIPTION_INSTRUCTIONS }}</div>
                          </div>
                          <!-- Company -->
                          <div class="RowAlignedLeft MarginBottom">
                            <div class="ChildCardRow">  
                              <input class="ThemedInput" type="text" id="company_name" v-model="company_name"
                              :disabled="inputsDisabled"
                              :placeHolder="PLACE_HOLDER_COMPANY">
                              <label class="InputLabel" for="company_name">{{LABEL_COMPANY.toUpperCase()}}</label>
                            </div>
                          </div>
                          <!-- Job Description -->
                          <div class="RowAlignedLeft">
                            <div class="ChildCardRow">
                              <textarea class="ThemedInput" id="job_description" v-model="job_description"
                              :disabled="inputsDisabled"
                              :placeHolder="PLACE_HOLDER_JOB_DESCRIPTION"
                              rows="10"></textarea>
                              <label class="InputLabel" for="job_description">{{LABEL_JOB_DESCRIPTION.toUpperCase()}}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Message Section -->
                    <div class="RadioButtonDescriptionRow" v-if="job_manual_entry_error_message">
                      <div class="InputError WiggleBox">
                        <div class="InputErrorIconContainer ErrorColor">
                          <Icon iconName="warning"/>
                        </div>
                        <div class="InputErrorText TextTruncate">{{ job_manual_entry_error_message}}</div>
                      </div>
                    </div>
                  </template>
                  <div class="RowAlignedLeft">
                    <themed-button componentId="job_complete" button-type="primary"
                      :label = "getJobCTALabel"
                      :isDisabled="!isJobSpecComplete || inputsDisabled" 
                      width="full"
                      @buttonClick="handleButtonClick"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Preview Panel -->
        <div class="PreviewPanel" >
          <div class="TopMenuContainer BorderBottom" ref="generateButton">
            <div class="TopMenuRow">
              <div class="PageHeaderTextButton" v-if="isPreviousApplication">
                <themed-button componentId="resume" button-type="secondary"
                :label = BUTTON_LABEL_VIEW_SHORT width="fit"
                @buttonClick="handleButtonClick"/>
              </div>
              <div class="PageHeaderTextButton" v-if="isPreviousApplication">
                <themed-button componentId="restart" button-type="primary"
                  :label = TOOLTIP_RESTART width="fit"
                  @buttonClick="handleButtonClick"/>
              </div>
              <template v-else>
                <div class="PageHeaderTextButton">
                  <themed-button componentId="generate_match" button-type="secondary" v-if="!match_in_progress && !resume_in_progress && !showMatchPreview && !generatedResumeAvailable"
                    :label = BUTTON_LABEL_MATCH :isDisabled="!(isJobSpecComplete && isResumeSpecComplete)" width="fit"
                    @buttonClick="handleButtonClick"/>
                </div>
                <div class="PageHeaderTextButton">
                  <themed-button componentId="generate_application" button-type="primary" v-if="!match_in_progress && !resume_in_progress && !generatedResumeAvailable"
                    :label = BUTTON_LABEL_GENERATE :isDisabled="!(isJobSpecComplete && isResumeSpecComplete)" width="fit"
                    @buttonClick="handleButtonClick"/>
                  <themed-button componentId="resume" button-type="primary" v-else-if="generatedResumeAvailable"
                    :label = BUTTON_LABEL_VIEW :isDisabled="!(isJobSpecComplete && isResumeSpecComplete)" width="fit"
                    @buttonClick="handleButtonClick"/>
                  <themed-button componentId="cancel_application" button-type="secondary"  v-else-if="match_in_progress || resume_in_progress"
                    :label = BUTTON_LABEL_CANCEL_APP :isDisabled="!(isJobSpecComplete && isResumeSpecComplete)" width="fit"
                    @buttonClick="handleButtonClick"/>
                </div>
              </template>
            </div>
            <div class="ProgressScaffold">
                <div class="InputProgressScaffold ProgressBackground">
                  <bar :percentage=inputProgress ></bar>
                  <div class="TextDescription ProgressTextHeight">{{LABEL_INPUT_COMPLETE}}</div> 
                </div>
                <div class="MatchProgressScaffold ProgressBackground">
                  <bar :percentage=match_progress ></bar>
                  <div class="TextDescription ProgressTextHeight">{{ match_in_progress? LABEL_MATCH_PROGRESS: LABEL_MATCH_COMPLETE}}</div>
                </div>
                <div class="ResumeProgressScaffold ProgressBackground">
                  <bar :percentage=resume_progress ></bar>
                  <div class="TextDescription ProgressTextHeight">{{ resume_in_progress? LABEL_RESUME_PROGRESS : LABEL_RESUME_COMPLETE }}</div>
                </div>
                <div class="ProgressIndicator ProgressBackground">
                  <div class="TextStatusIndicator">{{ totalProgress }}%</div>
                </div>
              </div>
          </div>
          <!-- Top Menu -->     
          <!-- <div class="StickyContainer BorderBottom">
            <div class="TopMenuScaffold JustifyCenter">
              <progress-bar :resetProgress=progress :totalDuration=resume_timer :showProgressAnimation=show_progress_animation :isError=resume_generation_error :text=progress_bar_text />
            </div>
          </div> -->
          <!-- Tabs -->
          <div class="TabMenu">
            <tab-button componentId="job_preview" 
              :hasRightSibling=true 
              :isSelected="current_tab === 'job_preview'" 
              :isDisabled="!showJobPreview" 
              :label=BUTTON_LABEL_JOB_PREVIEW 
              @tabClick="handleButtonClick"></tab-button>
            <tab-button componentId="match_preview" 
              :hasRightSibling=false :isSelected="current_tab === 'match_preview'" 
              :isDisabled="!showMatchPreview"  
              :label=BUTTON_LABEL_JOB_MATCH 
              @tabClick="handleButtonClick"></tab-button>
          </div>
          <div class="JobPanelContainer" ref="tabContainer">
            <div class="PreviewScaffold" :class="{ 'CardShadow': showJobPreview || showMatchPreview }">
              <!-- Empty State -->
              <div :class="['PreviewEmptyState', jobPreviewClass]">
                <Icon iconName="rocket"/>
                <div class="TextDescription TextAlignCenter">{{ EMPTY_STATE_MESSAGE_LINE1 }}</div>
                <div class="TextDescription TextAlignCenter">{{ EMPTY_STATE_MESSAGE_LINE2 }}</div>
              </div>
              <!-- Job Preview -->
              <div :class="['PrintPreview', jobPreviewClass]">
                  <div class="HorizontalGroup MarginBottom" v-show="job_title">
                    <div class="TextHeading2 FontBold">{{ job_title }}</div>
                  </div>      
                  <div class="HorizontalGroup MarginBottom" v-show="company_name">
                    <div class="TextParagraph FontBold">Company:</div>
                    <div class="TextDescription">{{ company_name }}</div>
                  </div>
                  <div class="HorizontalGroup MarginBottom" v-show="job_location">
                    <div class="TextParagraph FontBold">Location:</div>
                    <div class="TextDescription">{{ job_location }}</div>
                  </div>
                  <div class="TextParagraph FontBold" v-show="job_description">Job Description</div>
                  <pre class="TextPreview">{{ job_description }}</pre>
                </div>
              <!-- Match Preview -->
              <div :class="['PrintPreview', matchPreviewClass]">
                <div v-if="hasMandatoryRequirements">
                  <!-- Match Summary Card -->
                  <div class="MatchCard">
                    <div class="CardHeaderRow NoHorizontalPadding ExtraBottomMargin">
                      <div class="TextHeading1" ref="evaluationSummary">{{LABEL_MATCH_SUMMARY_TITLE}}</div>
                    </div>
                    <div class="MatchSummaryRow MarginBottom">
                      <div class="TextDescription">{{ MESSAGE_EVALUATION_SUMMARY }}</div>
                    </div>
                    <!-- ATS Match -->
                    <div class="RegularCardSection">
                      <div class="MatchSummaryRow">
                        <div class="MatchIndicator">
                          <bar :color="scoreToColor(combinedATSScore)" 
                          thickness="0.5rem" 
                          :percentage=combinedATSScore ></bar>
                        </div>
                        <div class="MatchText BoldText">{{ LABEL_ATS_SCORE }}</div>
                      </div>  
                      <div class="MatchSummaryRow">
                        <div class="ScoreValue" :style="{color: scoreToColor(combinedATSScore)}">{{ scoreToText(combinedATSScore) }}</div>
                        <div class="TextDescription"> {{messageATSMatch}}</div>
                      </div>
                    </div>
                    <!-- Qualitative Match -->
                    <div class="RegularCardSection">
                      <div class="MatchSummaryRow">
                        <div class="MatchIndicator">
                          <bar :color="scoreToColor(application_json.match.overall_match_score)" 
                          thickness="0.5rem" 
                          :percentage=application_json.match.overall_match_score ></bar>
                        </div>
                        <div class="MatchText BoldText">{{ LABEL_MATCH_SCORE }}</div>
                      </div>  
                      <div class="MatchSummaryRow">
                        <div class="ScoreValue" :style="{color: scoreToColor(application_json.match.overall_match_score)}">{{ scoreToText(application_json.match.overall_match_score) }}</div>
                        <div class="TextDescription"> {{messageQualitativeMatch}}</div>
                      </div>
                    </div>
                    <!-- Keyword Boost -->
                    <div class="RegularCardSection">
                      <div class="MatchSummaryRow">
                        <div class="MatchIndicator">
                          <bar :color="scoreToColor(keywordBoost.score)" 
                          thickness="0.5rem" 
                          :percentage=keywordBoost.score ></bar>
                        </div>
                        <div class="MatchText BoldText">{{LABEL_BOOST_SCORE}}</div>
                      </div>  
                      <div class="MatchSummaryRow">
                        <div class="ScoreValue" :style="{color: scoreToColor(keywordBoost.score)}">{{ scoreToText(keywordBoost.score) }}</div>                   
                        <div class="TextDescription"> {{keywordBoost.message}}</div>
                      </div>
                    </div>
                    <!-- FAQs -->
                     <div class="QuestionsContainer MarginTop" @click="scrollToScoreDetails">{{ QUESTION_SCORE_BREAKDOWN }}
                      <div class="QuestionsIcon"><Icon iconName="right-arrow"/></div>
                     </div>
                     <div class="QuestionsContainer" @click="scrollToJobRequirements">{{ QUESTION_REQUIREMENTS_MATCH }}
                      <div class="QuestionsIcon"><Icon iconName="right-arrow"/></div>
                     </div>
                     <div class="QuestionsContainer" @click="scrollToKeywords">{{ QUESTION_KEYWORDS_MATCH }}
                      <div class="QuestionsIcon"><Icon iconName="right-arrow"/></div>
                     </div>
                  </div>
                  <!-- Score Details Card -->
                  <div class="MatchCard">
                    <div class="CardHeaderRow NoHorizontalPadding ExtraBottomMargin">
                      <div class="TextHeading1" ref="scoreDetails">{{LABEL_SCORE_DETAILS}}</div>
                    </div>
                    <div class="MatchSummaryRow MarginBottom">
                      <div class="TextDescription">{{ MESSAGE_SCORE_DETAILS }}</div>
                    </div>
                    <!-- ATS Score Breakdown -->
                    <div class="RegularCardSection VerticalSpacing">
                      <div class="MatchSummaryRow">
                        <div class="MatchText BoldText InlineText">{{ LABEL_ATS_SCORE_DETAILS }}
                          <button class="TertiaryButton FontSmall" @click="scrollToKeywords">{{ LABEL_DETAILS }}</button>
                        </div>
                      </div>
                      <div class="MatchSummaryRow MarginBottom">
                        <div class="TextDescription">{{ MESSAGE_ATS_ROUND }}</div>
                      </div>
                      <!-- Readability -->
                      <div class="MatchSummaryRow JustifyCenter" v-if="'resume_readability' in application_json.match">
                        <div class="InputProgressScaffold">
                          <div class="MatchTextSmall AlignRight ">{{ LABEL_RESUME_READABILITY }}</div>
                        </div>
                        <div class="InputProgressScaffold">
                          <div class="FullScoreIndicator">
                            <bar :color="scoreToColor(application_json.match.resume_readability)" 
                            thickness="0.5rem" 
                            :percentage=application_json.match.resume_readability ></bar>
                          </div>
                        </div>
                        <div class="ProgressIndicator">
                          <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.resume_readability)}"> {{ Math.round(application_json.match.resume_readability)}}</div>
                        </div>
                      </div> 
                      <!-- Keywords -->
                      <div class="MatchSummaryRow JustifyCenter" v-if="'keyword_frequency_score' in application_json.match">
                        <div class="InputProgressScaffold">
                          <div class="MatchTextSmall AlignRight ">{{ LABEL_KEYWORD_SCORE }}</div>
                        </div>
                        <div class="InputProgressScaffold">
                          <div class="FullScoreIndicator">
                            <bar :color="scoreToColor(application_json.match.keyword_frequency_score)" 
                            thickness="0.5rem" 
                            :percentage=application_json.match.keyword_frequency_score ></bar>
                          </div>
                        </div>
                        <div class="ProgressIndicator">
                          <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.keyword_frequency_score)}"> {{ Math.round(application_json.match.keyword_frequency_score)}}</div>
                        </div>
                      </div> 
                    </div>
                    <!-- Qualitative Match Breakdown -->
                    <div class="RegularCardSection VerticalSpacing MarginTop">
                      <div class="MatchSummaryRow">
                        <div class="MatchText BoldText InlineText">{{ LABEL_MATCH_SCORE_DETAILS }}
                          <button class="TertiaryButton FontSmall" @click="scrollToJobRequirements">{{ LABEL_DETAILS }}</button>
                        </div>
                      </div>
                      <div class="MatchSummaryRow MarginBottom">
                        <div class="TextDescription">{{ MESSAGE_RECRUITER_ROUND }}</div>
                      </div>
                      <!-- Experience -->
                      <div class="MatchSummaryRow JustifyCenter" v-if="'years_of_experience' in application_json.match">
                        <div class="InputProgressScaffold">
                          <div class="MatchTextSmall AlignRight ">{{ LABEL_EXPERIENCE_SCORE }}</div>
                        </div>
                        <div class="InputProgressScaffold">
                          <div class="FullScoreIndicator">
                            <bar :color="scoreToColor(application_json.match.years_of_experience)" 
                            thickness="0.5rem" 
                            :percentage=application_json.match.years_of_experience ></bar>
                          </div>
                        </div>
                        <div class="ProgressIndicator">
                          <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.years_of_experience)}"> {{ Math.round(application_json.match.years_of_experience)}}</div>
                        </div>
                      </div> 
                      <!-- Hard Skills -->
                      <div class="MatchSummaryRow JustifyCenter" v-if="'hard_skills' in application_json.match">
                        <div class="InputProgressScaffold">
                          <div class="MatchTextSmall AlignRight ">{{ LABEL_HARD_SKILLS_SCORE }}</div>
                        </div>
                        <div class="InputProgressScaffold">
                          <div class="FullScoreIndicator">
                            <bar :color="scoreToColor(application_json.match.hard_skills)" 
                            thickness="0.5rem" 
                            :percentage=application_json.match.hard_skills ></bar>
                          </div>
                        </div>
                        <div class="ProgressIndicator">
                          <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.hard_skills)}"> {{ Math.round(application_json.match.hard_skills)}}</div>
                        </div>
                      </div> 
                      <!-- Education -->
                      <div class="MatchSummaryRow JustifyCenter" v-if="'educational_qualifications' in application_json.match">
                        <div class="InputProgressScaffold">
                          <div class="MatchTextSmall AlignRight ">{{ LABEL_EDUCATION_SCORE }}</div>
                        </div>
                        <div class="InputProgressScaffold">
                          <div class="FullScoreIndicator">
                            <bar :color="scoreToColor(application_json.match.educational_qualificationss)" 
                            thickness="0.5rem" 
                            :percentage=application_json.match.educational_qualifications ></bar>
                          </div>
                        </div>
                        <div class="ProgressIndicator">
                          <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.educational_qualifications)}"> {{ Math.round(application_json.match.educational_qualifications)}}</div>
                        </div>
                      </div> 
                      <!-- Soft Skills -->
                      <div class="MatchSummaryRow JustifyCenter" v-if="'soft_skills' in application_json.match">
                        <div class="InputProgressScaffold">
                          <div class="MatchTextSmall AlignRight ">{{ LABEL_SOFT_SKILLS_SCORE }}</div>
                        </div>
                        <div class="InputProgressScaffold">
                          <div class="FullScoreIndicator">
                            <bar :color="scoreToColor(application_json.match.soft_skills)" 
                            thickness="0.5rem" 
                            :percentage=application_json.match.soft_skills ></bar>
                          </div>
                        </div>
                        <div class="ProgressIndicator">
                          <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.soft_skills)}"> {{ Math.round(application_json.match.soft_skills)}}</div>
                        </div>
                      </div> 
                      <!-- Other Requirements-->
                      <div class="MatchSummaryRow JustifyCenter" v-if="'other' in application_json.match">
                        <div class="InputProgressScaffold">
                          <div class="MatchTextSmall AlignRight ">{{ LABEL_OTHER_REQUIREMENTS_SCORE }}</div>
                        </div>
                        <div class="InputProgressScaffold">
                          <div class="FullScoreIndicator">
                            <bar :color="scoreToColor(application_json.match.other)" 
                            thickness="0.5rem" 
                            :percentage=application_json.match.other ></bar>
                          </div>
                        </div>
                        <div class="ProgressIndicator">
                          <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.other)}"> {{ Math.round(application_json.match.other)}}</div>
                        </div>
                      </div> 
                      <!-- Optional Requirements : Hiding it since it's displayed in the next section -->
                      <!-- <div class="MatchSummaryRow JustifyCenter" v-if="hasOptionalRequirements">
                        <div class="InputProgressScaffold">
                          <div class="MatchTextSmall AlignRight ">{{ LABEL_OPTIONAL_MATCH }}</div>
                        </div>
                        <div class="InputProgressScaffold">
                          <div class="FullScoreIndicator">
                            <bar :color="scoreToColor(application_json.match.optional_match_score)" 
                            thickness="0.5rem" 
                            :percentage=application_json.match.optional_match_score ></bar>
                          </div>
                        </div>
                        <div class="ProgressIndicator">
                          <div class="MatchTextSmall" :style="{color: scoreToColor(application_json.match.optional_match_score)}"> {{ Math.round(application_json.match.optional_match_score)}}</div>
                        </div>
                      </div>  -->
                    </div>
                    <div class="MatchSummaryRow JustifyCenter MarginTop">
                      <div class="QuestionsIcon">
                        <themed-button  componentId="scroll_to_top" button-type="icon"  
                        icon="to-top" :tooltip-text="TOOLTIP_SCROLL_TOP" @buttonClick="handleButtonClick"/>
                      </div>
                    </div>  
                  </div>
                  <!-- <match-bar :match=application_json.match.overall_match ></match-bar> -->
                </div>
                <!-- Job Requirements Card-->
                <div class="MatchCard"  v-if="hasMandatoryRequirements">
                  <div class="CardHeaderRow NoHorizontalPadding">
                    <div class="TextHeading1" ref="jobRequirements">{{LABEL_JOB_REQUIREMENTS}}</div>
                  </div>
                  <div class="MatchSummaryRow MarginBottom">
                    <div class="TextDescription">{{ MESSAGE_JOB_REQUIREMENTS }}</div>
                  </div>
                  <!-- Mandatory -->
                  <div class="RegularCardSection">
                    <div class="MatchSummaryRow MarginBottom">
                        <div class="MatchText BoldText">{{ LABEL_MANDATORY_MATCH }}</div>
                      </div>
                    <div class="HorizontalGroup">
                      <div class="ChartContainer">
                        <doughnut-chart :percentage="application_json.match.mandatory_match_score" 
                            :color="scoreToColor(application_json.match.mandatory_match_score)"></doughnut-chart>
                      </div>
                      <ul class="MatchList">
                        <li class="MatchRow" v-for="(requirement, index) in application_json.match.mandatory_requirements" :key="index">
                          <div class="HorizontalGroup AlignTop">
                            <div class="IconContainerMini MatchIconColor">
                              <Icon :iconName="requirement.candidate_match? 'circle-checklist':'circle'"/>
                            </div>
                            <div class="MatchListWidth TextDescription">
                              <span> {{ requirement.requirement }}
                                <div class="ImpactPill"> {{ scoreKeyToImpact(requirement.requirement_type) }} </div>
                              </span>
                            </div>                          
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- Optional -->
                  <div class="RegularCardSection" v-if="hasOptionalRequirements">
                    <div class="MatchSummaryRow MarginBottom">
                      <div class="MatchText BoldText">{{ LABEL_OPTIONAL_MATCH }}</div>
                    </div>
                    <div class="HorizontalGroup">
                      <div class="ChartContainer">
                        <doughnut-chart :percentage="application_json.match.optional_match_score" 
                            :color="scoreToColor(application_json.match.optional_match_score)"></doughnut-chart>
                      </div>
                      <ul class="MatchList">
                        <li class="MatchRow" v-for="(requirement, index) in application_json.match.optional_requirements" :key="index">
                          <div class="HorizontalGroup AlignTop">
                            <div class="IconContainerMini MatchIconColor">
                              <Icon :iconName="requirement.candidate_match? 'circle-checklist':'circle'"/>
                            </div>
                            <div class="TextDescription MatchListWidth"> 
                              <span> {{ requirement.requirement }}
                                <div class="ImpactPill"> {{ LABEL_IMPACT_LOW }} </div>
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- Scroll to top -->
                  <div class="MatchSummaryRow JustifyCenter">
                    <div class="QuestionsIcon">
                      <themed-button  componentId="scroll_to_top" button-type="icon"  
                      icon="to-top" :tooltip-text="TOOLTIP_SCROLL_TOP" @buttonClick="handleButtonClick"/>
                    </div>
                  </div> 
                </div>
                <!-- Keywords Card -->
                <div class="MatchCard" v-if="application_json && application_json.keywords">
                  <div class="CardHeaderRow NoHorizontalPadding">
                    <div class="TextHeading1" ref="keywords">{{LABEL_KEYWORDS}}</div>
                  </div>
                  <div class="MatchSummaryRow MarginBottom">
                    <div class="TextDescription">{{ MESSAGE_KEYWORDS }}</div>
                  </div>
                  <span class="KeywordsContainer">
                    <!-- show a keyword is present if the presence score > 0.02 -->
                    <div class="ImpactPill KeywordPillMargin TextTruncate" :class="{'KeywordPresent': presence > KEYWORD_PRESENCE_THRESHOLD}"
                      v-for="(presence, keyword) in application_json.keywords" :key="keyword"> {{ keyword }}</div>
                  </span>
                  <!-- Scroll to top -->
                  <div class="MatchSummaryRow JustifyCenter">
                    <div class="QuestionsIcon">
                      <themed-button  componentId="scroll_to_top" button-type="icon"  
                      icon="to-top" :tooltip-text="TOOLTIP_SCROLL_TOP" @buttonClick="handleButtonClick"/>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import StepperButton from './ui_controls/StepperButton.vue'; 
import ProgressBar from './ui_controls/ProgressBar.vue'; 
import TabButton from './ui_controls/TabButton.vue'; 
import Icon from './ui_controls/Icon.vue'; 
import ThemedButton from './ui_controls/ThemedButton.vue'; 
import ToastMessage from './ui_controls/ToastMessage.vue';
import eventBus from '../utils/event-bus';
import { ToastEvents } from '../utils/toast-events';
import MatchBar from './ui_controls/MatchBar.vue'; 
import Bar from './ui_controls/Bar.vue'; 
import DoughnutChart from './ui_controls/DoughnutChart.vue'; 

export default {
  components: {
    StepperButton,
    ProgressBar,
    TabButton,
    Icon,
    ThemedButton,
    ToastMessage,
    MatchBar,
    Bar,
    DoughnutChart,
  },
  data() {
    return {
      //resume input related
      resume_uploading: false, //if the upload resume API call is in progress
      resume_spec_option: 'upload', // resume type selection in the radio group
      selected_file: null, //the resume file selected 
      resume_file_error_message: '', // error message to show for resume file input
      resume_upload_successful: false, //if the resume was uploaded successfully without errors
      text_resume:'', //the manually entered resume text
      is_valid_resume_text: false, //Is the text entered into the resume input valid?
      text_error_message: '', //error message to show for resume text input
      resume_spec_card_expanded: false, //whether the resume spec card should be expanded
      //job input related
      job_spec_card_expanded: false, //whether the job spec card should be expanded
      job_description_expanded: true, //whether the job description card should be expanded
      job_spec_option: 'job_url', // job type selection in the radio group
      job_url_input: '', // the job url provided
      job_url_error_message: '', // message if the job description from the job url cannot be parsed
      job_manual_entry_error_message: '', // error message for manually entered job description
      job_title: '', //the position in the job description
      company_name: '', //the company name in the job description
      job_location: '', //the city/location in the job description
      job_description: '', //the rest of the job description
      job_site: '', //the job site (e.g. linkedin, greenhouse) where the job description is available
      // Application data model
      application_id: '', //the job application ID 
      application_json: {},
      last_uploaded_resume: { //store details of last uploaded resume
        available: false, //indicates whether the user last uploaded any resume
        file_name: '', //the file_name of the last uploaded resume
        timestamp: '', //the timestamp of the last uploaded resume
      },
      last_application: { //store details of last job application
        available: false,
        id: '', //the user_application record id
        position: '', // the position applied to
        company: '', // the company applied to
        time: '', // the application time
      },
      //Other UI
      user_authenticated: false, // check whether the user has been authenticated
      current_tab: 'job_preview', //Options = blank (default), job_preview or match_preview i.e. the corresponding preview to display
      previous_tab: 'job_preview', //the previous tab selection
      // Request + progress related
      fetching_job: false, //flag to check whether the job validation API call is in progress
      match_in_progress: false, // Whether the match generation request is in progress
      resume_in_progress: false, //Whether the resume generation request is in progress
      cancel_application: false, //flag to cancel any ongoing resume or match generation request
      match_progress_toast: null, //Toast ID of the match progress toast message
      // Progress Animation Related
      show_progress_animation: false, //whether to show the progress bar amimation
      resume_generation_error: false, //whether there was an error in resume generation
      // progress_bar_text:'Resume Progress', //text to show for the progress bar
      match_progress: 0, //the counter used to reset the match progress bar
      resume_progress: 0, //the counter used to reset the resume progress bar
      total_progress: 0, //the progress counter shown to the user
      intervalId: 0, //animation interval
      resume_timer: 70, //the duration in seconds for generating a resume
      match_timer: 30, //the duration in seconds for generating the match
      // --- CONTENT ---
      ERROR_MESSAGE_DEFAULT: 'Apologies for the inconvenience. There was a brief communication problem with OpenAI.\nPlease wait a few minutes and try again. Thank you for your patience!', //Title to show when resume generation API call fails
      ERROR_MESSAGE_RELOAD: 'Apologies for the inconvenience. It\'s been a few days since you last worked on this application.\nPlease refresh or reload your browser window to continue.', //Title to show when resume refresh fails
      ERROR_MESSAGE_JOB: 'Umm...The job details you entered don\'t meet our terms of service.\nTo generate a tailored resume, please provide a legitimate job description. We\'re here to help you succeed!', //Error message for fake and malicious job description
      ERROR_MESSAGE_RESUME: 'Umm..The resume you provided doesn\'t meet our terms of service.\nPlease provide a real, professional resume and we\'ll be happy to help you create a compelling application.', //Error message for fake and malicious resume
      ERROR_MESSAGE_RESUME_FILE: 'The resume file could not be processed. We only accept .pdf, .docx, .doc, and .txt files under 700KB.\nAdditionally ensure that your resume does not include images or complex formatting like tables. These cannot be read by an Applicant Tracking System (ATS). Please try copying and pasting your resume instead.', //Generic Error message when resume file upload errors out
      ERROR_TERMS_OF_SERVICE: 'Umm...The resume or the job you have provided does not meet our terms of service.\nPlease check your inputs and try again.',
      ERROR_JOB_CANCELED_BY_USER: 'You have canceled this task',
      INPUTS_COMPLETED_MESSAGE: 'Click \'Generate Tailored Resume\' to view your customized resume',
      JOB_RESUME_VALIDATION_MESSAGE: 'Completed job and resume validation',
      JOB_MESSAGE_VALID_URL: 'Job description imported successfully.'+'\n'+'Please check the preview to ensure that that the job description is correct',
      JOB_MESSAGE_INVALID_URL: 'Please enter a valid URL',
      JOB_MESSAGE_UNKNOWN_ATS: 'This job site is not supported yet. ',
      JOB_MESSAGE_UNSUPPORTED_ATS: 'Please copy and paste the job description instead',
      JOB_MESSAGE_INACTIVE_JOB: 'This job is no longer accepting applications. Please select a different job',
      JOB_MESSAGE_INACTIVE_JOB_SHORT: 'This job post has expired',
      JOB_MESSAGE_DEFAULT_ERROR_SHORT: 'Could not read job description',
      JOB_MESSAGE_DEFAULT_ERROR_LONG: 'There was an error fetching the job description.\nPlease manually enter the position or copy and paste the job description below.',
      JOB_MESSAGE_NOT_FOUND: 'The job URL was not found',
      JOB_MESSAGE_FORBIDDEN: 'Access to this job posting was denied by  LinkedIn. Please try again in a few seconds.\nYou could also try copying and pasting the job description below',
      JOB_SPEC_SUMMARY_URL: 'Job URL provided',
      JOB_SPEC_SUMMARY_MANUAL_ENTRY: 'Position details provided',
      FILE_MESSAGE_TYPE_ERROR: 'Please provide a .pdf, .doc, .docx, or .txt file',
      FILE_MESSAGE_SIZE_ERROR: 'Please provide a resume that is under 1MB', //also see MAX_FILE_SIZE
      FILE_MESSAGE_DEFAULT_ERROR: 'Invalid file type or size',
      FILE_CHANGE_TOOLTIP: 'Select a different file',
      RESUME_UPLOAD_PROGRESS: 'Validating resume ...',
      RESUME_UPLOAD_SUCCESS: 'Resume file uploaded successfuly',
      RESUME_TEXT_MIN_LENGTH_ERROR: 'Provided content does not meet the minimum length requirements for a resume.',
      RESUME_TEXT_MAX_LENGTH_ERROR: 'Provided content is too long for a resume.',
      RESUME_TEXT_ALPHA_ERROR: 'There are too many special characters and blank spaces',
      RESUME_SPEC_SUMMARY_LAST_APPLICATION: 'Selected last generated resume ',
      RESUME_SPEC_SUMMARY_EXISTING: 'Selected last uploaded resume',
      RESUME_SPEC_SUMMARY_FILE: 'Resume file uploaded',
      RESUME_SPEC_SUMMARY_MANUAL_ENTRY: 'Resume provided',
      PROGRESS_TEXT_VALIDATION: 'Processing your inputs ...\nThis can take up to 2 minutes.',
      PROGRESS_TEXT_MATCH: 'We are evaluating how your resume matches up to the job requirements.\nThis can take up to 30 seconds ...',
      PROGRESS_TEXT_CANCELED: 'Resume request canceled',
      MATCH_SUCCESS: 'Check out your job fit while we work on your tailored resume.\n\nThis can take up to a minute.\nThis might be a good time to get your coffee or get a stretch in.',
      MATCH_ONLY_SUCCESS: 'Your job fit and keyword analyses are ready for your review.',
      RESUME_GEN_PROGRESS_MESSAGE_LONG: 'Generating your tailored resume.\n\nThis can take a couple of minutes...\nThis might be a good time to get your coffee or get a stretch in.',
      RESUME_GEN_SUCCESS: 'Your tailored resume is now ready.\nClick on "Resume" or "View Tailored Resume" to view it.',
      EMPTY_STATE_MESSAGE_LINE1: 'Ready to launch your career?',
      EMPTY_STATE_MESSAGE_LINE2: 'Just provide the position you are interested in and your resume to get started',
      TOOLTIP_CLEAR: 'Clear All',
      TOOLTIP_EXPAND: 'Expand',
      TOOLTIP_COLLAPSE: 'Minimize',
      TOOLTIP_RESTART: 'New Application',
      TOOLTIP_SCROLL_TOP: 'Scroll to Top',
      INSTRUCTION_COPY_RESUME: 'Copy and paste your resume',
      BUTTON_LABEL_MATCH: 'Evaluate Keywords',
      BUTTON_LABEL_GENERATE: 'Generate Tailored Resume',
      BUTTON_LABEL_VIEW: 'View Tailored Resume',
      BUTTON_LABEL_VIEW_SHORT: 'View Resume',
      BUTTON_LABEL_JOB_INCOMPLETE: 'Provide Position Details To Continue',
      BUTTON_LABEL_RESUME_INCOMPLETE: 'Provide Resume To Continue',
      BUTTON_LABEL_ALL_COMPLETE: 'Done',
      BUTTON_LABEL_ONE_COMPLETE: 'Next',
      BUTTON_LABEL_CANCEL_APP: 'Cancel',
      BUTTON_LABEL_JOB_PREVIEW: 'Job Details',
      BUTTON_LABEL_JOB_MATCH: 'Resume Match',
      PAGE_INSTRUCTIONS_LINE_1:'We can create a perfectly tailored resume for the position you\'re interested in.\nJust provide your existing resume and the position or job description to get started.',
      CARD_HEADER_JOB: 'Position You Seek',
      CARD_HEADER_RESUME: 'Your Background',
      JOB_SPEC_INSTRUCTIONS: 'Enter the details of the position you are interested in',
      RESUME_SPEC_INSTRUCTIONS: 'Provide your latest resume',
      RESUME_FILE_INSTRUCTIONS: 'Upload a new resume (.pdf, .docx, .txt files accepted)',
      JOB_URL_INSTRUCTIONS: 'Provide the URL of the job posting',
      JOB_COPY_PASTE_INSTRUCTIONS: 'Type in the position and other job details',
      JOB_DESCRIPTION_INSTRUCTIONS: 'If you\'re applying to a specific job, you can enter the job description below for a perfectly tailored resume.',
      LABEL_JOB_URL: 'Job  URL',
      LABEL_POSITION: 'Position',
      LABEL_OTHER_DETAILS: 'Additional Details (Optional)',
      LABEL_COMPANY: 'Organization',
      LABEL_JOB_DESCRIPTION: 'Job Description',
      LABEL_RESUME: 'Resume',
      PLACE_HOLDER_JOB_URL: 'E.g. http://acme.com/jobs/123456790',
      PLACE_HOLDER_POSITION: 'E.g Senior Customer Support Specialist',
      PLACE_HOLDER_COMPANY: 'The organization you are applying to. E.g. Acme Corp',
      PLACE_HOLDER_JOB_DESCRIPTION: 'The detailed job description from the job posting',
      PLACE_HOLDER_RESUME: 'Copy and paste your text resume here.\nIf you do not have a resume, don\'t worry. We will generate one for you.\nJust provide your education and work history with dates. Try to provide as much details as possible.',
      LABEL_MATCH_SUMMARY_TITLE: 'Resume Evaluation',
      LABEL_INPUT_COMPLETE: 'Input',
      LABEL_MATCH_COMPLETE: 'Match Evaluation',
      LABEL_RESUME_COMPLETE: 'Tailored Resume',
      LABEL_MATCH_PROGRESS: 'Evaluating Match ...',
      LABEL_RESUME_PROGRESS: 'Customizing Your Resume ...',
      LABEL_MATCH_SCORE: 'Recruiter Selection Chances',
      LABEL_ATS_SCORE: 'Resume Selection Chances',
      LABEL_BOOST_SCORE: 'Impact Of Tailoring Resume',
      LABEL_SCORE_DETAILS: 'Score Details',
      LABEL_ATS_SCORE_DETAILS: 'Round 1: ATS Screening',
      LABEL_MATCH_SCORE_DETAILS: 'Round 2: Recruiter Screening',
      LABEL_KEYWORD_SCORE: 'Job Specific Keywords',
      LABEL_EDUCATION_SCORE: 'Educational Qualifications',
      LABEL_EXPERIENCE_SCORE: 'Work Experience',
      LABEL_HARD_SKILLS_SCORE: 'Hard Skills',
      LABEL_SOFT_SKILLS_SCORE: 'Soft Skills',
      LABEL_OTHER_REQUIREMENTS_SCORE: 'Other Requirements',
      LABEL_RESUME_READABILITY: 'Resume Readability',
      LABEL_JOB_REQUIREMENTS:'Job Requirements Match',
      LABEL_KEYWORDS:'Job Keywords Match',
      LABEL_MANDATORY_MATCH: 'Must Have Requirements',
      LABEL_OPTIONAL_MATCH: 'Nice-to-Have Requirements',
      LABEL_IMPACT_HIGH: 'High Impact',
      LABEL_IMPACT_MEDIUM: 'Medium Impact',
      LABEL_IMPACT_LOW: 'Low Impact',
      LABEL_DETAILS: 'Details →',
      QUESTION_SCORE_BREAKDOWN: 'How did you calculate my ATS and recruiter selection chances?',
      QUESTION_REQUIREMENTS_MATCH: 'How well does my resume match the job requirements?',
      QUESTION_KEYWORDS_MATCH: 'Which job-specific keywords are present in my resume?',
      MESSAGE_ATS_LOW:'There\'s a high chance an Applicant Tracking System (ATS) might reject your resume',
      MESSAGE_ATS_MEDIUM:'There\'s a moderate risk of an Applicant Tracking System (ATS) rejecting your resume',
      MESSAGE_ATS_HIGH:'Your resume has the necessary keywords and phrases to pass the Applicant Tracking System (ATS) screening',
      MESSAGE_RECRUITER_ROUND:'After clearing the ATS, your resume reaches human reviewers. Here\'s how recruiters or hiring managers might evaluate your application:',
      MESSAGE_ATS_ROUND:'An Applicant Tracking System (ATS) evaluates your resume by scanning its contents and comparing them to job-specific keywords. Here\'s how your resume scored on key dimensions:',
      MESSAGE_MATCH_LOW:'Based on your current profile, this job might not be the best fit for you',
      MESSAGE_MATCH_MEDIUM:'Your profile shows a moderate potential for this job',
      MESSAGE_MATCH_HIGH:'Your profile is a strong match for this job',
      MESSAGE_MATCH_V_HIGH:'Your profile is an excellent fit for this job',
      MESSAGE_HIGH_BOOST:'Tailoring your resume with job specific keywords and phrases can greatly improve your chances of getting past the ATS screening',
      MESSAGE_MEDIUM_BOOST:'Tailoring your resume with job specific keywords and phrases can further increase your chances of getting past the ATS screening',
      MESSAGE_LOW_BOOST:'Tailoring your resume with job specific keywords and phrases can slightly increase your chances of getting past the ATS screening',
      MESSAGE_NO_BOOST:'Tailoring your resume with job specific keywords and phrases wouldn\'t have much of an impact on your chances of getting past the ATS screening',
      MESSAGE_EVALUATION_SUMMARY:'We reviewed your resume against the job details you provided and found the following:',
      MESSAGE_SCORE_DETAILS:'When you apply for a job, your resume must clear two hurdles before you can expect an interview: first the ATS screening, then the recruiter screening. Understanding these stages can help you optimize your application.',
      MESSAGE_JOB_REQUIREMENTS:'Here are the job requirements and their relative importance to a recruiter. The ones found in your resume are indicated with a checkmark.',
      MESSAGE_KEYWORDS:'Here are the top keywords that an ATS would likely scan for in your resume. The ones found in your resume are highlighted in green.',
      // Other constants..
      MIN_CONTENT_LENGTH: 400,  //Min number of characters allowed for a text area input
      MAX_CONTENT_LENGTH: 29500,  //Max number of characters allowed for a text area input
      MAX_FILE_SIZE: 1024*1024, // max resume file size of 1 MB (also check the message FILE_MESSAGE_SIZE_ERROR:)
      KEYWORD_PRESENCE_THRESHOLD: 0.0005, //only if a keyword has a score >= this value, will it be considered to be present in the resume
    };
  },
  async created() {
    // Check if user is authenticated
    console.log('Home screen avigated from page: ' + this.previousRoute);
    const isAuthenticated = await this.checkAuthentication();
    if (!isAuthenticated) {
      // Redirect the user to the login screen if user isn't authenticated
      console.log('Home Screen - user not authenticated');
      this.$router.push({ name: 'Login', params: { from: this.$route.name }, query: { existing_user: true } }); // Return the user to Login screen for existing user (i.e. with existing_user=true)
    } 
    // Check if terms need to be checked
    let pendingTerms = false; 
    if (this.userDetails.check_terms) {
      pendingTerms = await this.checkPendingTerms();       // Check if there are pending terms
    }
    if (pendingTerms) {
      // Redirect the user to the Terms screen
      this.$router.push({ name: 'Terms' });
    } else {
      // get the application json from the Vue store
      this.application_json = this.getApplicationJSONByVersion(1); // the application from Vue store using version 1 as the default version
      // check if application id is present in the URL && a non-null application json is present in the vue store
      if (this.isPreviousApplication && this.application_json) {
        console.log('Input form: Application ID available in URL and application JSON available in Vue store');
        // Set the application id from the URL
        this.application_id = this.applicationIdFromURL;
        //Set current tab to Match preview
        this.current_tab = 'match_preview';
        // Update the progress bars to 100%
        this.match_progress = 100;
        this.resume_progress = 100;
        // Populate the input cards
        this.job_spec_option = 'copy_paste'; //default it to the copy_paste option
        this.job_title = this.application_json.position_applied;
        this.company_name = this.application_json.company_applied;
        this.job_description = this.application_json.job_description_applied;
        // if(this.application_json.resume_spec) { //If an application id is present
        //   this.resume_spec_option = 'last_application'; //set the spec to last_applied resume
        // } else {
        //   this.resume_spec_option = 'existing_resume'; //else set it to existing resume
        // }
        this.resume_spec_option = 'existing_resume'; //set default spec to existing resume
      } else { //if a valid application json isn't available in the vuew store for the given application id in the URL
        if (this.isPreviousApplication) this.$router.push({ name: 'Home' }); //update the URL to get rid of any query params if applicable
        console.log('Input Form: Application ID or JSON is\'t present. Starting new application..');
        // Start a new application for the given user. (No need to reload the screen as the URL correctly doesn't have any query params)
        this.$store.commit('deleteApplicationJSON'); //delete any existing applications from the Vue store
        this.application_json = null; //clear out the application JSON
        await this.createNewApplication();
        // Update the default resume spec selection based on user's previous activity
        // if (this.last_application.available) {
        //   this.resume_spec_option = 'last_application';
        // } else if (this.last_uploaded_resume.available) {
        //   this.resume_spec_option='existing_resume'; 
        // }
        if (this.last_uploaded_resume.available) {
          this.resume_spec_option='existing_resume'; 
        }
      }
      this.user_authenticated = true; //now we are ready to show the input screen
    }
  },
  methods: {
    handleButtonClick (id){
      console.log('Clicked button ID: '+id);
      if (id=='change_resume_file') this.resetFileSelector();
      else if (id==='toggle_resume_card') this.toggleResumeCard();
      else if (id==='toggle_job_card') this.toggleJobCard();
      else if (id==='toggle_job_description') this.toggleJobDescription();
      else if (id==='reset_job_inputs') this.clearJobFields(true);
      else if (id==='reset_resume_inputs') this.clearResumeFields();
      else if (id==='generate_match') this.generateApplication(true); //this.getMatch(); //
      else if (id==='generate_application') this.generateApplication(); //this.getMatch(); //
      else if (id==='cancel_application') this.cancelRequest();
      else if (id==='job_preview' || id=='match_preview') this.changePreviewTab(id);
      else if (id==='resume_complete') this.handleResumeCardNavigation();
      else if (id==='job_complete') this.handleJobCardNavigation();
      else if (id==='resume') this.saveAndNavigate('Resume');
      else if (id==='cover_letter') this.saveAndNavigate('Cover Letter');
      else if (id==='scroll_to_top') this.scrollToEvaluationSummary();
      else if (id==='restart') this.startNewApplication();
    },
    async checkAuthentication() {
      console.log('inside check authentication: ');
      try {
        //call the #authenticate_user action in the LoginController class in the backend
        //without the auth token to validate whether the user is logged in
        const response = await axios.get('/api/v1/auth/next');
        //check whether the 'next' attribute in the server's response is true
        console.log('Authentication validation response: '+response.data.next);
        if (response.data.next) {
          //If server returns next = true i.e. user is authenticated,
          // then set the user details into the VueX store using the API response
          const user_details = response.data.user_details;
          console.log('User details received from server: '+JSON.stringify(user_details));
          this.$store.commit('setUserDetails', { userDetails: user_details });
        }
        console.log('User details object after setting userID in check authentication: '+JSON.stringify(this.userDetails));
        return response.data.next;
      } catch (error) {
        console.error(error);
        return false;
      }     
    },
    async checkPendingTerms() {
      console.log('inside pending terms, user details object: '+JSON.stringify(this.userDetails));
      // Check if there are any pending terms in the Vue store
      if ( this.userDetails.hasOwnProperty('pending_terms') && this.userDetails.pending_terms !== undefined) {
        console.log('Pending Terms key found with value = '+this.userDetails.pending_terms);
        return  this.userDetails.pending_terms; //if pending terms key is available, return it
      }
      // Else check server for pending terms
      try {
        console.log('Checking if there are pending terms from server ...');
        let pending_terms = false;
        const response = await axios.get(`/api/v1/users/${this.userDetails.user_id}/get_pending_terms`);
        console.log('Pending terms?'+response.data.pending_terms);
        if (response.data.hasOwnProperty('pending_terms')) {
          // update the terms intoo the VueX store
          pending_terms = response.data.pending_terms;
          const user_details = {
            pending_terms: response.data.pending_terms,
            terms_id: response.data.terms_id,
            terms_content: response.data.terms_content,
            new_user_terms_summary: response.data.new_user_terms_summary,
            existing_user_terms_summary: response.data.existing_user_terms_summary,
          };
          console.log('Updating pending terms in Vue store');
          this.$store.commit('updateUserDetails', { userDetails: user_details });
          return pending_terms;
        } else {
          console.log('Server did not return pending terms key');
          return false;
        }
      } catch (error) {
          console.log('Error checking pending terms from server'+error);
          return false;
      }     
    },
    async saveJobDetailsFromURL() {
      //Extract job description from Job URL and save it to db
      this.job_url_error_message = ''; //reset the validation message
      if (this.job_url_input) { //if a job url input is available
        //Reset the job details
        this.clearJobFields(false); //clear all job fields except job_url_input
        if (this.isValidURL(this.job_url_input)) { //if the URL is a valid URL
          this.fetching_job = true; //change the status to true when API call is being made
          try {
            const response = await axios.post(`/api/v1/user_applications/${this.application_id}/update_job_details`, { job_url: this.job_url_input });
            if (response.status === 202) {
              // If the job was successfuly queued
              const job_id = response.data.job_id;
              console.log("Job details extraction queued with id: "+job_id);
              const job_details = await this.pollAndGetJobDetails(job_id); //Get the job job details
              if (job_details.status === 'failed') {
                // If the job details could not be extracted, show the error message
                console.log("Could not extract the job details: "+job_details.message);
                this.job_url_error_message = job_details.message;
                this.showToast('Warning', job_details.message);
              } else if (job_details.status === 'completed') {
                console.log("Job details successfully extracted");
                if (job_details.result.job_site == 'Unknown') {
                  this.job_url_error_message = this.JOB_MESSAGE_DEFAULT_ERROR_SHORT;
                  this.showToast('Warning', this.JOB_MESSAGE_UNKNOWN_ATS + this.JOB_MESSAGE_UNSUPPORTED_ATS);
                } else if (job_details.result.job_site == 'Indeed') {
                  this.job_url_error_message = this.JOB_MESSAGE_DEFAULT_ERROR_SHORT;
                  this.showToast('Warning', job_details.message);
                } else if (job_details.result.job_status == 'Inactive') {
                  this.job_url_error_message = this.JOB_MESSAGE_INACTIVE_JOB_SHORT;
                  this.showToast('Warning', this.JOB_MESSAGE_INACTIVE_JOB);
                } else {
                  // Update the application data based on the API response
                  this.company_name = job_details.result.company;
                  this.job_title = job_details.result.job_title;
                  this.job_location = job_details.result.location;
                  this.job_description = job_details.result.job_description;
                  this.job_site = job_details.result.job_site;
                  this.showToast('Success', this.JOB_MESSAGE_VALID_URL);
                }

              }
            } else {
              // If the job was not successfuly queued
              this.job_url_error_message = this.JOB_MESSAGE_DEFAULT_ERROR_SHORT;
              this.showToast('Warning', this.JOB_MESSAGE_DEFAULT_ERROR_LONG);
            }
          } catch (error) {
            console.error('Error getting job details from URL: '+error.message);
            if (error.response && error.response.status === 404) {
              this.job_url_error_message = this.JOB_MESSAGE_DEFAULT_ERROR_SHORT;
              this.showToast('Warning', this.JOB_MESSAGE_NOT_FOUND);
            } else if (error.response && error.response.status === 410) {
              this.job_url_error_message = this.JOB_MESSAGE_DEFAULT_ERROR_SHORT;
              this.showToast('Warning', this.JOB_MESSAGE_INACTIVE_JOB);
            } else if (error.response && error.response.status === 403) {
              this.job_url_error_message = this.JOB_MESSAGE_DEFAULT_ERROR_SHORT;
              const displayMessage = this.JOB_MESSAGE_FORBIDDEN;
              this.showToast('Warning', displayMessage);
            } else {
              this.job_url_error_message = this.JOB_MESSAGE_DEFAULT_ERROR_SHORT;
              this.showToast('Warning', this.JOB_MESSAGE_DEFAULT_ERROR_LONG);
            }  
          } finally {
            this.fetching_job = false; //change the status to false when API call has finished
          }
        } else {
          this.job_url_error_message = this.JOB_MESSAGE_INVALID_URL;
        }
      }
    },
    // Clear all job inputs, if true; clear all inputs except URL input if false
    clearJobFields (clearURLInput) {
      if (clearURLInput) this.job_url_input = ''; 
      this.company_name = '';
      this.job_title = '';
      this.job_location = '';
      this.job_description = '';
      this.job_site = ''; 
      //also reset the progress bars
      this.match_progress=0;
      this.resume_progress=0;
      //also clear match and keywords
      if (this.application_json) {
        this.application_json.match={};
        this.application_json.keywords={};
        this.application_json.resume={};
      }
    },
     // Clear all resume inputs
    clearResumeFields () {
      this.resetFileSelector();
      this.text_resume = '';
      this.text_error_message = ''; //Also reset the error message
    },
    isValidURL(str) {
      // Returns true if the provided string is a valid URL; false otherwise
      try {
        new URL(str); //try creating a URL object using the input string
          return true; //if it successfully creates a URL, return true
      } catch (error) {
        return false;
      }
    },
    handleFileChange(event) {
      // Every time a user changes their file selection validate the file and set the error message accordingly
      console.log('Inside Handle File Change');
      this.resume_file_error_message = '';

      this.selected_file = event.target.files[0];
      console.log('Selected file: '+this.selected_file.name);
      if (!this.isValidFileType(this.selected_file)) {
        // Validate file type provided
        this.resume_file_error_message = this.FILE_MESSAGE_TYPE_ERROR;
        this.resetFileSelector();
      }
      else if (!this.isValidFileSize(this.selected_file)) {
        // validate file size
        this.resume_file_error_message = this.FILE_MESSAGE_SIZE_ERROR;
        this.resetFileSelector();
      }
      else {
        // If all file validations pass
        this.uploadResumeFile();
      }
    },

    isValidFileType(file) {
      // Validate that the selected file type is supported
      const valid_extensions = ['.pdf', '.txt', '.doc', '.docx'];
      const file_extension = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
      console.log('Inside is valid file type: '+ valid_extensions.includes(file_extension));
      return valid_extensions.includes(file_extension);
    },
    //Validate that the selected file is within the max file size limit
    isValidFileSize(file) {
      return file.size <= this.MAX_FILE_SIZE ; 
    },
    //Reset the file selector
    resetFileSelector() {
      console.log('Inside reset file selector');
      this.selected_file=null;
    },
    // Upload the selected resume file to server
    async uploadResumeFile() {
      console.log('Inside upload resume');
      this.resume_upload_successful = false; // Reset the upload status

      if (this.selected_file && this.isValidFileType(this.selected_file) && this.isValidFileSize(this.selected_file)) {
        console.log("File selected and matches file type and size criteria");

        const form_data = new FormData();
        form_data.append('resume', this.selected_file);
        form_data.append('uid', this.userDetails.user_id); // Send the user_id via the uid parameter
        form_data.append('application_id', this.application_id); // Send the application_id parameter
        
        this.resume_uploading = true;
        let toastId = this.showToast('Wait', this.RESUME_UPLOAD_PROGRESS);

        try {
          // Make the API call using Axios
          const response = await axios.post('/api/v1/upload_resume', form_data);
          // If file is successfully uploaded to server
          if (response.status === 200) {
            this.fileUploadSuccessActions(toastId);
          } // If file upload was queued
          else if (response.status === 202) { 
            this.removeToast(toastId); //remove the initial wait toast message
            toastId = this.showToast('Wait', response.data.message); //show a new wait toast message with updated info
            const file_upload_job_id = response.data.job_id;
            console.log("Resume upload job id: "+file_upload_job_id);
            const upload_result = await this.pollAndConfirmResumeUpload(file_upload_job_id); //Check whether the resume gen request has completed
            if (upload_result.status === 'completed'){ // If queued job succeeded
              this.fileUploadSuccessActions(toastId, upload_result.message);
            } else  { //If the queued job failed to save the resume
              console.error('Error uploading resume: '+upload_result.message);
              this.removeToast(toastId);
              if (upload_result.message) {
                this.showToast('Error', upload_result.message);
              } else {
                this.showToast('Error', this.ERROR_MESSAGE_RESUME_FILE);
              }
              this.resetFileSelector();
            }
          }
          else {
            // If the API call returns an error
            console.error('Error uploading resume:', response.statusText);
            this.removeToast(toastId);
            this.showToast('Error', response.data.message);
            this.resetFileSelector();
          }
        } catch (error) {
          console.error('Error uploading resume:', error.message);
          this.removeToast(toastId);
          this.resetFileSelector();
          if (error.response && error.response.data && error.response.data.message) {
            // Show the error message from the server
            this.showToast('Error', error.response.data.message);
          } else {
            // Show a generic error message
            this.showToast('Error', this.ERROR_MESSAGE_RESUME_FILE);
          }
        } finally {
          this.resume_uploading = false;
        }
      } else {
        this.resume_file_error_message = this.FILE_MESSAGE_DEFAULT_ERROR;
        console.log('Does not match file size or type criteria');
      }
    },
    //Actions to undertake when a resume file has been successfully uploaded
    fileUploadSuccessActions(toastId, message){
      // Reset the selected_file and clear error message on success
      this.resume_file_error_message = '';
      this.resume_upload_successful = true;
      console.log('File uploaded successfully');
      this.removeToast(toastId);
      if (message) { 
        this.showToast('Success', message); 
      } else {
        this.showToast('Success', this.RESUME_UPLOAD_SUCCESS); 
      }

      // Update the last uploaded file selector to reflect the new values
      this.last_uploaded_resume.available = true;
      this.last_uploaded_resume.file_name = this.selected_file.name;
      this.last_uploaded_resume.timestamp = Date.now();
    },
    // Start the match, resume and cover letter generation process
    async generateApplication (match_only_job=false) {
      const applicationValidated = await this.validateApplication();
      this.cancel_application = false; // reset the cancel token
      if (applicationValidated) {
        // If application is valid, generate match and resume
        this.$store.commit('updateUserApplicationHistory', { hasGeneratedApplication: true }); //remember that the user has generated their resume at least once
        this.resetAllErrorMessages(); //reset error messages

        let match_gen_complete = false; // whether match score has been generated
        let resume_gen_complete = false; // whether resume has been generated
        let job_id_json; // the JSON object containing all the job ids

        const resume_only_job = this.showMatchPreview; // if match preview is already available, then we only need to generate the resume; otherwise, we need to generate the full application
        
        // Start progress animation
        if (resume_only_job) {
          this.startResumeAnimation();
        } else {
          this.startMatchAnimation(match_only_job);
        }

        job_id_json =  await this.queueApplicationJobs(match_only_job, resume_only_job); //enqueue the application generation job
        console.log('After queueing jobs: match in progress? '+this.match_in_progress+' resume in progress? '+this.resume_in_progress);
        if (job_id_json) { //if the application generation was successfully quueud
          if (!resume_only_job) { //i.e. user is generating application from scratch
            console.log('Generating full application');
            this.application_json = {}; //reset/ initialize the application JSON
            this.match_in_progress = true; //indicator that match gen in progress
            this.match_progress_toast = this.showToast('Wait', this.PROGRESS_TEXT_MATCH); //the match gen toast message id



          } else { //i.e. user is just re-generating the resume
            console.log('Generating only resume');
            this.resume_in_progress = true; //indicator that resume gen in progress
            match_gen_complete = true;
            this.match_progress_toast = this.showToast('Wait',this.RESUME_GEN_PROGRESS_MESSAGE_LONG); //Set the match progress toast id for the spinner/wait toast
          }
          // Show messages to the user based on the status of resume and match generation
          if (!match_gen_complete) {
            console.log('Match hasn\'t been generated yet..');
            match_gen_complete = await this.pollAndGetMatch(job_id_json.match_job_id, match_only_job); //Check whether the match gen request has completed
          }
          if (!match_only_job) {
            // Message 1: Match Complete
            if (match_gen_complete && this.hasMandatoryRequirements && !resume_gen_complete) { //match has completed but resume still in progress
              this.startResumeAnimation(); //and start the resume progress animation
            }
            resume_gen_complete = await this.pollAndGetResume(job_id_json.resume_job_id); //Check whether the resume gen request has completed
          }
  
          // Generate Cover Letter Complete
          // if (match_gen_complete && resume_gen_complete && this.generatedResumeAvailable) {
          //   //When all requests have completed
          //   // if (!resume_only_job) {
          //   //   this.getCoverLetter(job_id_json.cover_letter_job_id);
          //   // }
          //   //Additionally, if resume has been successfully generated, redirect user to the resume screen
          //   // if (!this.resume_generation_error) await this.saveAndNavigate();
          // }
        } else { //i.e. there was an error queueing the application generation jobs
            console.error('Error queueing application generation jobs');
            console.log('Match in progress: '+this.match_in_progress);
            console.log('Resume in progress: '+this.resume_in_progress);
            console.log('Generated application available: '+this.generatedResumeAvailable);
            this.match_in_progress = false;
            this.resume_in_progress = false;
            this.showToast('Warning', this.ERROR_MESSAGE_DEFAULT);
            this.resume_generation_error=true;
            if (resume_only_job) {
              this.stopResumeAnimation();
            } else {
              this.stopMatchAnimation();
            }
        }
      }
    },
    //This method is for testing match score
    async getMatch() {
      const test_application_id = '47bdcda3-c969-4b5d-90a6-d091150a2e22';
      try {
        const api_response = await axios.get(`/api/v1/user_applications/${test_application_id}/generate_match_and_keywords`);
        this.application_json = {
          ...this.application_json, // Keep existing keys in case resume or cover letter were generated before match (unlikely..)
          match: api_response.data.match, // Add/ overwrite new key-value pair
          keywords: api_response.data.keywords,
        };
        this.$store.commit('setApplicationJSON', { version: 1, response: this.application_json });
        this.changePreviewTab('match_preview'); // show the match tab with the new match content
      } catch(error) {
        console.error('Error getting match:', error.message);
      }
    },
   //Check whether the entered job and resume are valid
    async validateApplication() {
      let application_valid = false; // whether the application is valid
      // Validation necessary only if a user manually entered the job description or resume and a match hasn't already been generated
      if ( !this.showMatchPreview && (this.resume_spec_option == 'manual_entry' || this.job_spec_option == 'copy_paste')) {
        this.match_in_progress = true; // initialize the match progress
        console.log('Job or resume validation necessary');
        console.log('Job spec: '+this.job_spec_option);
        console.log('Resume spec: '+this.resume_spec_option);

        this.resetAllErrorMessages(); //reset error messages
        const toastId = this.showToast('Wait', this.PROGRESS_TEXT_VALIDATION);
        try {
          const params = { //conditionally add the URL params to the API request
            ...({ job_spec: this.job_spec_option }),
            ...({ resume_spec: this.resume_spec_option }),
            ...(this.job_spec_option == 'copy_paste' && this.position_applied!==''? { position_applied: this.job_title } : {}),
            ...(this.job_spec_option == 'copy_paste' && this.company_name !== '' ? { company_applied: this.company_name } : {}),
            ...(this.job_spec_option == 'copy_paste' && this.job_description !== '' ? { job_description_applied: this.job_description } : {}),
            ...(this.resume_spec_option=='manual_entry' && this.text_resume !== '' ? { resume_string: this.text_resume } : {}),
          };

          const response = await axios.post(`/api/v1/user_applications/${this.application_id}/validate_application`, params);
          
          // If server sends an immediate validation response
          if (response.status === 200) {
            this.removeToast(toastId);
            if (!response.data.is_valid_job) {
              // Job doesn't meet criteria
              this.showToast('Error', this.ERROR_MESSAGE_JOB);
            } else if (!response.data.is_valid_resume) {
              // Resume description doesn't meet criteria
              this.showToast('Error', this.ERROR_MESSAGE_RESUME);
            } else {
              // If application seems valid
              this.showToast('Success', this.JOB_RESUME_VALIDATION_MESSAGE);
              application_valid
            }
          } // If validation was queued
          else if (response.status === 202) { 
            const validation_job_id = response.data.job_id;
            console.log("Validation job id: "+validation_job_id);
            const validation_result = await this.pollAndValidateApplication(validation_job_id); //Check whether the resume gen request has completed
            this.removeToast(toastId); //remove the initial wait toast message

            if (validation_result.status === 'completed'){ // If inputs are valid
              // Show a success message
              this.showToast('Success', this.JOB_RESUME_VALIDATION_MESSAGE);
              application_valid = true;
            } else  { //If the validation job failed
              console.error('Input validation failed: '+validation_result.message);
              if (validation_result.message) {
                this.showToast('Error', validation_result.message);
              } else {
                this.showToast('Error', this.ERROR_TERMS_OF_SERVICE);
              }
            }
          }
          else { //If API call returns an error
              console.error('Validation Error:', response.statusText);
              this.showToast('Warning', this.ERROR_MESSAGE_DEFAULT);
          }  

        } catch (error) {
          console.log('Validation error:'+error.message);
          this.removeToast(toastId);
          if (error.message ==='Missing application id') {
            //If application id wasn't found, it means that the user is working on an old ( more than 7 days old) unfinished application that was purged on the server side
            this.showToast('Warning', this.ERROR_MESSAGE_RELOAD);
          } else {
            //Show a retry message
            this.showToast('Warning', this.ERROR_MESSAGE_DEFAULT);
          }
        } 
      } else {
        console.log('No validation necessary');
        application_valid = true;
      }
      if (!application_valid) this.match_in_progress = false; //reset the match progress if application validation failed
      return application_valid;
    },
    // Enqueue application generation jobs and return the respective job ids
    async queueApplicationJobs(matchOnly=false, resumeOnly=false) {
      console.log('Inside Queue Application Generation Job for resume only? '+resumeOnly);
      const requestParams = {}; // list of request params to include in the POST call
      if (this.resume_spec_option === 'last_application') { // if resume from last application is used
        requestParams.last_application = this.last_application.id; //set the last_application request param to use the application id of the last app
      }
      // Determine which API endpoint to request
      let url_string;
      if (matchOnly) {
        url_string = `/api/v1/user_applications/${this.application_id}/generate_match_and_keywords`;
      } else if (resumeOnly) {
        url_string = `/api/v1/user_applications/${this.application_id}/tailor_resume`;
      } else { //i.e. if it's the full application
        url_string = `/api/v1/user_applications/${this.application_id}/generate_application`;
      }
      try {
        // enqueue the resume generation job and get the job id from the backend
        const response = await axios.post(url_string, requestParams );
        if (response.status === 202) { //if job was successfully queuued
          console.log('Application generation job queued successfully');
          if (resumeOnly) { // return the resume job id
            return { resume_job_id: response.data.job_id};
          } else { // return the full response object with all the job ids
            return response.data; 
          }
        } else { //If API call returns an error
          console.log('Application generation job enqueuing failed:', response.statusText);
          return null;
        }   
      } catch (error) {    
          console.log('Error submitting application generation job: '+error.message);
          this.match_in_progress = false;
          return null;
      }
    },
    // Poll the backend server to check if job details extraction job is complete
    async pollAndGetJobDetails(job_id) {
      console.log( 'Inside Poll and Get Job Details: '+job_id);
      const job_type = 'job_details';
      const poll_interval = 1500; //poll every 1.5 second
      const max_poll_attempts = 22; //poll a max of 16 times (i.e. 30 seconds)
      const result = await this.jobSuccessful(job_id, job_type, max_poll_attempts, poll_interval);
      return result;
    },
    // Poll the backend server to check if upload job is complete
    // Return true if successful, false if failed
    // Max duration: 60 seconds
    async pollAndConfirmResumeUpload(job_id) {
      console.log( 'Inside Poll and Confirm Resume Upload: '+job_id);
      const job_type = 'upload';
      const poll_interval = 5000; //poll every 5 seconds
      const max_poll_attempts = 13; //poll a max of 13 times
      const result = await this.jobSuccessful(job_id, job_type, max_poll_attempts, poll_interval);
      return result;
    },
    /**
     * Poll the backend server to check if job id completed successfully
     * Timeout in 60 seconds
     * @param {string} job_id - Unique identifier for the job
     * @returns {Promise.<Object>}
     *  - status: 'completed'|'failed',
     *  - result: result of job if status is 'completed' or null otherwise
     *  - message: error message if status is 'failed' or null otherwise
     */
    async pollAndValidateApplication(job_id) {
      console.log( 'Inside Poll and Validate Application: '+job_id);
      const job_type = 'validate';
      const poll_interval = 3000; //poll every 3 seconds
      const max_poll_attempts = 21; //poll a max of 21 times (=60 seconds)
      const result = await this.jobSuccessful(job_id, job_type, max_poll_attempts, poll_interval);
      return result;
    },
    // Poll the backend server to check if match generation job is complete
    // Get  match and keywords once available
    // Max duration: 36 seconds
    async pollAndGetMatch(job_id, match_only_job) {
      console.log( 'Inside Poll and Get Match for job id: '+job_id);
      const job_type = 'match';
      const poll_interval = 3000; //poll every 4 seconds
      const max_poll_attempts = 13; //poll a max of 13 times
      const match_response = await this.jobSuccessful(job_id,job_type, max_poll_attempts, poll_interval);

      if(!this.cancel_application && match_response.status === 'completed') {
        console.log('Match returned by server:\n'+JSON.stringify(match_response.result));
        this.application_json = {
          ...this.application_json, // Keep existing keys in case resume or cover letter were generated before match (unlikely..)
          match: match_response.result.match, // Add/ overwrite new key-value pair
          keywords: match_response.result.keywords,
        };
        // Overwrite the Vue store with the new application_json
        this.$store.commit('setApplicationJSON', { version: 1, response: this.application_json });
        this.removeMatchProgressToast();
        this.stopMatchAnimation(); //stop the resume progress animation
        this.match_progress=100; //set completion status to 100%
        this.changePreviewTab('match_preview'); // show the match tab with the new match content
        if (!match_only_job) {
          this.resume_in_progress = true; //indicator that resume gen in progress
          this.match_progress_toast= this.showToast('Wait', this.MATCH_SUCCESS); // show a message informing the user that their match score is ready while we prepare their resume
        } else {
          this.showToast('Success', this.MATCH_ONLY_SUCCESS); //show a message that match score is ready
        }
      } else {
        console.error('LLM did not generate a valid match');
        if (!this.resume_generation_error) { //If an error message hasn't already been shown, for example when the match service failed
          this.removeMatchProgressToast(); //remove the match toast
          this.resume_generation_error=true;
          // this.show_progress_animation = false;
          this.stopMatchAnimation(); //stop the match animation
          this.match_progress = 0; //and reset the match progres
          this.stopResumeAnimation(); //stop the resume animation
          this.resume_progress = 0; //and reset the resume progres
          if (!this.cancel_application) {
            this.showToast('Warning', this.ERROR_MESSAGE_DEFAULT); //Show a retry message
          }
        } 
      } 
      this.match_in_progress=false;
      console.log('End of poll and get match: match_in_progress: '+this.match_in_progress+' resume_in_progress: '+this.resume_in_progress);
      return true; //to indicate that polling the server for resume has completed
    },
    //Poll the backend server to check if resume generation job is complete
    // Get resume once available
    // Max duration: 110 seconds
    async pollAndGetResume(job_id) {
      console.log( 'Inside Poll and Get Resume for job id: '+job_id);
      const job_type = 'resume';
      const poll_interval = 10000; //poll every 10 seconds
      const max_poll_attempts = 12; //poll a max of 12 times
      const resume_response = await this.jobSuccessful(job_id,job_type, max_poll_attempts, poll_interval);

      if(!this.cancel_application && resume_response.status === 'completed') {
        console.log('Resume returned by server..');
        this.application_json = {
          ...this.application_json, // Keep existing keys
          resume: resume_response.result, // Add new key-value pair
        };
        // save resume to Vue store
        this.$store.commit('updateResume', {
          version: 1, 
          resume: resume_response.result,
        });
        this.$store.commit('updateViewedRecommendations', false); // set recommendaitons viewed to false
        this.removeMatchProgressToast(); //remove the match progress toast and show the resume progress toast
        this.showToast('Success', this.RESUME_GEN_SUCCESS);
        this.stopResumeAnimation(); //stop the resume progress animation
        this.resume_progress=100; //set completion status to 100%
      } else {
        if (!this.resume_generation_error) { //If an error message hasn't already been shown, for example when the match service failed
          this.removeMatchProgressToast(); //remove the match toast
          this.resume_generation_error=true;
          // this.show_progress_animation = false;
          this.stopResumeAnimation(); //stop the resume progress animation
          this.resume_progress=0; //reset the resume progress
          if (!this.cancel_application) {
            this.showToast('Warning', this.ERROR_MESSAGE_DEFAULT); //Show a retry message
          }
        } 
        console.error('LLM did not generate a valid resume');
        console.log('Match in progress: '+this.match_in_progress);
        console.log('Resume in progress: '+this.resume_in_progress);
        console.log('Generated application available: '+this.generatedResumeAvailable);
      }
      this.match_in_progress=false;
      this.resume_in_progress=false;
      return true; //to indicate that polling the server for resume has completed
    },
    // Get the cover letter using the job id
    async getCoverLetter(job_id) {
      console.log( 'Inside Get Cover Letter for job id: '+job_id);
      try{
        const statusResponse = await axios.get(`/api/v1/user_applications/${this.application_id}/job_status?job_id=${job_id}`);
        const jobStatus = statusResponse.data.status;

        if (jobStatus === 'completed') {
          console.log('Cover letter available. Storing it into Vue store..\n');  // Job complete
          this.application_json.cover_letter=statusResponse.data.result;
        } else {
          console.log('Cover letter job has not completed yet. Status: '+jobStatus)
        }
      } catch (error) {
          console.log('Error checking cover letter job status: ' + error);
      }
    },
    // Poll the backend to check the status of a job
    // Return the status and actual result if job has completed/ failed
    async jobSuccessful(job_id, job_type, max_poll_attempts, poll_interval) {
      console.log('Checking success status for job type: '+job_type+', id: ' + job_id);
      if (!job_id || this.cancel_application) return { status: 'failed', message: this.ERROR_MESSAGE_DEFAULT };

      let poll_attempts = 0;

      return new Promise((resolve, reject) => {
        const pollStatus = async () => {
          try {
            // check if the task was canceled by the user
            if ( (job_type ==='resume' && !this.resume_in_progress) || (job_type ==='match' && !this.match_in_progress) ){
              resolve({ status: 'failed', message: this.ERROR_JOB_CANCELED_BY_USER });
              return;
            } 

            const statusResponse = await axios.get(`/api/v1/user_applications/${this.application_id}/job_status?job_id=${job_id}`);
            const jobStatus = statusResponse.data.status;

            if (jobStatus === 'completed') {
              // Job succeeded
              console.log('Job id: ' + job_id + ' successfully completed, result: ' + statusResponse.data.result);
              resolve({ status: 'completed', result: statusResponse.data.result, message: statusResponse.data.message });
            } else if (jobStatus === 'working' || jobStatus === 'queued' || !jobStatus) {
              // Job still in progress, continue polling
              console.log('Job id: ' + job_id + ' is still in progress..will poll again in a few...');
              poll_attempts++;

              if (poll_attempts < max_poll_attempts) {
                setTimeout(pollStatus, poll_interval);
              } else {
                // Max poll attempts reached
                console.log('Job id: ' + job_id + ' Hit max poll attempts. Quitting ...');
                if (job_type === 'job_details') {
                  resolve({ status: 'failed', message: JOB_MESSAGE_DEFAULT_ERROR_LONG });
                } else {
                  resolve({ status: 'failed', message: this.ERROR_MESSAGE_DEFAULT });
                }
              }
            } else {
              // Job failed
              console.log('Job id: ' + job_id + ' failed with status: ' + jobStatus + ' , Message:' + statusResponse.data.result);
              resolve({ status: 'failed', message: statusResponse.data.message });
            }
          } catch (error) {
            console.error('Job id: ' + job_id + ' failed with error: ' + error.message);
            reject({ status: 'failed', message: error.message });
          }
        };
        // Start polling
        pollStatus();
      });
    },
    async createNewApplication() {
      //Start a new job application and get the application id
      console.log('Inside Create New Application');
      try {
        const response = await axios.post('/api/v1/user_applications/start_new_application', {
          user_id: this.userDetails.user_id,
        },);
        console.log('Job Application Creatiion Status: ' + response.status + '\nApplication ID= ' + response.data.application_id);
        this.application_id= response.data.application_id; //get the application id
        if (response.data.resume_previously_uploaded) { //get the last uploaded resume details
          this.last_uploaded_resume.available=true;
          this.last_uploaded_resume.file_name=response.data.last_resume_file_name;
          this.last_uploaded_resume.timestamp = response.data.last_resume_uploaded_at;
          // Get last application details
          if (response.data.last_application?.id && response.data.last_application?.position) {
            // Set the last application details
            this.last_application.available = true;
            this.last_application.id = response.data.last_application.id;
            this.last_application.position= response.data.last_application.position;
            this.last_application.company= response.data.last_application.company;
            this.last_application.time= response.data.last_application.updated_at;
            // Set user's resume preferences based on resume_preferences from the last application stored in the server
            if (response.data.resume_preferences) {
              this.$store.commit('setResumePreferences', { config: response.data.resume_preferences });
            } else {
              console.log('No previously saved resume preferences available in the server');
            }
          }
        } else {
          this.last_uploaded_resume.available=false;
        }
        // Update user details in Vue store
        this.$store.commit('updateUserDetails', { 
          userDetails: {
            user_first_name: response.data.first_name,
            user_last_name: response.data.last_name,
            existing_user: response.data.existing_user,
          },
         });

      } catch (error) {
        // In case there's an error making any of the API calls
        console.log('Error creating a job application:', error);
        // Return the user to Login screen for existing user (i.e. with existing_user=true)
        this.$router.push({ name: 'Login', params: { from: this.$route.name }, query: { existing_user: true } }); 
      }
    },
    formatTime(timeString){
      //Format a given timestring to YYYY-MM-DD HH:MM
      const date = new Date(timeString);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      return date.toLocaleString(undefined, options);
    },
    validateResumeText() {
      //Check whether the manually entered resume meets the length and alphabet criteria
      console.log('Inside validate resume text');
      if(this.text_resume.length  <this.MIN_CONTENT_LENGTH ){  //Check that the entered resume text has at least 400 characters
        this.text_error_message= this.RESUME_TEXT_MIN_LENGTH_ERROR;
        this.is_valid_resume_text= false;
      } else  if( this.text_resume.length > this.MAX_CONTENT_LENGTH){  //Check that the entered resume text is not too long
        this.text_error_message= this.RESUME_TEXT_MAX_LENGTH_ERROR;
        this.is_valid_resume_text= false;
      }else {
        //Check whether at least 75% of the characters are English Alphabets
        const alphabeticChars = this.text_resume.replace(/[^a-zA-Z]/g, ''); // Remove non-alphabetic characters
        let resumeContent = this.text_resume.replace(/\n{2,}/g, '\n'); //Replace consecuitive occurrences of new line characters
        resumeContent =resumeContent.replace(/\s{2,}/g, ' ');  //Replace consecuitive occurrences of white space characters
        resumeContent = resumeContent.replace(/\r{2,}/g, ' '); //Replace consecuitive occurrences of carriage return characters

        const score = (alphabeticChars.length / resumeContent.length) * 100;
        if (score <75) {
          this.text_error_message=this.RESUME_TEXT_ALPHA_ERROR;
          this.is_valid_resume_text = false;
        } else {
          this.is_valid_resume_text = true;
          this.text_error_message=''; //reset the error message
        }
      }
    },
    //Cancel the match and resume gen requests/ API calls
    cancelRequest() {
      this.match_in_progress = false;
      this.resume_in_progress = false;
      this.cancel_application = true;
      this.removeMatchProgressToast();
      this.showToast('Warning', this.PROGRESS_TEXT_CANCELED);
      //stop progress animation
      if (this.hasMandatoryRequirements) { //If match has already been generated
        //stop and reset resume animation
        console.log("Match is available..resetting resume progress");
        this.stopResumeAnimation();
        this.resume_progress = 0; 
      } else {
        //stop and reset both animation
        this.stopMatchAnimation();
        this.match_progress = 0; 
      }
    },
    resetAllErrorMessages() {
      //Reset all job and resume input error messages
      this.job_manual_entry_error_message = '';
      this.job_url_error_message = '';
      this.resume_file_error_message = '';
      this.text_error_message = '';
      this.resume_generation_error = false;
    },
    resetJobManualEntryErrorMessage()  {
      this.job_manual_entry_error_message = '';
    },
    showToast(toastType, toastMessage) {
      const toastId = Date.now(); // Use a unique identifier for each toast
      const newToast = {
        id: toastId,
        type: toastType,
        message: toastMessage,
        visible: true,
      };
      eventBus.emit(ToastEvents.ADDED, newToast); //publish an add toast event to the event bus so that App.vue is notified
      return toastId;
    },
    removeToast(toastId) {
      if (toastId && toastId !='') {
        eventBus.emit(ToastEvents.REMOVED, toastId); //publish a remove toast event to the event bus so that App.vue is notified
      }
    },
    //Remove the match progress toast if still visible and reset the toast id
    removeMatchProgressToast() {
      if (this.match_in_progress !=='') {
          this.removeToast(this.match_progress_toast); //remove the match progress toast, if still visible
          this.match_progress_toast = null;
        }
    },
    // Expand/ collapse the job spec card
    toggleJobCard() {
      this.job_spec_card_expanded = !this.job_spec_card_expanded;
      if (this.job_spec_card_expanded) { //If job spec card is exapnded
        this.resume_spec_card_expanded = false; //collapse the resume spec card
      }
    },
    // Expand/ collapse job description details inside the job spec card
    toggleJobDescription() {
      this.job_description_expanded = !this.job_description_expanded
    },
    // Expand/ collapse the resume spec card
    toggleResumeCard() {
      this.resume_spec_card_expanded = !this.resume_spec_card_expanded;
      if (this.resume_spec_card_expanded) { //If resume spec card is exapnded
        this.job_spec_card_expanded= false; //collapse the job spec card
      }
    },
    // Handle navigation from the job card when a user clicks done/ next
    handleJobCardNavigation(){
      if( this.isJobSpecComplete && this.isResumeSpecComplete) { //Both sections are complete
        this.job_spec_card_expanded = false; //close the job card
        if (this.$refs.generateButton) {
          this.$refs.generateButton.scrollIntoView({ behavior: 'smooth' }); // Move focus to the "generate_application" button
          this.$refs.generateButton.querySelector('button').focus();
        }
        if (!this.userDetails.has_generated_resume) { //If the user hasn't previously generated a resume
          this.generateApplication(); //generate the application this.getMatch(); //
        }
      } else if (this.isJobSpecComplete && !this.isResumeSpecComplete) { //Only job section is complete
        this.resume_spec_card_expanded = true; //expand the resume card
        this.job_spec_card_expanded = false; //close the job card
        if (this.$refs.resumeCard) {         
          this.$refs.resumeCard.focus(); // Move focus to the resume card
        }
      }
    },
    // Handle navigation from the resume card when a user clicks done/ next
    handleResumeCardNavigation(){
      if( this.isJobSpecComplete && this.isResumeSpecComplete) { //Both sections are complete
        this.resume_spec_card_expanded = false; //close the resume card
        if (this.$refs.generateButton) {
          this.$refs.generateButton.scrollIntoView({ behavior: 'smooth' }); // Move focus to the "generate_application" button
          this.$refs.generateButton.querySelector('button').focus();
        }
        if (!this.userDetails.has_generated_resume) { //If the user hasn't previously generated a resume
          this.generateApplication(); //generate the application this.getMatch(); //
        }
      } else if (!this.isJobSpecComplete && this.isResumeSpecComplete) { //Only resume section is complete
        this.job_spec_card_expanded = true; //expand the job card
        this.resume_spec_card_expanded = false; //close the resume card
        if (this.$refs.jobCard) {         
          this.$refs.jobCard.focus(); // Move focus to the resume card
        }
      }
    },
    //Change preview tab
    changePreviewTab(tabId){
      console.log('Changing preview tab to: '+tabId);
      if (this.current_tab !=tabId) {
        this.current_tab=tabId;
        this.$refs.tabContainer.scrollTop = 0; // Reset the vertical scroll position to the top
      }
    },
    // Save application JSON to Vue store and navigate to Resume or Cover Letter screen
    async saveAndNavigate(destination) {
      console.log('Save and navigate to resume screen ...');
      //Save the input specs to Vue store
      this.application_json.job_spec_option = 'copy_paste'; //default it to the copy_paste option
      this.application_json.position_applied = this.job_title;
      this.application_json.company_applied = this.company_name;
      this.application_json.job_description_applied = this.job_description;
      if(this.resume_spec_option === 'last_application') {
        this.application_json.resume_spec_option = 'last_application'; //set the spec to last_applied resume
      } else {
        this.application_json.resume_spec_option = 'existing_resume'; //else set it to existing resume
      }
      await this.$store.commit('setApplicationJSON', { version: 1, response: this.application_json }); //save applicaation JSON into Vue store
      // console.log('Application JSON updated with new resume');
      this.$router.push({ name: destination, query: { application_id: this.application_id }, params: { from: this.$route.name } });
    },
    // Reload screen and start new application
    startNewApplication () {
      console.log('Start New Application');
      this.$router.push({ name: 'Home' }); //redirect to the home screen without any query params
      location.reload(); // force reload the screen
    },
    //The icon color to use give a particular score
    scoreToColor (value) {
      if (value<35) {
        return 'var(--danger-700)';
      } else if (value<65) {
        return 'var(--warning-600)';
      } else if (value<90) {
        return 'var(--success-500)';
      }else {
        return 'var(--success-700)';
      }
    },
    //The text to show given a particular score 
    scoreToText (value) {
      if (value<35) {
        return 'LOW';
      } else if (value<65) {
        return 'MEDIUM';
      } else if (value<90) {
        return 'HIGH';
      }else {
        return 'SUPERB';
      }
    },
    //The impact text to show for a given score type
    scoreKeyToImpact (scoreType) {
      switch (scoreType) {
        case 'soft_skills':
          return this.LABEL_IMPACT_MEDIUM;
        default:
          return this.LABEL_IMPACT_HIGH;
      }
    },
    // Start the animation for the match progress bar
    startMatchAnimation(match_only_job = false) {
      // Reset match and resume progress
      this.match_progress = 0; // reset match progress to 0%
      this.resume_progress = 0; // reset resume progress to 0%
      this.show_progress_animation = true;
      this.intervalId = setInterval(() => {
        if (this.show_progress_animation) {
          this.match_progress += (100 / this.match_timer) * 1; // Increase percentage by 1 second
          if (this.match_progress >= 100) {
            this.match_progress = 100;
            this.stopMatchAnimation();
            if (!match_only_job) this.startResumeAnimation();
          }
        } else {
          this.stopMatchAnimation(); // Stop the animation if show_progress_animation becomes false
        }
      }, 1000);
    },
    // Start the animation for the resume progress bar
    startResumeAnimation() {
      this.resume_progress = 0; //Reset resume progress
      this.show_progress_animation = true;
      this.intervalId = setInterval(() => {
        if (this.show_progress_animation) {
          this.resume_progress += (100 / this.resume_timer) * 1; // Increase percentage by 1 second
          if (this.resume_progress >= 98) {
            this.resume_progress = 98;
            this.stopResumeAnimation(); // Stop the animation when progress reaches 98% so that the totalProgress doesn't ever exceed 99%
          }
        } else {
          this.stopResumeAnimation(); // Stop the animation if show_progress_animation becomes false
        }
      }, 1000);
    },
    // Stop the animation for the match progress bar
    stopMatchAnimation() {
      clearInterval(this.intervalId);
    },
    // Stop the animation for the resume progress bar
    stopResumeAnimation() {
      clearInterval(this.intervalId);
      this.show_progress_animation = false; //stop all progress animation
    },
    //scroll to focus on the evaulation summary card
    scrollToEvaluationSummary(){
      if (this.$refs.evaluationSummary) {
          this.$refs.evaluationSummary.scrollIntoView({ behavior: 'smooth', block: 'start' }); // Move focus to the "generate_application" button
        }
    },
    //scroll to focus on the score details card
    scrollToScoreDetails(){
      if (this.$refs.scoreDetails) {
          this.$refs.scoreDetails.scrollIntoView({ behavior: 'smooth', block: 'start' }); // Move focus to the "generate_application" button
        }
    },
    //scroll to focus on the job requirements card
    scrollToJobRequirements(){
      if (this.$refs.jobRequirements) {
          this.$refs.jobRequirements.scrollIntoView({ behavior: 'smooth', block: 'start' }); // Move focus to the "generate_application" button
        }
    },
    //scroll to focus on the keywords card
    scrollToKeywords(){
      if (this.$refs.keywords) {
          this.$refs.keywords.scrollIntoView({ behavior: 'smooth', block: 'start' }); // Move focus to the "generate_application" button
        }
    },
  },
  computed: {
    ...mapGetters(['getUserDetails']),
    ...mapGetters(['previousRoute']), //get previous page name
    userDetails() {
      console.log('Inside Map Getters: Getting User Details');
      return this.getUserDetails;
    },
    ...mapGetters(['getResumePreferences']), //get resume preferences from Vue
    ...mapGetters(['getApplicationJSONByVersion']),
    applicationIdFromURL() {
      return this.$route.query.application_id;
    },
    //Is this a previous application or a new one
    isPreviousApplication(){
      return (this.applicationIdFromURL && this.applicationIdFromURL !== undefined && this.applicationIdFromURL !=='');
    },
    // Is the tailored resume available
    generatedResumeAvailable() {
      console.log('Checking if generated resume is available. Application JSON resume: '+JSON.stringify(this.application_json));
      return (this.application_json && this.application_json.resume && Object.keys(this.application_json.resume).length > 0);
    },
    // Is the tailored cover letter available
    coverLetterAvailable() {
      return (this.application_json && this.application_json.cover_letter && this.application_json.cover_letter.length > 0);
    },
    isJobSpecComplete() {
      //Check if job spec is complete
      let jobSpecComplete = true;
      if (this.job_spec_option === 'job_url') {
        // Job URL input can't be empty AND the URL can't be erroneous
        jobSpecComplete= this.job_url_input !== '' && !this.fetching_job && this.job_url_error_message ==''; 
      } else  { //i.e. if user is copying + pasting the job
        // Position cannot be empty AND there shouldn't be any errors with the inputs
        jobSpecComplete = this.job_title !=='' && this.job_manual_entry_error_message =='';
      }
      // console.log('Is Job Spec Complete? '+jobSpecComplete)
      return jobSpecComplete;
    },
    //Check if resume spec is complete
    isResumeSpecComplete() {
      let resumeComplete=  true;
      if (this.resume_spec_option === 'upload') {
        // File must have been successfully uploaded and there shouldn't have been any errors during or after upload
        resumeComplete= this.resume_upload_successful &&  this.resume_file_error_message=='' ;
      } else if (this.resume_spec_option == 'manual_entry') {
          // Resume text should meet criteria and there shouldn't have been any errors during or after upload
          resumeComplete = this.is_valid_resume_text && this.text_error_message =='';
      }
      // console.log('Resume spec complete? '+resumeComplete);
      return resumeComplete;
    },
    //Return the summary of the job spec card
    getJobSpecSummary() {
      let jobSpecSummary ='';
      if (this.job_spec_option === 'job_url' && this.job_url_input!='' && this.job_url_error_message=='') {
          jobSpecSummary = this.JOB_SPEC_SUMMARY_URL ;
      } else if (this.job_spec_option == 'copy_paste' && this.text_error_message =='' && this.job_title !='') {
        jobSpecSummary = this.JOB_SPEC_SUMMARY_MANUAL_ENTRY;
      } else {
          jobSpecSummary =  this.JOB_SPEC_INSTRUCTIONS; //Show job incomplete message
      }
      return jobSpecSummary;
    },
    //Return resume CTA button label
    getResumeCTALabel(){
      if (this.isResumeSpecComplete && this.isJobSpecComplete) {
        if (!this.userDetails.has_generated_resume) { //If the user hasn't previously generated a resume
          return this.BUTTON_LABEL_GENERATE;
        } else {
          return this.BUTTON_LABEL_ALL_COMPLETE; // if the user has previously generated a resume
        }
      } else if (this.isResumeSpecComplete && !this.isJobSpecComplete) { //only if resume is complete but not job
        return this.BUTTON_LABEL_ONE_COMPLETE;
      } else {
        return this.BUTTON_LABEL_RESUME_INCOMPLETE;
      }
    },
    //Return job CTA button label
    getJobCTALabel(){
      if (this.isJobSpecComplete && this.isResumeSpecComplete) {
        if (!this.userDetails.has_generated_resume) { //If the user hasn't previously generated a resume
          return this.BUTTON_LABEL_GENERATE;
        } else {
          return this.BUTTON_LABEL_ALL_COMPLETE; // if the user has previously generated a resume
        }
      } else if (this.isJobSpecComplete && !this.isResumeSpecComplete) {
        return this.BUTTON_LABEL_ONE_COMPLETE;
      } else {
        return this.BUTTON_LABEL_JOB_INCOMPLETE;
      }
    },
    //Return the summary of the resume spec card
    getResumeSpecSummary() {
      let resumeSpecSummary ='';
      if (this.resume_spec_option === 'upload' && this.resume_upload_successful &&  this.resume_file_error_message=='') {
          resumeSpecSummary = this.RESUME_SPEC_SUMMARY_FILE;
      } else if (this.resume_spec_option == 'manual_entry' && this.is_valid_resume_text && this.text_error_message =='') {
        resumeSpecSummary = this.RESUME_SPEC_SUMMARY_MANUAL_ENTRY;
      } else if (this.resume_spec_option == 'existing_resume') {
          resumeSpecSummary = this.RESUME_SPEC_SUMMARY_EXISTING;
      } else if (this.resume_spec_option == 'last_application') {
          resumeSpecSummary = this.RESUME_SPEC_SUMMARY_LAST_APPLICATION;
      } else {
          resumeSpecSummary =  this.RESUME_SPEC_INSTRUCTIONS //Show resume incomplete message
      }
      return resumeSpecSummary;
    },
    jobInputsEmpty() {
      return ( this.job_url_input == '' &&  this.company_name == '' && this.job_title == '' && this.job_location == '' && this.job_description == '' && this.job_site == '');
    },
    showJobPreview() {
      return (this.job_description!='');
    },
    showMatchPreview() { //We use the double-negation operator !! to convert the result of the condition to a boolean value
      return !!(this.job_title!='' && this.hasMandatoryRequirements); //the job position should have been entered
    },
    //Returns the computed classes for the job tab
    jobPreviewClass() {
      let classList =[];
      if(this.showJobPreview ){
        // If job preview can be shown
        if (this.current_tab=='job_preview') {
          classList=['jobFull'];
        } else {
          classList=['jobFull', 'swipeLeft'];
        }
      } else {
        //If job preview can't be shown
        if (this.previous_tab=='job_preview') {
          classList=['jobEmpty'];
        } else {
          classList=['jobEmpty', 'swipeLeft'];
        }
      } 
      return classList;
    },
    //Returns the computed classes for the job tab
    matchPreviewClass() {
      let classList =[];
      if(this.showMatchPreview){
        // If match preview can be shown
        if (this.current_tab=='match_preview') {
          classList=['jobFull'];
        } else {
          classList=['jobFull', 'swipeRight'];
        }
      }  else {
        //If job preview can't be shown
        if (this.previous_tab=='match_preview') {
          classList=['jobEmpty'];
        } else {
          classList=['jobEmpty', 'swipeRight'];
        }
      } 
      return classList;
    },
    // Dynamic class to apply for expand/ collapse of job card
    jobCardClass() {
      if(!this.job_spec_card_expanded) return 'collapsed'; 
    },
    // Dynamic class to apply for expand/ collapse of job card
    resumeCardClass() {
      if(!this.resume_spec_card_expanded) return 'collapsed';
    },
    // Checks if the mandatory match requirements can be shown
    hasMandatoryRequirements() {
      // Check if application_json is not blank and has match key with mandatory_requirements
      return (
        this.application_json &&
        this.application_json.match &&
        this.application_json.match.mandatory_requirements &&
        this.application_json.match.mandatory_requirements.length > 0
      );
    },
    // Checks if the optional match requirements can be shown
    hasOptionalRequirements() {
      // Check if application_json is not blank and has match key with mandatory_requirements
      return (
        this.application_json &&
        this.application_json.match &&
        this.application_json.match.optional_requirements &&
        this.application_json.match.optional_requirements.length > 0
      );
    },
    //Formatted string for last application
    lastApplicationString () {
      if(this.last_application.company) {
        return (this.last_application.position + ' at ' +this.last_application.company);
      } else if (this.last_application.position){
        return this.last_application.position;
      }
    },
    //The message to show for qualitative match against job requirements
    messageQualitativeMatch () {
      if (this.application_json.match.overall_match_score < 40) {
        return this.MESSAGE_MATCH_LOW;
      } else if (this.application_json.match.overall_match_score < 70) {
          return this.MESSAGE_MATCH_MEDIUM;
      } else if (this.application_json.match.overall_match_score < 90) {
          return this.MESSAGE_MATCH_HIGH;
      } else {
          return this.MESSAGE_MATCH_V_HIGH;
      }
    },
    //The message to show for how the resume would fare against an ATS based on keywords/ phrases
    messageATSMatch () {
      if (this.application_json.match.keyword_frequency_score < 40) {
        return this.MESSAGE_ATS_LOW;
      } else if (this.application_json.match.keyword_frequency_score < 70) {
          return this.MESSAGE_ATS_MEDIUM;
      } else {
          return this.MESSAGE_ATS_HIGH;
      }
    },
    //The message to show for how much of a boost the candidate would get from tailoring the resume
    keywordBoost () {
      const ats = this.application_json.match.keyword_frequency_score;
      const match = this.application_json.match.overall_match_score;
      if ((ats >= 40 && match < 40 ) || (ats >=70 && match <70)) {
        return {score: 20, message: this.MESSAGE_NO_BOOST};
      } else if (match >= 70 && ats < 70) {
        return {score: 80, message: this.MESSAGE_HIGH_BOOST};
      } else if (match >= 70) {
        return {score: 55, message: this.MESSAGE_MEDIUM_BOOST};
      }else {
        return {score: 40, message: this.MESSAGE_LOW_BOOST};
      }
    },
    //combined ATS score consisting of keyword frequency score and resume readability
    combinedATSScore () {
      const match = this.application_json.match;
      if (match && match.hasOwnProperty('keyword_frequency_score') && match.hasOwnProperty('resume_readability')) {
        return Math.round(match.keyword_frequency_score * match.resume_readability/100.0);
      } else if (match && match.hasOwnProperty('keyword_frequency_score')) {
        return match.keyword_frequency_score;
      } else {
        return 0;
      }
    },
    //compute the progress of input completion
    inputProgress(){
      if ((this.isJobSpecComplete && this.isResumeSpecComplete) || this.generatedResumeAvailable) {
        return 100;
      } else {
        return 0;
      }
    },
    // compute the total progress of resume generation
    totalProgress() {
      return Math.round( 0.05*this.inputProgress + 0.25* this.match_progress + 0.70* this.resume_progress);
    },
    // if inputs need to be disabled
    inputsDisabled() {
      return (this.match_in_progress || this.resume_in_progress || this.generatedResumeAvailable);
    }
  },
  watch: {
    // Watch for changes to the following fields and call the corresponding methods
    job_url_input() {
      this.clearJobFields(false); //Editing the job URL should reset the job fields
      this.saveJobDetailsFromURL();
    },
    job_title: 'resetJobManualEntryErrorMessage',
    job_description: {
      handler(newText, oldText) {
        if (newText === '') { // Check if the new value of job_description is empty
          this.previous_tab=this.current_tab;
          this.changePreviewTab('job_preview'); //reset the preview tab to the default value 
        }
        this.resetJobManualEntryErrorMessage();
      },
    } , 
    text_resume: {
      handler(newText, oldText) {
        if (newText !== '') { // Check if the new value is not an empty string
          this.validateResumeText(); // Call the method only when the new value is not an empty string
        }
      },
    },
    current_tab(newTab, oldTab) {
      console.log('Preview tab changed to:'+ newTab);
      this.previous_tab = oldTab;
    },
    // match_progress(new_value){
    //   //Total progress = 5% (input) + 25% (Match) + 70% (Resume)
    //   this.total_progress = Math.round(5.0 + 0.25*new_value);
    // },
  },
};
</script>
<style scoped>
.ProgressScaffold {
  width: 100%;
  height: 1.75rem;
  margin-bottom: 0.5rem;
  padding: 0 0.625rem;
  display: flex;
}
.ProgressBackground {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 0.25rem;
  gap: 0.375rem;
}
.InputProgressScaffold {
  flex: 10;
}
.MatchProgressScaffold {
  flex: 20;
}
.ResumeProgressScaffold {
  flex: 70;
}
.ProgressIndicator {
  flex: 5;
}
.ProgressTextHeight {
  line-height: 1rem;
  padding-left: 0.125rem;
}

.JobPanelContainer {
    width: 100%;
    height: calc(100% - 8.0625rem);
    top: 5.0625rem;
    position: relative;
    padding: 0.625rem 1.25rem 0 1.25rem;
}
.PrintPreview.jobEmpty {
  opacity: 0;
}
.PrintPreview.jobFull {
  opacity: 1;
  padding: 2rem;
}
.PrintPreview.swipeLeft {
  transform: translateX(-200%);
  height: 0;
}
.PrintPreview.swipeRight {
  transform: translateX(200%);
  height: 0;
}
.PreviewEmptyState.jobEmpty {
  opacity: 1;
  padding: 2rem;
}
.PreviewEmptyState.jobFull {
  opacity: 0;
}
.InputErrorText {
  width: calc(100% - 1.625rem);
  color: var(--danger-700);
  font-size: var(--size-small);
  line-height: var(--size-normal);
}
.QuestionsContainer {
  border: solid var(--border-thickness-normal) var(--color-input-selected);
  border-radius: var(--button-border-radius);
  color: var(--color-input-selected);
  padding: 0.5rem 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.QuestionsIcon {
  height: 1.5rem;
  width: 1.5rem;
  color: var(--color-input-selected);
}
.MatchSummaryRow {
  display: flex;
  align-items: center;
  padding: 0 0.625rem;
  gap: 0.625rem;
  width: 100%;
}
.MatchIndicator {
  width: 3.25rem;
}
.ScoreValue{
  font-size: var(--size-xx-small);
  text-align: center;
  min-width: 3.25rem;
  align-self: flex-start;
}
.FullScoreIndicator {
  width: 100%;
}
.ImpactPill {
    display: inline-block;
    vertical-align: middle;
    background-color: var(--color-input-disabled);
    padding: 0.125rem 0.5rem;
    border-radius: var(--pill-border-radius);
    font-size: var(--size-xx-small);
    font-weight: var(--weight-normal);
    color: var(--color-text-paragraph);
    text-align: center;
    margin-left: 0.25rem;
}
.KeywordsContainer {
  text-align: left;
  display: inline-block;
}
.KeywordPresent{
  background-color: var(--success-100);
}

.VerticalSpacing {
  gap: 0.5rem;
}

.MatchText{
  text-align: left;
  font-size: var(--size-normal);
  white-space: normal;
  word-wrap: break-word;  
}
.MatchTextSmall{
  text-align: left;
  font-size: var(--size-x-small);
  color: var(--color-text-description);
  overflow-x: hidden;
}
.BoldText {
  color: var(--color-text-paragraph);
  font-weight: var(--weight-bold);
}
.AlignRight {
  text-align: right;
}
.MatchCard{
  border: 1px solid var(--gray-200);
  border-radius: var(--card-border-radius);
  padding: 1.25rem 0.625rem;
  margin: 0.625rem 0 1.25rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.MatchSeparator {
  border-right: 1px solid var(--gray-200);
  padding: 1rem 0 0 0;
}
.MatchList {
  list-style-type: none; /* Remove default bullet point */
  margin-top: 0.25rem;
  width: 33.875rem;
  padding: 0 0.625rem;
  align-self: flex-start;
}
.MatchListWidth {
  width: calc( 100% - 2.5rem);
  display: flex;
  gap: 0.625rem;
}
.MatchRow{
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem 0;
    width: 100%;
}
.MatchIconColor {
  color: var(--gray-400);
  align-self: flex-start;
}
.ChartContainer{
  height: 10rem;
  width: 10rem;
  align-self: flex-start;
}
</style>